import React from 'react';
import WhatIs from './WhatIs';
import { NavLink } from 'react-router-dom';
import { Box, Grid, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';

// integration to remove elements when viewing the dashboard through the compair portal
const currentPath = window.location.search;
let pathHome = ''
if (currentPath.includes('integration=true') )
{
  pathHome = '?integration=true'
}


const Home = () => {

  const subdomain = 'https://monitoring.dev.wecompair.eu/';
  const accessToken2 = localStorage.getItem(`${subdomain}_accessToken`);

  const externalUrl = 'https://monitoring.wecompair.eu/auth/register';
  
  const { t } = useTranslation();

  
  const { trackPageView } = useMatomo()
  // Track page view
  useEffect(() => {
    // Set the document title
    document.title = "Home - Carbon Footprint Simulation Dashboard - CompAir";
    _paq.push(['setDocumentTitle', "Home Page"]);
    // Track the page view
    trackPageView();
  }, []);


  return (
    <>
      <Helmet>
        <title>Home - Carbon Footprint Simulation Dashboard - CompAir</title>
      </Helmet>

      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <div>
          <section id="home">
            <Box sx={{ bgcolor: 'white' }}>
              <div className="container-fluid py-5">
                <div className="row justify-content-center">
                  <div className="col-md-8">
                  <Typography textAlign="center" fontSize={55} variant="h1" >
                      {t("Landing Page Title")}
                    </Typography>
                    <p className="lead text-center fs-4 p-2">
                      {t("Landing Page Subtitle")}
                    </p>

                    <p className="lead text-center fs-4 p-2">
                      {t("Keep track of your carbon footprint by signing up.")}
                    </p>

                    <div className="d-flex justify-content-center">
                      <div className="p-2">
                        <Button
                          variant="outlined"
                          size="large"
                          component="a"
                          href={externalUrl}
                          target="_blank" // Opens the link in a new tab
                          rel="noopener noreferrer" // Security measure for external links
                        >
                          {t("Sign Up Now")}
                        </Button>
                      </div>

                      <div className="p-2">
                        <Button variant="outlined" size="large" component={NavLink} to={`/profile` + pathHome}>
                        {t("Sign In")}
                        </Button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </Box>
          </section>
          <WhatIs />
        </div>
      </Grid>
    </>
  );
};

export default Home;
