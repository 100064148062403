import { useRef, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import jwtDecode  from 'jwt-decode';
import { useEffect } from "react";


import {
  Avatar,
  Box,
  IconButton,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  styled
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';


const currentPath = window.location.search;
let isIntegrated = false;
let pathHome = ''
if (currentPath.includes('integration=true') )
{
  isIntegrated = true;
  pathHome = '?integration=true'

}

const IconButtonPrimary = styled(IconButton)(
  ({ theme }) => `
    display: inline-flex;
    border-radius: ${theme.general.borderRadiusLg};
    height: 58px;
    justify-content: center;
    font-size: ${theme.typography.pxToRem(13)};
    position: relative;
    margin-left: ${theme.spacing(1)};

    .MuiSvgIcon-root {
      transition: ${theme.transitions.create(['color'])};
      font-size: ${theme.typography.pxToRem(16)};
      color: ${theme.colors.alpha.trueWhite[50]};
    }

    &.active,
    &:hover {
      background-color: ${theme.colors.alpha.black[10]};

      .MuiSvgIcon-root {
        color: ${theme.colors.alpha.trueWhite[100]};
      }
    }
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${theme.palette.secondary.light}
`
);

function Userbox() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const { user, logout} = useAuth();
  const [decoded, setDecoded] = useState([]);


  let accessToken = localStorage.getItem('accessToken');
  useEffect(() => {
    
    if (accessToken !== undefined && accessToken !== null) {
      const decodedToken = jwtDecode(accessToken);
      setDecoded(decodedToken);
    }  
  }, []);


  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      navigate('/home');
    } catch (err) {
      console.error(err);
    }
  };

  const handleLogin = async () => {
    try {
      handleClose();
      navigate('/profile');
    } catch (err) {
      console.error(err);
    }
  };

  const handleName = () => {
    if(accessToken !== null) {
      return decoded.name;
    }
    return 'Guest';
  }

  const handleSignInStatus = () => {
    if(accessToken !== null) {
      return (
        <Button color="primary" fullWidth onClick={handleLogout}>
        <LockOpenTwoToneIcon
          sx={{
            mr: 1
          }}
        />
        {t('Sign out')}
      </Button>
      )
    }

    return (
      // <Button color="primary" fullWidth onClick={handleLogout}>
      <Button color="primary" fullWidth onClick={handleLogin}>
      <LockOpenTwoToneIcon
        sx={{
          mr: 1
        }}
      />
      {t('Sign in')}
    </Button>
    )

  }


  const currentPath = useLocation();
  const isIntegrated = new URLSearchParams(currentPath.search).get('integration')
  if (isIntegrated) return null;
 

  return (
    <>
      <IconButtonPrimary color="primary" ref={ref} onClick={handleOpen}>
        {/* <Avatar variant="rounded" alt={user.name} src={user.avatar} /> */}
        <Avatar variant="rounded"  />
        <Box
          component="span"
          sx={{
            display: { xs: 'none', sm: 'inline-block' }
          }}
        >
          <ExpandMoreTwoToneIcon
            sx={{
              ml: 1
            }}
          />
        </Box>
      </IconButtonPrimary>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <MenuUserBox
          sx={{
            minWidth: 210
          }}
          display="flex"
        >
          {/* <Avatar variant="rounded" alt={user.name} src={user.avatar} /> */}
          <Avatar variant="rounded"  />
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {handleName()}
            </UserBoxLabel>
            <UserBoxDescription variant="body2">
              {/* {handleRole()} */}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider
          sx={{
            mb: 0
          }}
        />
        <List
          sx={{
            p: 1
          }}
          component="nav"
        >
          <ListItem
            button
            onClick={() => {
              handleClose();
            }}
            to={`/profile`+ pathHome}
            component={NavLink}
          >
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText primary={t('Profile')} />
          </ListItem>

        </List>
        <Divider />
         <Box m={1}>
            {handleSignInStatus()}
         </Box>
      </Popover>
    </>
  );
}

export default Userbox;
