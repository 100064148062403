import { Tooltip, styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.colors.alpha.trueWhite[100]};
        padding: 0;
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);


function Logo() {

  /* Hide footer when viewing through the Compair Project portal */
  const currentPath = useLocation();
  const isIntegrated = new URLSearchParams(currentPath.search).get('integration')
  if (isIntegrated) return null;


  return (
    <LogoWrapper to="/home">
      <Tooltip
        arrow
        placement="right"
        title="Compair Project"
      >
        
<Typography style={{ color: "#33cb37" }}>
       COMPAIR
</Typography>

      </Tooltip>
    </LogoWrapper>
  );
}

export default Logo;
