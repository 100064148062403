import React from "react";
import {
    Box, Typography, ListItemAvatar, ListItemText, Divider, Card, styled, ListItem
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from "react";
import Modal from '@mui/material/Modal'
import { Link } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const ListColumnWrapper = styled(Card)(
    ({ theme }) => `

        margin-right: ${theme.spacing(3)};
        border-top-width: 8px;
        border-top-style: solid;
    `
);



const ListItemAvatarWrapper = styled(ListItemAvatar)(
    ({ theme, color }) => `
    min-width: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${theme.spacing(1)};
    padding: ${theme.spacing(0.5)};
    border-radius: 60px;
    background: ${color};
  
    img {
      background: ${theme.colors.alpha.trueWhite[100]};
      padding: ${theme.spacing(0.5)};
      display: block;
      border-radius: inherit;
      height: ${theme.spacing(4.5)};
      width: ${theme.spacing(4.5)};
    }
  `
);

const newJSONcomponentNames = {
    "Age" : "age",
    "Home - Total Result" : "buildingResults",
    "Home - Eelctricity" : "building_elec",
    "Home - Fuel Amount" : "building_fuel",
    "Home - Fuel Type" : "building_fuel_type",
    "Cars - Distance" : "car_distance",
    "City" : "city",
    "Waste Management - Composting" : "composting",
    "Country" : "country",
    "Flights - Domestic " : "domestic",
    "Cars - Electric Car" : "electric",
    "End Date" : "endDate",
    "Flights - Total Result" : "flightResults",
    "Cars - Fuel Type": "fuel",
    "Gender" : "gender",
    "Home - Grid" : "grid",
    "Cars - Litres " : "litres",
    "Flights - Long Haul" : "long",
    "Marital Status" : "marital",
    "Flights - Medium Haul" : "medium",
    "Cars - Public Transportation" : "public",
    "Home - Heat Pump" : "pump",
    "Waste Management - Recycling" : "recycling",
    "Flights - Short Haul" : "short",
    "Home - Solar" : "solar",
    "Start Date" : "startDate",
    "Trains - Total Result" : "trainResults",
    "Trains - Distance" : "train_distance",
    "Cars - Total Result" : "transportationResults",
    "Train - Number of Trips" : "trips",
    "Waste Management - Total Result" : "wasteResults",
    "Home - Refrigirator" : "buildingRefrigerator",
    "Home - Washing Machine" : "buildingRefrigerator",
    "Home - Dishwasher" : "buildingDishwasher",
    "Home - Oven" : "buildingOven"
}


const RecommenationTab = ({ userInputData, dataArr, categoryData }) => {
    const { t } = useTranslation();

    const parseAndCheck = (recommendation) => {

        let showThisRecommendation = true

        Object.keys(recommendation["cases"]).forEach(function (iterator) { 

            if (showThisRecommendation) { 

                let currentCase = recommendation["cases"][iterator]
                let value = currentCase.neededValue
                // let componentID = currentCase.neededValueID
                let componentID = newJSONcomponentNames[currentCase.neededValueID]
                let operator = currentCase.operator
                let userInputValue = userInputData[componentID]

                switch (operator) {
                    case ">":
                        if (!(parseFloat(userInputValue) >= parseFloat(value))){
                            showThisRecommendation = false
                        }
                        break;
                    case "<":
                        if (!(parseFloat(userInputValue) <= parseFloat(value))){
                            showThisRecommendation = false
                        }
                        break;
                    case "=":
                        if (!(userInputValue === value)) {
                            showThisRecommendation = false
                        }
                        break;
                    default:
                        // console.log("recommendationTabSkipped?")
                }

            }
        })
        return showThisRecommendation
    }


  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const handleOpenModal = (recom) => {
    setOpen(true);
    setModalContent(recom);
  }

  const handleCloseModal = () => {
    setOpen(false);
  }

  const loadRecommendations = () => {
    let recommendationsList = []

    Object.keys(dataArr).forEach(function (iterator) {

      if (parseAndCheck(dataArr[iterator])) {

        recommendationsList.push(
          <ListItem button onClick={() => handleOpenModal(dataArr[iterator])}>
            <ListItemAvatarWrapper color={categoryData.color}>
              <img
                alt=""
                src={categoryData.icon}
              />
            </ListItemAvatarWrapper>
            <Typography overflow='hidden' variant="subtitle2">
              {t(dataArr[iterator].text)}
            </Typography>
          </ListItem>
        )
      }
    })

    return recommendationsList
  }

  const loadRecommendationLinks = () => {
    let recommendationLinksList = []

    if (modalContent && modalContent.links) {

        if (modalContent.links[userInputData['country']] != null) {
            Object.keys(modalContent.links[userInputData['country']]).forEach(function (iterator) {
                if(modalContent.links[userInputData['country']][iterator] !== "") {
                    recommendationLinksList.push(
                        <li variant="body1">
                            <Link href={modalContent.links[userInputData['country']][iterator]} target="_blank" rel="noopener">{modalContent.links[userInputData['country']][iterator]} </Link>
                        </li>
                    )
                }
            });
        }
    }

    return recommendationLinksList
  }


    return (
        <>
            <ListColumnWrapper
                sx={{
                    borderColor: categoryData.color
                }}>

                {dataArr.length !== 0 && (
                    <Box>
                        <ListItemAvatar
                            sx={{
                                minWidth: 'auto',
                                mr: 1,
                                mb: { xs: 1, sm: 0 }
                            }} />
                        <ListItemText
                            primary={
                                <>
                                    <Typography
                                        color="text.primary"
                                        variant="h5"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >

                                        {t(categoryData.title)}

                                    </Typography>
                                    <Divider />
                                </>}
                            secondary={<>

                                {loadRecommendations()}
                            </>}
                        />

                    </Box>
                )}
            </ListColumnWrapper>


        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
                    {t('For more information')}
                </Typography>
                {/* <div>{modalContent.category}</div> */}
                <ul>
                {loadRecommendationLinks(modalContent)}
                </ul>
                
            </Box>
        </Modal>
        </>
    );
};

export default RecommenationTab;
