import React from "react";
import { Grid, Card, Box } from "@mui/material"
import Block1 from './Block1'
import GraphWithFilter from "./GraphWithFilter";


const UserScenarioStatistics = () => {

    return (
        <>
            <Grid container justifyContent="center" marginTop={1} >
                <Grid item xs={10}>
                <Card>
                    <Box>
                        {/* <Block1 userScenarios = {userScenarios} /> */}
                        <Block1/>
                    </Box>
                    <Box>
                        <GraphWithFilter/>
                    </Box>
                </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default UserScenarioStatistics;
