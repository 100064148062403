import React from 'react';

const Modal = ({
  formData,
  car,
  flight,
  train,
  building,
  waste,
  carC,
  trainC,
  buildingC,
  wasteC,
  carE,
  trainE,
  buildingE,
  wasteE,
  country,
  start,
  end,
  flightC,
  flightE
}) => {
  const calculator = {
    // welcome fields
    country: formData.country,
    startDate: formData.startDate || start,
    endDate: formData.endData || end,

    // demographic fields
    city: formData.city || 'unanswered',
    age: formData.age || 'unanswered',
    gender: formData.gender || 'unanswered',
    marital: formData.marital || 'unanswered',

    // car fields
    car_distance: formData.car_distance,
    liters: formData.liters,
    fuel: formData.fuel || 'unanswered',
    public: formData.public ? 'yes' : 'no',
    electric: formData.electric ? 'yes' : 'no',

    // flights fields
    domestic: formData.domestic,
    short: formData.short,
    medium: formData.medium,
    long: formData.long,

    // trains fields
    trips: formData.trips,
    train_distance: formData.train_distance,

    // buildings fields
    building_fuel: formData.building_fuel,
    building_fuel_type: formData.building_fuel_type || 'unanswered',
    building_elec: formData.building_elec,
    solar: formData.solar ? 'yes' : 'no',
    pump: formData.pump ? 'yes' : 'no',

    // waste fields
    recycling:
      formData.recycling === '200'
        ? 'no'
        : formData.recycling === '486.4'
        ? 'less than average'
        : formData.recycling === '608'
        ? 'same as average'
        : formData.recycling === '729.6'
        ? 'more than average'
        : 'unanswered',
    composting:
      formData.composting === '200'
        ? 'no'
        : formData.composting === '486.4'
        ? 'less than average'
        : formData.composting === '608'
        ? 'same as average'
        : formData.composting === '729.6'
        ? 'more than average'
        : 'unanswered'
  };
  const userResults = {
    cars: car,
    flights: flight,
    trains: train,
    buildings: building,
    waste: waste
  };
  const countryResults = {
    country: country,
    cars: carC,
    flights: flightC,
    trains: trainC,
    buildings: buildingC,
    waste: wasteC
  };
  const euResults = {
    cars: carE,
    flights: flightE,
    trains: trainE,
    buildings: buildingE,
    waste: wasteE
  };
  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Save Calculator Results
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <span>The following data have been saved to database</span>
            <hr />
            <span>Calculator Fields</span>
            <ul>
              {Object.keys(calculator).map((data) => (
                <li key={data}>
                  {data} : {calculator[data]}
                </li>
              ))}
            </ul>
            <hr />
            <span>User Results</span>
            <ul>
              {Object.keys(userResults).map((data) => (
                <li key={data}>
                  {data} : {userResults[data]}
                </li>
              ))}
            </ul>
            <hr />
            <span>Country Results</span>
            <ul>
              {Object.keys(countryResults).map((data) => (
                <li key={data}>
                  {data} : {countryResults[data]}
                </li>
              ))}
            </ul>
            <hr />
            <span>EU Results</span>
            <ul>
              {Object.keys(euResults).map((data) => (
                <li key={data}>
                  {data} : {euResults[data]}
                </li>
              ))}
            </ul>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
