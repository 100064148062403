import React, { useState, useEffect } from 'react';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import Welcome from './Welcome';
import Trains from './domains/Trains';
import Cars from './domains/Cars';
import Flights from './domains/Flights';
import Buildings from './domains/Buildings';
import WasteDisposal from './domains/WasteDisposal';
import Demographic from './Demographic';
import Results from './results/Results';
import Alert from './Alert';

import CarJSON from './cars.json';
import FlightJSON from './flights.json';
import TrainJSON from './trains.json';
import BuildingJSON from './buildings.json';
import WasteJSON from './wasteManagement.json';

import { useTranslation } from 'react-i18next';

const Domains = () => {
  // states to handle alert message
  const [alert, setAlert] = useState(true);
  const [status, setStatus] = useState('');
  const [first, setFirst] = useState(true);

  // locked results
  const [showRes, setShowRes] = useState(true);

  // tabs navigation
  const [activeTab, setActiveTab] = useState('welcome');

  // country carbon calculations
  const [country, setCountry] = useState('');
  const [locked, setLocked] = useState(true);
  const [carC, setCarC] = useState(0);
  const [trainC, setTrainC] = useState(0);
  const [buildingC, setBuildingC] = useState(0);
  const [wasteC, setWasteC] = useState(0);
  const [flightC, setFlightC] = useState(0);

  // user carbon calculations
  const [car, setCar] = useState(0);
  const [flight, setFlight] = useState(0);
  const [train, setTrain] = useState(0);
  const [building, setBuilding] = useState(0);
  const [waste, setWaste] = useState(0);

  // EU carbon calculations
  const [carE, setCarE] = useState(0);
  const [trainE, setTrainE] = useState(0);
  const [buildingE, setBuildingE] = useState(0);
  const [wasteE, setWasteE] = useState(0);
  const [flightE, setFlightE] = useState(0);

  // states to handle start and end dates
  let oneYear = new Date();
  oneYear.setFullYear(oneYear.getFullYear() + 1);
  const [start, setStart] = useState(new Date().toLocaleDateString('el-GR'));
  const [end, setEnd] = useState(oneYear.toLocaleDateString('el-GR'));

  // calculator forms fields
  const [formData, setFormData] = useState({
    // welcome fields
    country: '',
    startDate: '',
    endDate: '',

    // demographic fields
    city: '',
    age: '',
    gender: '',
    // marital: '',
    socialAid: '',
    educationLevel: '',
    municipalCommunity: '',
    busFrequency: 0,
    tramFrequency: 0,
    metroFrequency: 0,
    bicycleFrequency: 0,

    // new car fields
    car_distance: [0], // Initialize with an array containing a default value
    liters: [0], // Initialize with an array containing a default value
    fuel: [''], // Initialize with an array containing an empty string
    // electric: [false], // Initialize with an array containing a default value
    public: false,

    // old car fields
    // car_distance: 0,
    // liters: 0,
    // fuel: '',
    // public: false,
    // electric: false,

    // flights fields
    domestic: 0,
    short: 0,
    medium: 0,
    long: 0,

    // trains fields
    trips: 0,
    train_distance: 0,

    // buildings fields
    building_fuel: 0,
    building_fuel_type: '',
    building_elec: 0,
    grid: '',
    solar: false,
    pump: false,
    buildingRefrigerator: '',
    buildingWashingMachine: '',
    buildingDishwasher: '',
    buildingOven: '',

    // waste fields
    recycling: 0,
    composting: 0
  });

  const averages = {
    cars: {
      austria: 432,
      belgium: 491,
      bulgaria: 544,
      croatia: 349,
      denmark: 588,
      germany: 863,
      greece: 216,
      italy: 452,
      latvia: 458,
      netherlands: 432,
      poland: 751,
      portugal: 694,
      romania: 617,
      slovenia: 341,
      eu: 469,
    },
    flights: {
      austria: 506,
      belgium: 442,
      bulgaria: 401,
      croatia: 640,
      denmark: 991,
      germany: 452,
      greece: 1172,
      italy: 576,
      latvia: 486,
      netherlands: 569,
      poland: 204,
      portugal: 931,
      romania: 204,
      slovenia: 151,
      eu: 744,
    },
    trains: {
      austria: 304,
      belgium: 247,
      bulgaria: 65,
      croatia: 51,
      denmark: 281,
      germany: 549,
      greece: 79,
      italy: 327,
      latvia: 40,
      netherlands: 413,
      poland: 231,
      portugal: 261,
      romania: 116,
      slovenia: 62,
      eu: 219,
    },
    buildings: {
      austria: 2237,
      belgium: 2225,
      bulgaria: 1640,
      croatia: 1426,
      denmark: 1785,
      germany: 2539,
      greece: 2471,
      italy: 1796,
      latvia: 937,
      netherlands: 2351,
      poland: 1594,
      portugal: 1904,
      romania: 1062,
      slovenia: 1951,
      eu: 1753,
    },
    waste: {
      austria: 378,
      belgium: 684,
      bulgaria: 545,
      croatia: 343,
      denmark: 922,
      germany: 558,
      greece: 581,
      italy: 791,
      latvia: 419,
      netherlands: 377,
      poland: 288,
      portugal: 686,
      romania: 22,
      slovenia: 446,
      eu: 486,
    },
  };

  // calculate results after waste form is submited
  useEffect(() => {
    showResults();
  }, [waste]);


  // function to unlock results after fields have been completed
  const showResults = () => {
    if (first) {
      setFirst(false);
      return;
    }
    if (
      formData.building_elec === 0 ||
      formData.recycling === 0 ||
      formData.composting === 0 ||
      formData.country === ''
    ) {
      setStatus('fail');
      setAlert(false);
      setShowRes(true);
    } else {
      setStatus('success');
      setAlert(false);
      setShowRes(false);
    }
  };

  // function to hide alert
  const hideAlert = (e) => {
    e.preventDefault();
    setAlert(true);
  };

  // functions to set start and end dates
  const handleStartDate = (e) => {
    setStart(new Date(e).toLocaleDateString()); 
  };
  const handleEndDate = (e) => {
    setEnd(new Date(e).toLocaleDateString()); 
  };

  const handleCar = () => {
    // Create variables to store total carbon footprints
    let totalCountry = averages.cars[formData.country];
    let totalCitizen = 0;
    let totalEU = averages.cars['eu'];
  
    // Iterate over each car's data
    for (let i = 0; i < formData.car_distance.length; i++) {
      const distance = formData.car_distance[i];
      const liters = formData.liters[i];
      const fuelType = formData.fuel[i];
  
      let citizen;
  
      if (fuelType === 'electricity') {
        // Calculate for electric cars
        citizen = 1 * distance * 0.156 * CarJSON.countryFactorCO2perkWh[0][formData.country];
      // eslint-disable-next-line
      } else {
        // Calculate for non-electric cars
        // eslint-disable-next-line
        if (formData.public) {
          citizen = ((1 * distance) / 100) * liters * CarJSON.typeOfFuel[0][fuelType] * 0.72;
        }
        else {
          citizen = ((1 * distance) / 100) * liters * CarJSON.typeOfFuel[0][fuelType] * 1;
        }
      }
  
      // Add the individual car's carbon footprint to the total
      totalCitizen += citizen;
    }
  
    // Calculate the total carbon footprint for all cars
    setCar(parseFloat((totalCitizen / 1000).toFixed(2)));
    setCarC(parseFloat((totalCountry / 1000).toFixed(2)));
    setCarE(parseFloat((totalEU / 1000).toFixed(2)));
  };

  // function to calculate user flights co2
  const handleFlight = () => {
    // Eq = D*E*F*G + D*E*F*G + D*E*F*G + D*E*F*G
    // D(user) = domestic, short haul, medium haul, long haul
    // E(JSON) = avg domestic, avg short haul, avg medium haul, avg long haul
    // F(JSON) = no description cell 0.1
    // G(JSON) = kerosene factor
    let citizen =
      formData.domestic *
        FlightJSON.averageDistanceDomesticKM *
        FlightJSON.cell *
        FlightJSON.kerosene +
      formData.short *
        FlightJSON.averageDistanceShortHaulKM *
        FlightJSON.cell *
        FlightJSON.kerosene +
      formData.medium *
        FlightJSON.averageDistanceMediumHaulKM *
        FlightJSON.cell *
        FlightJSON.kerosene +
      formData.long *
        FlightJSON.averageDistanceLongHaulKM *
        FlightJSON.cell *
        FlightJSON.kerosene;

    let country;
    let eu;
    country = averages.flights[formData.country];
    eu = averages.flights['eu'];

    setFlight(parseFloat((citizen / 1000).toFixed(2)));
    setFlightC(parseFloat((country / 1000).toFixed(2)));
    setFlightE(parseFloat((eu / 1000).toFixed(2)));
  };

  // function to calculate user trains co2
  const handleTrain = () => {
    // Eq = D*E*F*G
    // D = Number of trips (user input)
    // E = Average distance of trip (user input || JSON)
    // F = CO2 per kWh (country factor) (JSON)
    // G = kWh per km of travel (JSON)
    let citizen =
      formData.trips *
      formData.train_distance *
      TrainJSON.co2PerkWhFactor[0][formData.country] *
      TrainJSON.kWhPerKmOfTravel;

    let country;
    let eu;

    country = averages.trains[formData.country];
    eu = averages.trains['eu'];

    setTrain(parseFloat((citizen / 1000).toFixed(2)));
    setTrainC(parseFloat((country / 1000).toFixed(2)));
    setTrainE(parseFloat((eu / 1000).toFixed(2)));
  };

  // function to calculate user and country buildings co2
  const handleBuilding = () => {
    let country;
    let eu;

    country = averages.buildings[formData.country];
    eu = averages.buildings['eu'];

    // user electricity

    let citizenElec;
    if (formData.pump) {
      citizenElec = formData.building_elec *
      1 *
      BuildingJSON.co2PerkWhFactor[0][formData.country] *
      1.08 *
      0.36;
    }
    else {
      citizenElec = formData.building_elec *
      1 *
      BuildingJSON.co2PerkWhFactor[0][formData.country] *
      1.08;
    }

    // user heating
    let citizenHeat;
    if (formData.building_fuel_type === "nofuel") {
      citizenHeat = 0;
    }
    else {
      // eslint-disable-next-line
      if (formData.solar) {
        citizenHeat = 
        (formData.building_fuel * BuildingJSON.countryFactorHeat[0][formData.country] * 3.6) *
        (BuildingJSON.co2PerUnit[0][formData.building_fuel_type] / 30) *
        0.71;
      }
      else {
        citizenHeat = 
        (formData.building_fuel * BuildingJSON.countryFactorHeat[0][formData.country] * 3.6) *
        (BuildingJSON.co2PerUnit[0][formData.building_fuel_type] / 30) *
        1;
      }
    }


    // user appliances
    let citizenAppliances = 0;
    if (formData.buildingRefrigerator === "yes") {
      citizenAppliances += (citizenElec * 0.147 * 0.85);
    }
    else if (formData.buildingRefrigerator === "semi") {
      citizenAppliances += (citizenElec * 0.147 * 0.5);
    }

    if (formData.buildingWashingMachine === "yes") {
      citizenAppliances += (citizenElec * 0.106 * 0.51);
    }
    else if (formData.buildingWashingMachine === "semi") {
      citizenAppliances += (citizenElec * 0.147 * 0.21);
    }

    if (formData.buildingDishwasher === "yes") {
      citizenAppliances += (citizenElec * 0.036 * 0.48);
    }
    else if (formData.buildingDishwasher === "semi") {
      citizenAppliances += (citizenElec * 0.147 * 0.195);
    }

    if (formData.buildingOven === "yes") {
      citizenAppliances += (citizenElec * 0.061 * 0.7);
    }
    else if (formData.buildingOven === "semi") {
      citizenAppliances += (citizenElec * 0.147 * 0.434);
    }

    citizenElec -= citizenAppliances;

    setBuilding(parseFloat(((citizenHeat + citizenElec) / 1000).toFixed(2)));
    setBuildingE(parseFloat((eu / 1000).toFixed(2)));
    setBuildingC(parseFloat((country / 1000).toFixed(2)));
  };

  // function to calculate user and country waste management co2
  const handleWaste = () => {
    // Eq = recycling + composting + landfilling + energy recovery

    let citizen =
      // recycling = D*E*F*0.75*0.03*H
      // D(user) = user input
      // E(JSON) = country factor
      // F(JSON) = country recycling factor
      // H(JSON) = co2 per kWh
      formData.recycling *
        WasteJSON.countryFactor[0][formData.country] *
        WasteJSON.countryRecyclingFactor[0][formData.country] *
        0.75 *
        0.03 *
        WasteJSON.co2PerkWhFactor[0][formData.country] +
      // composting = D*E*0.25*0.12
      // D(user) = user input
      // E(JSON) = country factor
      formData.composting *
        WasteJSON.countryFactor[0][formData.country] *
        0.25 *
        0.12 +
      // energy recovery = D*E*G*1.05
      // D(user) = user input
      // E(JSON) = country factor
      // G(JSON) = energy recovery factor
      WasteJSON.initialValue *
        WasteJSON.countryFactor[0][formData.country] *
        1.05 *
        WasteJSON.countryEnergyRecoveryFactor[0][formData.country] +
      // landfilling = (((D*E)-(D*E*F)-(D*E*G))*0.8*1.25)+(((D*E)-(D*E*F)-(D*E*G))*0.2*0.3)
      // D(user) = user input
      // E(JSON) = country factor
      // F(JSON) = country recycling factor
      // G(JSON) = energy recovery factor
      ((formData.recycling * WasteJSON.countryFactor[0][formData.country] -
        formData.recycling *
          WasteJSON.countryFactor[0][formData.country] *
          WasteJSON.countryRecyclingFactor[0][formData.country] -
        formData.recycling *
          WasteJSON.countryFactor[0][formData.country] *
          WasteJSON.countryEnergyRecoveryFactor[0][formData.country]) *
        0.8 *
        1.25 +
        (formData.recycling * WasteJSON.countryFactor[0][formData.country] -
          formData.recycling *
            WasteJSON.countryFactor[0][formData.country] *
            WasteJSON.countryRecyclingFactor[0][formData.country] -
          formData.recycling *
            WasteJSON.countryFactor[0][formData.country] *
            WasteJSON.countryEnergyRecoveryFactor[0][formData.country]) *
          0.2 *
          0.3);
    

    let country;
    let eu;

    country = averages.waste[formData.country];
    eu = averages.waste['eu'];

    setWaste(parseFloat((citizen / 1000).toFixed(2)));
    setWasteC(parseFloat((country / 1000).toFixed(2)));
    setWasteE(parseFloat((eu / 1000).toFixed(2)));
  };

  // function to set country name on results tab
  const handleCountry = (e) => {
    setCountry(e.toUpperCase());
    setLocked(false);
  };

  // function to handle switching to a next tab
  function nextTabChange() {
    if (activeTab === 'welcome') {
      setActiveTab('demographic');
    }
    if (activeTab === 'demographic') {
      setActiveTab('cars');
    }
    if (activeTab === 'cars') {
      handleCar();
      setActiveTab('flights');
    }
    if (activeTab === 'flights') {
      handleFlight();
      setActiveTab('trains');
    }
    if (activeTab === 'trains') {
      handleTrain();
      setActiveTab('buildings');
    }
    if (activeTab === 'buildings') {
      handleBuilding();
      setActiveTab('wasteDisposal');
    }
    if (activeTab === 'wasteDisposal') {
      setActiveTab('results');
    }
  }

  // function to handle switching to a previous tab
  function prevTabChange() {
    if (activeTab === 'demographic') {
      setActiveTab('welcome');
    }
    if (activeTab === 'cars') {
      handleCar();
      setActiveTab('demographic');
    }
    if (activeTab === 'flights') {
      handleFlight();
      setActiveTab('cars');
    }
    if (activeTab === 'trains') {
      handleTrain();
      setActiveTab('flights');
    }
    if (activeTab === 'buildings') {
      handleBuilding();
      setActiveTab('trains');
    }
    if (activeTab === 'wasteDisposal') {
      handleWaste();
      setActiveTab('buildings');
    }
    if (activeTab === 'results') {
      setActiveTab('wasteDisposal');
    }
  }

  // function to call next tab change
  const toNextTab = (e) => {
    e.preventDefault();
    nextTabChange();
  };

  // function to call previous tab change
  const toPrevTab = (e) => {
    e.preventDefault();
    prevTabChange();
  };

  // function to change tabs from top navigation
  const changeTab = (e) => {
    if (activeTab === 'wasteDisposal') {
      handleWaste();
      setActiveTab(e);
    } else if (activeTab === 'buildings') {
      handleBuilding();
      setActiveTab(e);
    } else if (activeTab === 'flights') {
      handleFlight();
      setActiveTab(e);
    } else if (activeTab === 'trains') {
      handleTrain();
      setActiveTab(e);
    } else if (activeTab === 'cars') {
      handleCar();
      setActiveTab(e);
    } else {
      setActiveTab(e);
    }
  };

  const { t } = useTranslation();

  return (
    <div className="container my-3 p-2 bg-light rounded">
      <Tabs activeKey={activeTab} onSelect={(e) => changeTab(e)}>
        <Tab title={t("Welcome")} eventKey="welcome" id="welcome-tab">
          <Welcome
            toNextTab={toNextTab}
            handleCountry={handleCountry}
            handleStartDate={handleStartDate}
            handleEndDate={handleEndDate}
            formData={formData}
            setFormData={setFormData}
            locked={locked}
          />
        </Tab>
        <Tab
          title={t("Demographic")}
          eventKey="demographic"
          id="demographic-tab"
          disabled={locked}
        >
          <Demographic
            toNextTab={toNextTab}
            toPrevTab={toPrevTab}
            formData={formData}
            setFormData={setFormData}
          />
        </Tab>
        <Tab title={t("Vehicles")} eventKey="cars" id="cars-tab" disabled={locked}>
          <Cars
            toNextTab={toNextTab}
            toPrevTab={toPrevTab}
            handleCar={handleCar}
            formData={formData}
            setFormData={setFormData}
          />
        </Tab>
        <Tab
          title={t("Flights")}
          eventKey="flights"
          id="flights-tab"
          disabled={locked}
        >
          <Flights
            toNextTab={toNextTab}
            toPrevTab={toPrevTab}
            start={start}
            end={end}
            handleFlight={handleFlight}
            formData={formData}
            setFormData={setFormData}
          />
        </Tab>
        <Tab title={t("Trains")} eventKey="trains" id="trains-tab" disabled={locked}>
          <Trains
            toNextTab={toNextTab}
            toPrevTab={toPrevTab}
            start={start}
            end={end}
            handleTrain={handleTrain}
            formData={formData}
            setFormData={setFormData}
          />
        </Tab>
        <Tab
          title={t("Buildings")}
          eventKey="buildings"
          id="buildings-tab"
          disabled={locked}
        >
          <Buildings
            toNextTab={toNextTab}
            toPrevTab={toPrevTab}
            handleBuilding={handleBuilding}
            formData={formData}
            setFormData={setFormData}
          />
        </Tab>
        <Tab
          title={t("Waste Management")}
          eventKey="wasteDisposal"
          id="wasteDisposal-tab"
          disabled={locked}
        >
          <WasteDisposal
            toPrevTab={toPrevTab}
            handleWaste={handleWaste}
            formData={formData}
            setFormData={setFormData}
            showRes={showRes}
            toNextTab={toNextTab}
            country={country}
            carC = {carC}
            flightC = {flightC}
            trainC = {trainC}
            buildingC = {buildingC}
            wasteC = {wasteC}
            carE = {carE}
            flightE = {flightE}
            trainE = {trainE}
            buildingE = {buildingE}
            wasteE = {wasteE}
          />
        </Tab>
        <Tab
          title={t("Results")}
          eventKey="results"
          id="results-tab"
          disabled={showRes}
        >
          <Results
            toPrevTab={toPrevTab}
            country={country}
            carC={carC}
            trainC={trainC}
            buildingC={buildingC}
            wasteC={wasteC}
            car={car}
            flight={flight}
            train={train}
            building={building}
            waste={waste}
            formData={formData}
            carE={carE}
            trainE={trainE}
            buildingE={buildingE}
            wasteE={wasteE}
            start={start}
            end={end}
            flightC={flightC}
            flightE={flightE}
          />
        </Tab>
      </Tabs>
      <Alert alert={alert} hideAlert={hideAlert} status={status} />
    </div>
  );
};

export default Domains;
