import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import InsightsIcon from '@mui/icons-material/Insights';
import MapIcon from '@mui/icons-material/Map';

// import { useLocation } from "react-router-dom";
// import { useEffect } from "react";

  /* Hide footer when viewing through the Compair Project portal */
  // const currentPath = useLocation();
  // const isIntegrated = new URLSearchParams(currentPath.search).get('integration')

  const currentPath = window.location.search;
  let pathHome = ''
  if (currentPath.includes('integration=true') )
  {
    pathHome = '?integration=true'
  }

const menuItems = [
  {
    heading: '',
    items: [
      {
        name: 'Home',
        icon: BackupTableTwoToneIcon,
        link: '/home'+ pathHome,
        roles: []
      },
      
      {
        name: 'Calculator',
        icon: HealthAndSafetyTwoToneIcon,
        link: '/calculator'+ pathHome,
        roles: []
      },
      {
        name: 'Dashboard',
        icon: AnalyticsTwoToneIcon,
        link: '/dashboard'+ pathHome,
        roles: []
      },
      {
        name: 'Profile',
        link: '/profile'+ pathHome,
        icon: InsightsIcon,
        roles: ['user', 'admin']
        
      },
      {
        name: 'AdminPage',
        link: '/adminpage'+ pathHome,
        icon: DesignServicesTwoToneIcon,
        roles: ['admin']
      },
      {
        name: 'Map',
        link: '/map'+ pathHome,
        icon: MapIcon,
        roles: ['admin']
      },
    ]
  }
];

/* eslint-disable */
// add a function to filter menu items based on the user role(s)
function filterMenuItems(menuItems, userRoles) {
  return menuItems.map((menuItem) => {
    const filteredItems = menuItem.items.filter((item) => {
      /* disable eslint */
      if (item.roles) {
        // only show the item if the user has one of the required roles
        return item.roles.some((role) => userRoles.includes(role));
      } else {
        // show the item if it doesn't have any roles specified
        return true;
      }
    });
    return { ...menuItem, items: filteredItems };
  });
}
/* eslint-disable */

export default menuItems;
