import * as React from 'react';
import {
  Box,
  Card,
  Typography,
  List,
  Divider,
  IconButton,

} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Scrollbar from 'src/components/Scrollbar';
import { Fragment } from 'react';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';


export default function ScrollableTabsButtonAuto({cardMap,deleteRecommendation,toggleCancelButton,loadAction,toggleUpdateButton}) {

  const { t } = useTranslation();

  const preLoadedMap = new Map()
  preLoadedMap.set("1", {
    caseID: "",
    neededValueID: "",
    operator: "",
    neededValue: ""
  })

  const loadRecommendations = () => {

    let recommendationList = []

    let counter = 0
    cardMap.forEach(function (action) {
      counter += 1
      recommendationList.push(

        <Fragment key={counter}>
          <Box
            sx={{
              textAlign: 'center'
            }}
          >
            <Typography variant="subtitle2">
              {t(action.category)}
            </Typography>
            <Typography variant="subtitle2">
              {t(action.text)}
            </Typography>
            <Typography variant="subtitle2">
              {action.popsWhen}
            </Typography>
            <Typography variant="subtitle2">
              {
                action.cases.map(({ operator, neededValue, neededValueID, caseID }) =>

                <>
                  <Typography variant="subtitle2">
                    ID: {caseID} - {neededValueID} {operator} {neededValue}
                  </Typography>
                </>

              )}
            </Typography>

            <Box
              sx={{
                textAlign: 'center'
              }}
            >

              <IconButton color="primary" onClick={() => {
              loadAction(action._id)
              toggleCancelButton(true)
              toggleUpdateButton(true)
            }}>

              <LaunchTwoToneIcon fontSize="small" />
              <Typography variant="body2"> {t('Update')} </Typography>

            </IconButton>

            <IconButton color="error" onClick={() => {
                  deleteRecommendation(action._id)
                }}>

              <DeleteTwoToneIcon fontSize="small" />
              <Typography variant="body2"> {t('Delete')} </Typography>

            </IconButton>

            </Box>

            <Divider />
          </Box>
        </Fragment>
      )
    })

    return recommendationList

  }

  
  return (
    <>
      

          <Card>

            <Box
              p={2.2}
            >
              <Box >
                <Typography variant="h4">
                  {t('Recommendations')}
                </Typography>
              </Box>
            </Box>
            <Divider />


            <Box
              sx={{
                height: 540 // box length = chart length
              }}
            >
              <Scrollbar>

                <List disablePadding>

                  {loadRecommendations()}
                </List>
                <Box
                  px={2}
                  py={3}
                  sx={{
                    textAlign: 'center'
                  }}
                >
                  <Typography variant="subtitle2">
                    {t("You've reached the end of the list!")}!
                  </Typography>
                </Box>
              </Scrollbar>
            </Box>

          </Card>
     
    </>
  );

}