import React from 'react';
import User from './User';
import Country from './Country';
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import Modal from './Modal';
import Europe from './Europe';
import Dashboard from './Dashboard';
import Recommendations from './Recommendations';
import {Box, Grid, Button}  from "@mui/material"
import { useTranslation } from 'react-i18next';
// import { useEffect, useState } from 'react';
import { useRef } from "react";
// import axios from "axios";
import generatePDF from 'react-to-pdf';
import { NavLink } from 'react-router-dom';

const currentPath = window.location.search;
let pathHome = ''
if (currentPath.includes('integration=true') )
{
  pathHome = '?integration=true'
}

const Results = ({
  toPrevTab,
  country,
  carC,
  trainC,
  buildingC,
  wasteC,
  car,
  flight,
  train,
  building,
  waste,
  formData,
  carE,
  trainE,
  buildingE,
  wasteE,
  start,
  end,
  flightC,
  flightE
}) => {
  
  const { t } = useTranslation();
  
  const optionsCars = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'center',
        labels: {
          boxWidth: 12,
          usePointStyle: false
        }
      },
      title: {
        display: true,
        text: t("Vehicles")
      },
    },
    scales: {
      x: {
        barPercentage: 0.3, 
      },
    },
  };

  const dataCars = {
    labels: [t("tons CO2")],
    datasets: [
      {
        // label: t('You'),
        label: `${t('You')} - ${car}`,
        data: [car],
        backgroundColor: 'rgba(18, 88, 193, 0.5)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
      {
        label: `${t(country)} - ${carC}`,
        data: [carC],
        backgroundColor: 'rgba(10, 133, 255, 0.5)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
      {
        label: `${t('Europe')} - ${carE}`,
        data: [carE],
        backgroundColor: 'rgba(8, 78, 171, 0.5)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  };

  const optionsFlights = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'center',
        labels: {
          boxWidth: 12,
          usePointStyle: false
        }
      },
      title: {
        display: true,
        text: t("Flights")
      },
    },
    scales: {
      x: {
        barPercentage: 0.3, 
      },
    },
  };

  const dataFlights = {
    labels: [t("tons CO2")],
    datasets: [
      {
        label: `${t('You')} - ${flight}`,
        data: [flight],
        backgroundColor: 'rgba(176, 42, 54, 0.5)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
      {
        label: `${t(country)} - ${flightC}`,
        data: [flightC],
        backgroundColor: 'rgba(132, 31, 39, 0.5)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
      {
        label: `${t('Europe')} - ${flightE}`,
        data: [flightE],
        backgroundColor: 'rgba(88, 21, 24, 0.5)', 
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      }
    ],
  };

  const optionsTrains = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'center',
        labels: {
          boxWidth: 12,
          usePointStyle: false
        }
      },
      title: {
        display: true,
        text: t("Trains")
      },
    },
    scales: {
      x: {
        barPercentage: 0.3, 
      },
    },
  };

  const dataTrains = {
    labels: [t("tons CO2")],
    datasets: [
      {
        label: `${t('You')} - ${train}`,
        data: [train],
        backgroundColor: 'rgba(20, 108, 67, 0.5)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
      {
        label: `${t(country)} - ${trainC}`,
        data: [trainC],
        backgroundColor: 'rgba(15, 81, 50, 0.5)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
      {
        label: `${t('Europe')} - ${trainE}`,
        data: [trainE],
        backgroundColor: 'rgba(10, 54, 33, 0.5)', 
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      }
    ],
  };

  const optionsBuildings = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'center',
        labels: {
          boxWidth: 12,
          usePointStyle: false
        }
      },
      title: {
        display: true,
        text: t("Buildings")
      },
    },
    scales: {
      x: {
        barPercentage: 0.3, 
      },
    },
  };

  const dataBuildings = {
    labels: [t("tons CO2")],
    datasets: [
      {
        label: `${t('You')} - ${building}`,
        data: [building],
        backgroundColor: 'rgba(204, 154, 5, 0.5)',
        borderColor: 'rgba(204, 154, 5, 1)',
        borderWidth: 1,
      },
      {
        label: `${t(country)} - ${buildingC}`,
        data: [buildingC],
        backgroundColor: 'rgba(153, 115, 3, 0.5)',
        borderColor: 'rgba(204, 154, 5, 1)',
        borderWidth: 1,
      },
      {
        label: `${t('Europe')} - ${buildingE}`,
        data: [buildingE],
        backgroundColor: 'rgba(102, 77, 2, 0.5)', 
        borderColor: 'rgba(204, 154, 5, 1)',
        borderWidth: 1,
      }
    ],
  };

  const optionsWaste = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'center',
        labels: {
          boxWidth: 12,
          usePointStyle: false
        }
      },
      title: {
        display: true,
        text: t("Waste")
      },
    },
    scales: {
      x: {
        barPercentage: 0.3, 
      },
    },
  };

  const dataWaste = {
    labels: [t("tons CO2")],
    datasets: [
      {
        label: `${t('You')} - ${waste}`,
        data: [waste],
        backgroundColor: 'rgba(9, 161, 192, 0.5)',
        borderColor: 'rgba((9, 161, 192, 1)',
        borderWidth: 1,
      },
      {
        label: `${t(country)} - ${wasteC}`,
        data: [wasteC],
        backgroundColor: 'rgba(6, 121, 144, 0.5)',
        borderColor: 'rgba((9, 161, 192, 1)',
        borderWidth: 1,
      },
      {
        label: `${t('Europe')} - ${wasteE}`,
        data: [wasteE],
        backgroundColor: 'rgba(4, 81, 96, 0.5)', 
        borderColor: 'rgba((9, 161, 192, 1)',
        borderWidth: 1,
      }
    ],
  };


  const targetRef = useRef();
  
  return (
    <>
    
    <div ref={targetRef}>
    <Box sx={{border:1, borderRadius:1, borderColor:"lightgray"}} backgroundColor="white" >
    <div className="card">
        <div className="card-body">
          <h5 className="card-title text-center">{t("CO2 Calculator Results")}</h5>
          <div className="col-12 p-2">
            <div className="d-flex justify-content-between">

            <Grid item xs={1}>
              <Button variant = "text" onClick={(e) => toPrevTab(e)}>
              {t("Previous")}
              </Button>
            </Grid>

            <Grid item xs={1}>
            <Button 
                  variant="contained" color="primary" 
                  style={{ color: 'white' }}
                  onClick={() => generatePDF(targetRef, {filename: 'page.pdf'})} 
                >
                  {t("Export")} PDF 
            </Button>
            </Grid>

            </div>
          </div>
          <div className="row justify-content-center py-2">
            <div className="col-lg-7 py-2">
              <User
                car={car}
                flight={flight}
                train={train}
                building={building}
                waste={waste}
              />
            </div>
          </div>
          <div className="row justify-content-center py-2">
            <div className="col-lg-7 py-2">
              <Country
                carC={carC}
                trainC={trainC}
                buildingC={buildingC}
                wasteC={wasteC}
                country={country}
                flightC={flightC}
              />
            </div>
          </div>
          <div className="row justify-content-center py-2">
            <div className="col-lg-7 py-2">
              <Europe
                carE={carE}
                trainE={trainE}
                buildingE={buildingE}
                wasteE={wasteE}
                flightE={flightE}
              />
            </div>
          </div>

          <div className="row justify-content-center py-2">
            <div className="col-lg-7 py-2">
              <Dashboard
                country={country}
              />
            </div>
          </div>

          <div className="row justify-content-center align-items-center mt-3">

            <Grid container spacing={2}>
              <Grid item xs={12} md={4} >
                <div className="my-3">
                  <Bar options={optionsCars} data={dataCars}  />
                </div>
                {/* <div className="car-information">
                  {carInformation}
                </div> */}
              </Grid>
              <Grid item xs={12} md={4} >
                <div className="my-3">
                  <Bar options={optionsFlights} data={dataFlights}  />
                </div>
              </Grid>
              <Grid item xs={12} md={4} >
                <div className="my-3">
                  <Bar options={optionsTrains} data={dataTrains}  />
                </div>
              </Grid>
              <Grid item xs={12} md={4} >
                <div className="my-3">
                  <Bar options={optionsBuildings} data={dataBuildings}  />
                </div>
              </Grid>
              <Grid item xs={12} md={4} >
                <div className="my-3">
                  <Bar options={optionsWaste} data={dataWaste}  />
                </div>
              </Grid>
            </Grid>
          </div>

        </div>
      </div>
      <Modal
        formData={formData}
        car={car}
        flight={flight}
        train={train}
        building={building}
        waste={waste}
        carC={carC}
        trainC={trainC}
        buildingC={buildingC}
        wasteC={wasteC}
        carE={carE}
        trainE={trainE}
        buildingE={buildingE}
        wasteE={wasteE}
        country={country}
        start={start}
        end={end}
        flightC={flightC}
        flightE={flightE}
      />
      <Recommendations
        countryCar={carC}
        countryTrain={trainC}
        countryBuilding={buildingC}
        countryWaste={wasteC}
        countryFlight={10}
        calculatedCar={car}
        calculatedFlight={flight}
        calculatedTrain={train}
        calculatedBuilding={building}
        calculatedWaste={waste}
        formData={formData}
        // jsonData = {jsonData}
      />
    </Box>

    <Box sx={{border:1, borderRadius:1, borderColor:"lightgray"}} backgroundColor="white" >
    <div >

      <p className="lead text-center fs-4 p-2">
        {t("Now that you know your carbon footprint, give us your opinion on how to reduce emissions.")}
      </p>

      <div className="d-flex justify-content-center">
        <div className="p-2">

          <Button variant="outlined" size="large" component={NavLink} to={`/dashboard` + pathHome}>
            {t("Scenario Simulation")}
          </Button>
        </div>
      </div>

      </div>
    </Box>

    </div>
    </>
  );
};

export default Results;
