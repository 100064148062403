import { useState } from 'react';
import {
  Box,
  Card,
  Typography,
  List,
  Divider,
  ToggleButtonGroup,
  useTheme,
  Grid,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import Scrollbar from 'src/components/Scrollbar';
import { Fragment } from 'react';
import { styled } from "@mui/material/styles";
import MuiToggleButton from "@mui/material/ToggleButton";


function SliderListAdmin({ sliders,  deleteAction,loadPrefilledValues,  setToUpdate }) {

  const ToggleButton = styled(MuiToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: 'primary'
    }
  });

  const { t } = useTranslation();

  const userCountry = "Greece"
  const theme = useTheme();
  const [tabs, setTab] = useState('activity');

  const handleViewOrientation = (_event, newValue) => {
    setTab(newValue);
  };

  const citizenSliders = []
  const governmentSliders = []
  const localGovernmentSliders = []

  sliders.forEach(slider => {
    if (slider.category === "Citizen") {
      citizenSliders.push(slider)
    } 
    if (slider.category === "Government") {
      governmentSliders.push(slider)
    }
    if (slider.category === "localGov") {
      localGovernmentSliders.push(slider)
    }
  });

  const stringToFakeFloat = (stringNumber) => {
    let onlyDecimals = stringNumber.substring(2)
    let fakeFloat

    let intPart = onlyDecimals.slice(0,2)
    if ( intPart.charAt(0) === "0" ){
      intPart = intPart.substring(1)
    }
    let floatPart = onlyDecimals.slice(2)

    if ( floatPart.length > 0){
      fakeFloat = intPart+","+floatPart
    }else{
      fakeFloat = intPart
    }
    return fakeFloat
  }

  const createActionList = (tempSliderList) => {
    let actionsList = []

    let counter = 0
    tempSliderList.forEach(function (cslider) {
      counter+=1
      actionsList.push(

        <Fragment key = {counter}>
          <Box
            sx={{
              textAlign: 'center'
            }}
          >
            <Typography variant="subtitle2">
              {t(cslider["name"]["en"])}
            </Typography>

            <Typography variant="subtitle2">
              {/* {presentList(Object.keys(cslider["titles"]))}   */}
            </Typography>
            <Typography variant="subtitle2">
              {cslider["countriesData"][userCountry]["contribution"]} total ({stringToFakeFloat(cslider["countriesData"][userCountry]["contribution"])}%) 
            </Typography>
                        
          </Box>

          <Box
            sx={{
              textAlign: 'center'
            }}
          >

            <Grid container>

              <Grid item xs={4}>
                <Box
                  sx={{
                    textAlign: 'center'
                  }}
                >
                  <Typography variant="subtitle2">
                  {t(cslider["titles"]["en"][1])}
                  </Typography>

                  <Typography variant="subtitle2">
                    {cslider["countriesData"][userCountry]["percentages"][1]} ({stringToFakeFloat(cslider["countriesData"][userCountry]["percentages"][1])}%)
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box
                  sx={{
                    textAlign: 'center'
                  }}
                >
                  <Typography variant="subtitle2">
                    {t(cslider["titles"]["en"][2])}
                  </Typography>

                  <Typography variant="subtitle2">
                    {cslider["countriesData"][userCountry]["percentages"][2]} ({stringToFakeFloat(cslider["countriesData"][userCountry]["percentages"][2])}%)
                  </Typography>

                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box
                  sx={{
                    textAlign: 'center'
                  }}
                >
                  <Typography variant="subtitle2">
                    {t(cslider["titles"]["en"][3])}
                  </Typography>

                  <Typography variant="subtitle2">
                  {cslider["countriesData"][userCountry]["percentages"][3]} ({stringToFakeFloat(cslider["countriesData"][userCountry]["percentages"][3])}%)
                  </Typography> 

                </Box>
              </Grid>

            </Grid>
          </Box>

          <Box
            sx={{
              textAlign: 'center'
            }}
          >

            <IconButton color="primary" onClick={() => {
                loadPrefilledValues(cslider._id);
                setToUpdate("true");
            }}>

              <LaunchTwoToneIcon fontSize="small" />
              <Typography variant="body2">Update</Typography>

            </IconButton>

            <IconButton color="error" onClick={() => {
              deleteAction(cslider._id)
            }}>

              <DeleteTwoToneIcon fontSize="small" />
              <Typography variant="body2">Delete</Typography>

            </IconButton>
          
          </Box>

          <Divider />

        </Fragment>
      )
    }
    )
    return actionsList
  }

  return (
    <Card>
      <Box
        p={2.2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography gutterBottom variant="h4">
            {t('Actions')}
          </Typography>
          <Typography variant="subtitle2">
            {t('Select slider action')}
          </Typography>
        </Box>
        <ToggleButtonGroup
          size="small"
          value={tabs}
          exclusive
          onChange={handleViewOrientation}

        >
          <ToggleButton
            sx={{
              px: 4,
              py: 0.5,
              lineHeight: 1.5,
              fontSize: `${theme.typography.pxToRem(12)}`,
            }}
            disableRipple
            value="activity"
          >
            {t('Citizen')}
          </ToggleButton>
          <ToggleButton
            sx={{
              px: 2,
              py: 0.5,
              lineHeight: 1.5,
              fontSize: `${theme.typography.pxToRem(12)}`
            }}
            disableRipple
            value="trading"
          >
            {t('Government')}
          </ToggleButton>

          <ToggleButton
            sx={{
              px: 4,
              py: 0.5,
              lineHeight: 1.5,
              fontSize: `${theme.typography.pxToRem(12)}`,
            }}
            disableRipple
            value="localGov"
          >
            {t('Local Government')}
          </ToggleButton>

        </ToggleButtonGroup>
      </Box>
      <Divider />

      {tabs === 'activity' && (
        <>
          <Box
            sx={{
              height: 460 // box length = chart length
            }}
          >
            <Scrollbar>

              <List disablePadding>

              {createActionList(citizenSliders)}

              </List>
              <Box
                px={2}
                py={3}
                sx={{
                  textAlign: 'center'
                }}
              >
                <Typography variant="subtitle2">
                  {t("You've reached the end of the list")}!
                </Typography>
              </Box>
            </Scrollbar>
          </Box>
          <Divider />

        </>
      )}

      {
        /* 
        tabs === 'add' && (
          <>
            <Box
              sx={{
                height: 515
              }}
            >
             <SliderAdder addSlider={addSlider}/>
            </Box>
           
          </>
            )
            */
      }
      {tabs === 'trading' && (
        <>
          <Box
            sx={{
              height: 460 // box length = chart length
            }}
          >
            <Scrollbar>

              <List disablePadding>
                
              {createActionList(governmentSliders)}

              </List>
              <Box
                px={2}
                py={3}
                sx={{
                  textAlign: 'center'
                }}
              >
                <Typography variant="subtitle2">
                  {t("You've reached the end of the list")}!
                </Typography>
              </Box>
            </Scrollbar>
          </Box>
          <Divider />

        </>
      )}

      {tabs === 'localGov' && (
        <>
          <Box
            sx={{
              height: 460 // box length = chart length
            }}
          >
            <Scrollbar>

              <List disablePadding>
                
              {createActionList(localGovernmentSliders)}

              </List>
              <Box
                px={2}
                py={3}
                sx={{
                  textAlign: 'center'
                }}
              >
                <Typography variant="subtitle2">
                  {t("You've reached the end of the list")}!
                </Typography>
              </Box>
            </Scrollbar>
          </Box>
          <Divider />

        </>
      )}

      {!tabs && (
        <Box
          p={3}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            height: 440,
            textAlign: 'center'
          }}
        >
          <Box>
            <Typography
              align="center"
              variant="h2"
              fontWeight="normal"
              color="text.secondary"
              sx={{
                mt: 3
              }}
              gutterBottom
            >
              {t('Select one of the tabs to continue')}
            </Typography>

          </Box>
        </Box>
      )}
    </Card>
  );
}

export default SliderListAdmin;
