import Authenticated from 'src/components/Authenticated';
import DocsLayout from 'src/layouts/DocsLayout';
import OldBottomNowTopNavigationLayout from 'src/layouts/BottomNavigationLayout';
import documentationRoutes from './documentation';
import Home from 'src/components/LandingPage/Home';
import { Navigate } from 'react-router-dom';
import Simulation from 'src/components/Simulation/Simulation';
import AdminPage from 'src/components/AdminPage';
import Calculator from 'src/components/Calculator';
import Profile from 'src/components/Profile';
import Footer from 'src/components/NewFooter';
import MapComponent from 'src/components/Map';


const router = [
  {
    path: '/',
    element: (
      <Authenticated>
        <OldBottomNowTopNavigationLayout />
        <Footer />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="home" replace />,
      },
      {
        path: 'home',
        element: <Home />
      },
      {
        path: 'dashboard',
        element: <Simulation />
      },
      {
        path: 'calculator',
        element: <Calculator />,
      },
      {
        path: 'map',
        element: <MapComponent />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
      {
        path: 'adminpage',
        element: <AdminPage />,
        access: 'admin',
      }
    ]
  },
  {
    path: 'co2dashboard/about',
    element: (
      <Authenticated>
        <Home/>
      </Authenticated>
    )
  },

  {
    path: 'co2dashboard/docs',
    element: <DocsLayout />,
    children: documentationRoutes
  }
];

export default router;
