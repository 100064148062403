import React from 'react';
import {Button} from "@mui/material"
import {Box, Grid, TextField} from "@mui/material"
import CarComponent from './CarComponent';
import { useTranslation } from 'react-i18next';


const Cars = ({ toNextTab, toPrevTab, handleCar, formData, setFormData }) => {

  const carsForm = (e) => {
    e.preventDefault();
    handleCar();
  };

  const addCar = () => {
    const newCarDistance = [...formData.car_distance, 0];
    const newLiters = [...formData.liters, 0];
    const newFuel = [...formData.fuel, ''];
    // const newElectric = [...formData.electric, false];
  
    setFormData({
      ...formData,
      car_distance: newCarDistance,
      liters: newLiters,
      fuel: newFuel,
      // electric: newElectric,
    });
  };

  const removeLastCar = () => {
    if (formData.car_distance.length > 0) {
      const newCarDistance = [...formData.car_distance];
      const newLiters = [...formData.liters];
      const newFuel = [...formData.fuel];
      // const newElectric = [...formData.electric];
  
      newCarDistance.pop();
      newLiters.pop();
      newFuel.pop();
      // newElectric.pop();
  
      setFormData({
        ...formData,
        car_distance: newCarDistance,
        liters: newLiters,
        fuel: newFuel,
        // electric: newElectric,
      });
    }
  };
  
  const { t } = useTranslation();

  return (
    <>
      <Box sx={{ border: 1, borderRadius: 1, borderColor: "lightgray" }} backgroundColor="white">
        <form onSubmit={(e) => { carsForm(e) }}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sx={{ px: 2, py: 1, textAlign: 'center' }}>
              <h5> {t('CO2 from Vehicles')} </h5>
            </Grid>
            <Grid item xs={12} sx={{ px: 2, py: 1, textAlign: 'center' }}>
              <h6> {t('Vehicles Info Text')} </h6>
            </Grid>
            
            <Grid item xs={6} sm={6} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            {t("From")}
          </Grid>
  
          <Grid item xs={6} sm={6} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            {t("To")}
          </Grid>
  
          <Grid item xs={6} sm={6} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            <TextField
              key={formData.startDate}
              defaultValue={formData.startDate}
              variant="outlined"
              InputProps={{
                readOnly: true,
                inputProps: { style: { textAlign: 'center' } }
              }}
              fullWidth
              size="small"
            />
          </Grid>

          <Grid item xs={6} sm={6} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            <TextField
              key={formData.endDate}
              defaultValue={formData.endDate}
              variant="outlined"
              InputProps={{
                readOnly: true,
                inputProps: { style: { textAlign: 'center' } }
              }}
              fullWidth
              size="small"
            />
          </Grid>
            
            <Grid item xs={12}>
              <CarComponent
                formData={formData}
                setFormData={setFormData}
              />
            </Grid>

            <Grid item xs={12} sx={{ px: 2, py: 1, textAlign: 'center' }}>
              <Button variant="text" onClick={addCar} fullWidth>
              {t('Add Vehicle')}
              </Button>
              {formData.car_distance.length > 0 && (
                <Button variant="text" onClick={removeLastCar} fullWidth>
                  {t('Remove Last Vehicle')}
                </Button>
              )}
            </Grid>
            <Grid item xs={6}>
        <Button variant="text" onClick={(e) => toPrevTab(e)} fullWidth>
          {t("Previous")}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button variant="text" onClick={(e) => toNextTab(e)} fullWidth>
          {t("Next")}
        </Button>
      </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );

};

export default Cars;