import {
  Box,
  List,
  Card,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography

} from '@mui/material';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';


function ScenarioAdder({ cancelButton,showCancelButton, presaveScenario, fieldsEmptyError, translationLanguage, changeTranslation, clear, addScenario, toUpdate, setToUpdate, loadedScenario, scenarioTitle, scenarioDescription, setScenarioTitle, setScenarioDescription, updateScenario }) {


  const { t } = useTranslation();


  return (
    <Card>

      <List >
        <FormControl variant="standard" sx={{ minWidth: 120 }}>
          <InputLabel id="demo-simple-select-standard-label">Action Language</InputLabel>
          <Select
            label="Translation selector"
            variant="standard"
            value={translationLanguage}
            onChange={(e) => {
              changeTranslation(e.target.value)
            }}
          >
            <MenuItem value={"en"}>English</MenuItem>
            <MenuItem value={"gr"}>Greek</MenuItem>

          </Select>
        </FormControl>

        <Box>
        <Typography variant="h7" align='center'>
          {t('Please enter a title and a description for your scenario.')}
        </Typography>   
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ mt: 1.5 }}
        >
          <TextField id="standard-basic"
            label={t('Scenario Title')}
            variant="standard"
            value={scenarioTitle}
            onChange={(e) => {
              setScenarioTitle(e.target.value)
              showCancelButton(true)
            }}
          />
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ mt: 1.5 }}
        >

          <TextField id="standard-basic"
            label={t('Scenerio Description')}
            variant="standard"
            value={scenarioDescription}
            onChange={(e) => { setScenarioDescription(e.target.value) }}
          />


        </Box>

        
        {(toUpdate === 'true') ? (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ mt: 1.5 }}
            >
              <Button
                variant="outlined"
                size="medium"
                onClick={() => {
                  setToUpdate("false")
                  clear()
                }}          >
                {t('Cancel')}
              </Button>
              <Button
                variant="outlined"
                size="medium"
                onClick={() => {
                  setToUpdate("false")
                  updateScenario(loadedScenario)
                }}          >
                {t('Update')}
              </Button>
              {(translationLanguage !== "en") ? (

                <Button
                  variant="outlined"
                  size="medium"
                  onClick={() => {
                    presaveScenario()
                  }}
                >
                  add translation
                </Button>

              ) : (null)}
            </Box>

          </>
        ) : (

          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ mt: 1.5 }}
          >
             {(cancelButton) ? (
              

              <Button
                variant="outlined"
                size="medium"
                onClick={() => {
                  clear()
                }}
              >
                {t('Cancel')}
              </Button>

            ) : (null)}
            

            <Button
              variant="outlined"
              size="medium"
              onClick={() => {
                addScenario(scenarioTitle)
              }}          >
              {t('Add Scenario')}
            </Button>

            {(translationLanguage !== "en") ? (
              

              <Button
                variant="outlined"
                size="medium"
                onClick={() => {
                  presaveScenario()
                }}
              >
                add translation
              </Button>

            ) : (null)}
          </Box>
        )}
        {(fieldsEmptyError === true) ? (
          <Typography color="red" sx={{ ml: 2, mt: 2 }} > {t("Empty Fields Error Text")} </Typography>
        ) : (null)}
      </List>

      {/*
      </div>  
      */}
    </Card>
  );
}

export default ScenarioAdder;
