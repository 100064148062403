import React from 'react';
import {Grid,TextField, FormControl, InputLabel, Select, MenuItem} from "@mui/material";
import { t } from 'i18next';


const CarComponent = ({formData, setFormData}) => {

    const handleDistance = (distance,id) =>{
        let newCarDistance = formData.car_distance.map((distanceValue, carIndex)=>{
            if (carIndex===id) {
                return distance
            }
            return distanceValue
            
        })

        setFormData((prevState) => ({
            ...prevState,
            car_distance: newCarDistance,
          }));
    }

    const handleLiters = (liters, id) => {
        let newLiters = formData.liters.map((litersValue, carIndex)=>{
            if (carIndex===id) {
                return liters
            }
            return litersValue
        })

        setFormData((prevState) => ({
            ...prevState,
            liters: newLiters,
          }));
    }

    const handleFuel = (fuel, id) => {
        let newFuel = formData.fuel.map((fuelValue, carIndex)=>{
            if (carIndex===id) {
                return fuel
            }
            return fuelValue
        })

        setFormData((prevState) => ({
            ...prevState,
            fuel: newFuel,
          }));
          
    }

    const loadCars = (formData) => {
        let carList = [];
        let repeats = formData.car_distance.length;
      
        for (let counter = 0; counter < repeats; counter++) {
            if (counter > 0) {
                carList.push(
                  <Grid item xs={12} sx={{ height: 16 }} key={`break-${counter}`} />
                );
              }
              
          carList.push(
            
            <Grid container key={counter}>
              {/* Distance */}
              <Grid item xs={12} sm={4} sx={{ px: 4, py: 0.5, lineHeight: 1.5 }}>
                {t('Distance Question')}
              </Grid>
              <Grid item xs={12} sm={6} sx={{ px: 4, py: 0.5, lineHeight: 1.5 }}>
                <TextField
                  required
                  label={t("Required")}
                  defaultValue={formData.car_distance[counter]}
                  variant="outlined"
                  type="number"
                  onChange={(e) => handleDistance(e.target.value, counter)}
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                  fullWidth
                />
              </Grid>
      
              {/* Liters/100km or kWh/100km */}
              <Grid item xs={12} sm={4} sx={{ px: 4, py: 0.5, lineHeight: 1.5 }}>
                {t('Liters/100km or kWh/100km')}
              </Grid>
              <Grid item xs={12} sm={6} sx={{ px: 4, py: 0.5, lineHeight: 1.5 }}>
                <TextField
                  required
                  label={t("Required")}
                  defaultValue={formData.liters[counter]}
                  variant="outlined"
                  type="number"
                  onChange={(e) => handleLiters(e.target.value, counter)}
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                  fullWidth
                />
              </Grid>
      
              {/* Type of Fuel */}
              <Grid item xs={12} sm={4} sx={{ px: 4, py: 0.5, lineHeight: 1.5 }}>
                {t('Type of Fuel Question')}
              </Grid>
              <Grid item xs={12} sm={6} sx={{ px: 4, py: 0.5, lineHeight: 1.5 }}>
                <FormControl fullWidth>
                  <InputLabel required label={t("Required")} id="selectlabel">
                    {/* Fuel Type */}
                    {t('Type of Fuel Question')}
                  </InputLabel>
                  <Select
                    labelId="selectLabel"
                    defaultValue={formData.fuel[counter]}
                    value={formData.fuel[counter]}
                    onChange={(e) => handleFuel(e.target.value, counter)}
                    style={{ textAlign: 'center' }}
                  >
                    <MenuItem value={"cng"}> {t('CNG')} </MenuItem>
                    <MenuItem value={"diesel"}>{t('Diesel')} </MenuItem>
                    <MenuItem value={"electricity"}> {t('Electricity')} </MenuItem>
                    <MenuItem value={"lpg"}> {t('LPG')} </MenuItem>
                    <MenuItem value={"petrol"}> {t('Petrol')} </MenuItem>
                    <MenuItem value={"petrol+e10"}> {t('Petrol + E10')} </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          );
        }
        return carList;
      };


 return (
    <Grid container>
        {loadCars(formData)}
    </Grid>

 );

};

export default CarComponent;