import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faShoePrints } from '@fortawesome/free-solid-svg-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

const Europe = ({ carE, trainE, buildingE, wasteE, flightE }) => {
  const { t } = useTranslation();

  const carPop = (
    <Popover
      id="popover-basic"
      style={{ zIndex: 2000 }} // Adjust the z-index as needed
    >
      <Popover.Header as="h3" className="bg-primary text-white">
        { t("Vehicles") }
      </Popover.Header>
      <Popover.Body>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            { t("Vehicles") + ': ' +
              carE +
              ' / '+ t("Total") + ': ' +
              (carE + buildingE + wasteE + trainE).toFixed(2) +
              t("tons CO2")}
          </li>
          <li className="list-group-item">
            {  t("Percentage") + ': '+
              ((carE * 100) / (carE + buildingE + wasteE + trainE)).toFixed(2) +
              '%'}
          </li>
        </ul>
      </Popover.Body>
    </Popover>
  );
  const flightPop = (
    <Popover
    id="popover-basic"
    style={{ zIndex: 2000 }} // Adjust the z-index as needed
  >
      <Popover.Header as="h3" className="bg-danger text-white">
        { t("Flights") }
      </Popover.Header>
      <Popover.Body>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
          { t("Flights") + ': ' +
              flightE +
              ' / '+ t("Total") + ': ' +
              (carE + flightE + trainE + buildingE + wasteE).toFixed(2) +
              t("tons CO2")}
          </li>
          <li className="list-group-item">
            {  t("Percentage") + ': '+
              (
                (flightE * 100) /
                (carE + flightE + trainE + buildingE + wasteE)
              ).toFixed(2) +
              '%'}
          </li>
        </ul>
      </Popover.Body>
    </Popover>
  );
  const trainPop = (
    <Popover
      id="popover-basic"
      style={{ zIndex: 2000 }} // Adjust the z-index as needed
    >
      <Popover.Header as="h3" className="bg-success text-white">
      { t("Trains") }
      </Popover.Header>
      <Popover.Body>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            { t("Trains") + ': ' +
              trainE +
              ' / '+ t("Total") + ': ' +
              (carE + buildingE + wasteE + trainE).toFixed(2) +
              t("tons CO2")}
          </li>
          <li className="list-group-item">
            {  t("Percentage") + ': '+
              ((trainE * 100) / (carE + buildingE + wasteE + trainE)).toFixed(
                2
              ) +
              '%'}
          </li>
        </ul>
      </Popover.Body>
    </Popover>
  );
  const buildingPop = (
    <Popover
      id="popover-basic"
      style={{ zIndex: 2000 }} // Adjust the z-index as needed
    >
      <Popover.Header as="h3" className="bg-warning text-white">
        { t("Buildings") }
      </Popover.Header>
      <Popover.Body>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            { t("Buildings")   + ': ' +
              buildingE +
              ' / '+ t("Total") + ': ' +
              (carE + buildingE + wasteE + trainE).toFixed(2) +
              t("tons CO2")}
          </li>
          <li className="list-group-item">
            {  t("Percentage") + ': '+
              (
                (buildingE * 100) /
                (carE + buildingE + wasteE + trainE)
              ).toFixed(2) +
              '%'}
          </li>
        </ul>
      </Popover.Body>
    </Popover>
  );
  const wastePop = (
    <Popover
      id="popover-basic"
      style={{ zIndex: 2000 }} // Adjust the z-index as needed
    >
      <Popover.Header as="h3" className="bg-info text-white">
        { t("Waste") }
      </Popover.Header>
      <Popover.Body>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            {t("Waste")   + ': ' +
              wasteE +
              ' / '+ t("Total") + ': ' +
              (carE + buildingE + wasteE + trainE).toFixed(2) +
              t("tons CO2")}
          </li>
          <li className="list-group-item">
            {  t("Percentage") + ': '+
              ((wasteE * 100) / (carE + buildingE + wasteE + trainE)).toFixed(
                2
              ) +
              '%'}
          </li>
        </ul>
      </Popover.Body>
    </Popover>
  );

  const progressStyles = {
    width: '20%',
    height: '100%',
  };

  return (
    // <div>
    <Grid container alignItems="flex-end" justify="flex-start" spacing={1}>

      {/* <div className="d-flex justify-content-center p-1">
        <span className="fs-3">{ t("Europe") }</span>
      </div> */}
      <Grid item style={{ paddingTop: '16px', width: '100px' }}>
          <span style={{ fontSize: '1.5rem' }}>{ t("Europe") }</span>
      </Grid>
      {/* <div className="d-flex justify-content-center p-2">
        <FontAwesomeIcon
          icon={faShoePrints}
          className="fa-3x"
          style={{ color: 'rgb(0, 204, 0)' }}
        />
      </div> */}

      <Grid item xs className="ml-3">
        <div className="progress" style={{ height: '20px', backgroundColor: '#00000000' }}>
          <div className="text-center" style={{ width: '20%', fontWeight: 'bold', fontSize: '1.2em' }}>
            {t("Vehicles")}
          </div>
          <div className="text-center" style={{ width: '20%', fontWeight: 'bold', fontSize: '1.2em' }}>
            {t("Flights")}
          </div>
          <div className="text-center" style={{ width: '20%', fontWeight: 'bold', fontSize: '1.2em' }}>
            {t("Trains")}
          </div>
          <div className="text-center" style={{ width: '20%', fontWeight: 'bold', fontSize: '1.2em' }}>
            {t("Buildings")}
          </div>
          <div className="text-center" style={{ width: '20%', fontWeight: 'bold', fontSize: '1.2em' }}>
            {t("Waste")}
          </div>
        </div>

      <div className="progress" style={{ height: '20px' }}>
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="top"
          overlay={carPop}
        >
          {/* <div
            className="progress-bar text-wrap"
            role="button"
            style={{
              width:
                ((carE * 100) / (carE + buildingE + wasteE + trainE)).toFixed(
                  2
                ) + '%'
            }}
          >
            { t("Cars") }
          </div> */}
            <div style={{ ...progressStyles, backgroundColor: '#F2F5F9', width: '20%' }}>

              <div
                className="progress-bar text-wrap" 
                role="button"
                style={{
                  width:
                    (
                      (carE * 100) / 
                      (carE + buildingE + wasteE + trainE)
                    ).toFixed(2) + '%',
                  backgroundColor: '#0D6EFD'
                }}
                
              >
                {/* { t("Cars") } */}
                &nbsp;
              </div>
              </div>          

        </OverlayTrigger>


        <OverlayTrigger
          trigger="click"
          rootClose
          placement="top"
          overlay={flightPop}
        >
          {/* <div
            className="progress-bar bg-danger text-wrap"
            role="button"
            style={{
              width:
                (
                  (flightE * 100) /
                  (carE + flightE + trainE + buildingE + wasteE)
                ).toFixed(2) + '%'
            }}
          >
            { t("Flights") }
          </div> */}
            <div style={{ ...progressStyles, backgroundColor: '#F2F5F9', width: '20%' }}>
              <div
                className="progress-bar text-wrap"
                role="button"
                style={{
                  width:
                    (
                      (flightE * 100) /
                      (carE + flightE + trainE + buildingE + wasteE)
                    ).toFixed(2) + '%',
                  backgroundColor: '#DC3545'
                }}
              >
                {/* { t("Flights") } */}
                &nbsp;
              </div>          
            </div>

        </OverlayTrigger>


        <OverlayTrigger
          trigger="click"
          rootClose
          placement="top"
          overlay={trainPop}
        >
          {/* <div
            className="progress-bar bg-success text-wrap"
            role="button"
            style={{
              width:
                ((trainE * 100) / (carE + buildingE + wasteE + trainE)).toFixed(
                  2
                ) + '%'
            }}
          >
            { t("Trains") }
          </div> */}
            <div style={{ ...progressStyles, backgroundColor: '#F2F5F9', width: '20%' }}>
            
              <div
                className="progress-bar text-wrap"
                role="button"
                style={{
                  width:
                    (
                      (trainE * 100) / 
                      (carE + buildingE + wasteE + trainE)
                    ).toFixed(2) + '%',
                  backgroundColor: '#198754'
                }}
              >
                {/* { t("Trains") } */}
                &nbsp;
              </div>
            </div>  

        </OverlayTrigger>

      
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="top"
          overlay={buildingPop}
        >
          {/* <div
            className="progress-bar bg-warning text-wrap"
            role="button"
            style={{
              width:
                (
                  (buildingE * 100) /
                  (carE + buildingE + wasteE + trainE)
                ).toFixed(2) + '%'
            }}
          >
            { t("Buildings") }
          </div> */}

            <div style={{ ...progressStyles, backgroundColor: '#F2F5F9', width: '20%' }}>
              <div
                className="progress-bar text-wrap"
                role="button"
                style={{
                  width:
                    (
                      (buildingE * 100) /
                      (carE + buildingE + wasteE + trainE)
                    ).toFixed(2) + '%',
                  backgroundColor: '#FFC107'
                }}
              >
                {/* { t("Buildings") } */}
                &nbsp;
              </div>
            </div>
        </OverlayTrigger>
        
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="top"
          overlay={wastePop}
        >
          {/* <div
            className="progress-bar bg-info text-wrap"
            role="button"
            style={{
              width:
                ((wasteE * 100) / (carE + buildingE + wasteE + trainE)).toFixed(
                  2
                ) + '%'
            }}
          >
            { t("Waste") }
          </div> */}

          <div style={{ ...progressStyles, backgroundColor: '#F2F5F9', width: '20%' }}>
            <div
              className="progress-bar text-wrap"
              role="button"
              style={{
                width:
                  ((wasteE * 100) / 
                  (carE + buildingE + wasteE + trainE)
                  ).toFixed(2) + '%',
                backgroundColor: '#0DCAF0'
              }}
            >
              {/* { t("Waste") } */}
              &nbsp;
            </div>
          </div>
        </OverlayTrigger>
      </div>
      </Grid>
      </Grid>
      // </div>
  );
};

export default Europe;
