import React from 'react';
// import Tooltip from '@mui/material/Tooltip';
import {Button} from "@mui/material"
import {Box, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, FormGroup, FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography} from "@mui/material"
import {useState} from 'react';
import { useTranslation } from 'react-i18next';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
// import { useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';



const Buildings = ({
  toNextTab,
  toPrevTab,
  handleBuilding,
  formData,
  setFormData
}) => {
  const buildingsForm = (e) => {
    e.preventDefault();
    handleBuilding();
  };

  const [fuelTypeMeasurement, setFuelTypeMeasurement] = useState('');

  const handleFuel = (e) => {
      let type = e.target.value
      if (type==='diesel') {
        setFuelTypeMeasurement('lt')
      }
      if (type==='coal') {
        setFuelTypeMeasurement('kg')
      }
      if (type==='biomass') {
        setFuelTypeMeasurement('kg')
      }
      if (type==='naturalGas') {
        setFuelTypeMeasurement('m3')
      }
      if (type==='nofuel') {
        setFuelTypeMeasurement(' ')
      }
      setFormData({ ...formData, building_fuel_type: type })
  }

  const { t } = useTranslation();

  return (
    <>
    <Box sx={{border:1, borderRadius:1, borderColor:"lightgray"}} backgroundColor="white" >
    <form onSubmit={(e) => {buildingsForm(e)}}>
    <Grid container justifyContent="center" alignContent="center">
      <Grid item sx={{px: 4, py: 0.5, lineHeight: 1.5,}}>
        <h5> {t("CO2 from Buildings")} </h5>
      </Grid>

      <Grid item xs={12}> </Grid>

      <Grid container justifyContent="center" alignContent="center">
          <Grid item xs={6} sm={6} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            {t("From")}
          </Grid>
  
          <Grid item xs={6} sm={6} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            {t("To")}
          </Grid>
  
          <Grid item xs={6} sm={6} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            <TextField
              key={formData.startDate}
              defaultValue={formData.startDate}
              variant="outlined"
              InputProps={{
                readOnly: true,
                inputProps: { style: { textAlign: 'center' } }
              }}
              fullWidth
              size="small"
            />
          </Grid>

          <Grid item xs={6} sm={6} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            <TextField
              key={formData.endDate}
              defaultValue={formData.endDate}
              variant="outlined"
              InputProps={{
                readOnly: true,
                inputProps: { style: { textAlign: 'center' } }
              }}
              fullWidth
              size="small"
            />
          </Grid>
          </Grid>

      <Grid item xs={12} sm={4} sx={{px: 4, py: 0.5, lineHeight: 1.5,}}> {t("Type of Fuel")} </Grid>
      <Grid item xs={12} sm={4} sx={{pl: 4, py: 0.5, lineHeight: 1.5,}}>
        <FormControl fullWidth required label={t("Required")}>
          <InputLabel id="selectlabel"> {t("Type of Fuel")} </InputLabel>
          <Select labelId="selectLabel" value={formData.building_fuel_type} onChange={(e) => handleFuel(e)}>
            <MenuItem value={"diesel"}> {t("Heating Oil")} </MenuItem>
            <MenuItem value={"coal"}> {t("Coal")} </MenuItem>
            <MenuItem value={"biomass"}> {t("Biomass")} </MenuItem>
            <MenuItem value={"naturalGas"}> {t("Natural Gas")} </MenuItem>
            <MenuItem value={"nofuel"}> {t("Not using any")} </MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={2} sx={{ px: 4, py: 0.5, lineHeight: 1.5 }}>
        <TextField value={fuelTypeMeasurement} variant="filled" disabled style={{ textAlign: 'center' }}  />
      </Grid>
      
      <Grid item xs={12} sm={4} sx={{px: 4, py: 0.5, lineHeight: 1.5,}}> {t("Fuel per Year")} </Grid>

      <Grid item xs={12} sm={6} sx={{px: 4, py: 0.5, lineHeight: 1.5,}}>
        <TextField 
          defaultValue={formData.building_fuel} 
          required 
          label={t("Required")}
          type="number" 
          variant="outlined" 
          fullWidth 
          onChange={(e) => setFormData({...formData, building_fuel : e.target.value})}
          InputProps={{
            inputProps: { 
                min: 0
            }}}
          />
      </Grid>

      <Grid item xs={12} sm={4} sx={{px: 4, py: 0.5, lineHeight: 1.5,}}>
        {t("Electricity Buildings")}
      </Grid>

      <Grid item xs={12} sm={6} sx={{px: 4, py: 0.5, lineHeight: 1.5,}}>
        <TextField 
          defaultValue={formData.building_elec} 
          type="number" 
          required 
          label={t("Required")}
          variant="outlined" 
          fullWidth onChange={(e) => setFormData({ ...formData, building_elec: e.target.value }) } 
          InputProps={{
            inputProps: { 
                min: 0
            }}}
          />
      </Grid>

      <Grid item xs={6} sx={{px: 4, py: 0.5, lineHeight: 1.5,}}> </Grid>

      <Grid item xs={12} sm={10} sx={{px: 4, py: 0.5, lineHeight: 1.5,}}>
       <FormGroup>
        <FormControlLabel control={<Checkbox checked={formData.solar} onChange={() => setFormData({ ...formData, solar: !formData.solar })} />} label={t("Solar Thermal")}/>
       </FormGroup>
      </Grid>

      <Grid item xs={12} sm={10} sx={{px: 4, py: 0.5, lineHeight: 1.5,}}>
       <FormGroup>
        <FormControlLabel control={<Checkbox checked={formData.pump} onChange={() => setFormData({ ...formData, pump: !formData.pump })} />} label={t("Heat Pump")}/>
       </FormGroup>
      </Grid>

      <Grid item xs={12} sm={10} sx={{px: 4, py: 0.5, lineHeight: 1.5,}}> 
        <FormControl component="fieldset" required label={t("Required")}>
          <FormLabel id="demo-row-radio-buttons-group-label" sx={{ fontWeight: 'bold' }}>{t("Is your refrigerator energy efficient?")}</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={formData.buildingRefrigerator}
            onChange={(event) =>
              setFormData({ ...formData, buildingRefrigerator: event.target.value })
            }
            > 
            <FormControlLabel value="no" control={<Radio />} label= {t("No") + "(F - G)"}  /> 
            <FormControlLabel value="semi" control={<Radio />} label= {t("Semi-efficient") + "(C - E)"} />
            <FormControlLabel value="yes" control={<Radio />} label= {t("Yes") + "(A - B)"} />
            <FormControlLabel value="dontknow" control={<Radio />} label= {t("I don't own one")} />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={10} sx={{px: 4, py: 0.5, lineHeight: 1.5,}}> 
        <FormControl component="fieldset" required label={t("Required")}>
        <FormLabel id="demo-row-radio-buttons-group-label" sx={{ fontWeight: 'bold' }}> {t("Is your washing machine energy efficient?")} </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={formData.buildingWashingMachine}
            onChange={(event) =>
              setFormData({ ...formData, buildingWashingMachine: event.target.value })
            }
            >
            <FormControlLabel value="no" control={<Radio />} label={t("No") + "(F - G)"} />
            <FormControlLabel value="semi" control={<Radio />} label={t("Semi-efficient") + "(C - E)"} />
            <FormControlLabel value="yes" control={<Radio />} label={t("Yes") + "(A - B)"}/>
            <FormControlLabel value="dontknow" control={<Radio />} label={t("I don't own one")} />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={10} sx={{px: 4, py: 0.5, lineHeight: 1.5,}}> 
        <FormControl component="fieldset" required label={t("Required")}>
        <FormLabel id="demo-row-radio-buttons-group-label" sx={{ fontWeight: 'bold' }}>{t("Is your dishwasher energy efficient?")}</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={formData.buildingDishwasher}
            onChange={(event) =>
              setFormData({ ...formData, buildingDishwasher: event.target.value })
            }
            >
            <FormControlLabel value="no" control={<Radio />} label={t("No") + "(F - G)"} />
            <FormControlLabel value="semi" control={<Radio />} label={t("Semi-efficient") + "(C - E)"} />
            <FormControlLabel value="yes" control={<Radio />} label={t("Yes") + "(A - B)"}/>
            <FormControlLabel value="dontknow" control={<Radio />} label={t("I don't own one")} />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={10} sx={{px: 4, py: 0.5, lineHeight: 1.5,}}> 
        <FormControl component="fieldset" required label={t("Required")}>
        <FormLabel id="demo-row-radio-buttons-group-label" sx={{ fontWeight: 'bold' }}>{t("Is your oven energy efficient?")}</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={formData.buildingOven}
            onChange={(event) =>
              setFormData({ ...formData, buildingOven: event.target.value })
            }
            >
            <FormControlLabel value="no" control={<Radio />} label={t("No") + "(C - D)"}/>
            <FormControlLabel value="semi" control={<Radio />} label={t("Semi-efficient") + "(A+ - B)"} />
            <FormControlLabel value="yes" control={<Radio />} label={t("Yes") + "(A+++ - A++)"} />
            <FormControlLabel value="dontknow" control={<Radio />} label={t("I don't own one")} />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <Button variant="text" onClick={(e) => toPrevTab(e)} fullWidth>
          {t("Previous")}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button variant="text" onClick={(e) => toNextTab(e)} fullWidth>
          {t("Next")}
        </Button>
      </Grid>

    </Grid>
    </form>
  </Box>

  <Grid item xs={6} sx={{ border: 1, borderRadius: 1, borderColor: 'lightgray', marginBottom: '16px' }}>
  <Accordion style={{ width: '100%' }}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
      <Typography sx={{ fontWeight: 'bold' }}>{t('Regarding Energy Efficiency Labels')}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Typography>
        {/* Your static text goes here */}
        {t("Match table explanation")}
      </Typography>

        {/* REFRIGERATOR */}
        <TableContainer component={Paper} sx={{ marginTop: '16px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("Refrigerator")}</TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell>{t("Before 2021")}</TableCell>
              <TableCell>{t("After 2021")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>A+++</TableCell>
              <TableCell>A-B</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>A++</TableCell>
              <TableCell>C</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>A+</TableCell>
              <TableCell>D</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>A</TableCell>
              <TableCell>E</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>B</TableCell>
              <TableCell>F</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>C, D, E, F, G</TableCell>
              <TableCell>G</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

        {/* WASHING MACHINE */}
        <TableContainer component={Paper} sx={{ marginTop: '16px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("Washing Machine")}</TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell>{t("Before 2021")}</TableCell>
              <TableCell>{t("After 2021")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>A+++</TableCell>
              <TableCell>A</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>A++</TableCell>
              <TableCell>B</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>A+</TableCell>
              <TableCell>C-D</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>A</TableCell>
              <TableCell>E</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>B</TableCell>
              <TableCell>F</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>C, D</TableCell>
              <TableCell>G</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

        {/* DISHWASHER */}
        <TableContainer component={Paper} sx={{ marginTop: '16px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("Dishwasher")}</TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell>{t("Before 2021")}</TableCell>
              <TableCell>{t("After 2021")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>A+++</TableCell>
              <TableCell>A</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>A++</TableCell>
              <TableCell>B</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>A+</TableCell>
              <TableCell>C-D</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>A</TableCell>
              <TableCell>E</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>B</TableCell>
              <TableCell>F</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>C, D</TableCell>
              <TableCell>G</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>


        {/* OVEN */}
        <TableContainer component={Paper} sx={{ marginTop: '16px' }}>
        <Table>
          <TableHead >
            <TableRow>
              <TableCell>{t("Oven")}</TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell>{t("The energy classes for ovens remain the same")}</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      
      </AccordionDetails>
      
    </Accordion>
    </Grid>
    
</>
  );
};

export default Buildings;