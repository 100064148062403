const deJSON = {
  Blueprints: 'Blaupausen',

  // Scenario Simulation
  "Scenario Simulation": "Scenario Simulation",
  "Emission text":"Das Ziel der Europäischen Kommission ist es, die Treibhausgasemissionen um 55% netto bis 2030 zu reduzieren.",
  "% CO2 reduction" : "% CO2-Reduzierung",
  "end of list text" : "Sie haben das Ende der Liste erreicht!",
  "Save Button Label" : "Speichern",
  "Empty Tabs Text" : "Wählen Sie eine der Registerkarten, um fortzufahren",
  "Citizen Tab Text" : "Maßnahmen, die Sie als Bürger zu ergreifen bereit sind",
  "Government Tab Text" : "Maßnahmen, die Sie bereit sind, von der Regierung zu akzeptieren",
  "Local Government Tab Text" : "Maßnahmen, die Sie bereit sind, von der lokalen Regierung zu akzeptieren",
  "Local Government" : "Lokale Regierung",
  "Current" : "Aktuell",
  "Target" : "Ziel",
  "Example Scenarios Tooltip" : "Hier können Sie zwischen verschiedenen Beispielszenarien wählen. Vom geringsten Aufwand (Szenario 'Minimale Maßnahmen') bis hin zu übermäßigem Aufwand (Szenario 'Maximale Maßnahmen') zur Verringerung Ihres CO2-Fußabdrucks.",
  "Emission Tooltip" : "Wählen Sie die Maßnahmen aus, die Sie ergreifen möchten, sowie die Maßnahmen, die Sie bereit sind, von der Regierung zu akzeptieren, um Ihre Kohlenstoffemissionen zu reduzieren.",
  "Optimistic Scenario" : "Optimistisches Szenario",
  "In an optimistic scenario where citizens change their everyday habits to be more sustainable and environmentally friendly." : "In einem optimistischen Szenario, in dem Bürger:innen ihre alltäglichen Gewohnheiten ändern, um nachhaltiger und umweltfreundlicher zu werden ",
  "Pessimistic Scenario" : "Pessimistisches Szenario",
  "In a pessimistic scenario where citizens do not change their everyday habits, the negative impacts on the environment would continue to exist." : "In einem pessimistischen Szenario, in dem Bürger:innen ihre alltäglichen Gewohnheiten beibehalten, werden sich die negativen Auswirkungen auf die Umwelt fortsetzen.",
  "Realistic Scenario" : "Realistisches Szenario",
  "In a realistic scenario, citizens will try to change their everyday habbits towards achieving sustainability and improving the environment, while also being aware of the limitations and challenges that may exist." : "In einem realistischen Szenario werden die Bürger:innen versuchen, ihre alltäglichen Gewohnheiten zu ändern, um Nachhaltigkeitzielen nachzukommen und die Umwelt zu verbessern. Dabei sind sie sich auch der Grenzen sowie Herausforderungen bewusst, die auf sie zukommen können.",


  // Landing Page
  "Landing Page Title": "Carbon Footprint Simulation Dashboard",
  "Landing Page Subtitle": "Gemeinsam können wir saubere Luft für alle erreichen. Nutzen Sie das Carbon Footprint Simulation Dashboard und werden Sie noch heute aktiv.",
  "Get Started Button": "Starten Sie",
  "Explanation Title": "Was ist das Carbon Footprint Simulation Dashboard",
  "Explanation Paragraph 1": "Das Carbon Footprint Simulation Dashboard besteht aus zwei Tools. Das erste ist der Carbon Footprint Calculator. Dieses Tool soll den Bürgerinnen und Bürgern dabei helfen, zu verstehen, wie sie ihren CO2-Fußabdruck durch ihre täglichen Aktivitäten beeinflussen, und Empfehlungen geben, wie sie ihren CO2-Fußabdruck reduzieren können.",    
  "Explanation Paragraph 2": "Das zweite Instrument ist das Szenario-Simulations-Dashboard. Mit diesem Instrument können sich die Bürger an der Politikgestaltung beteiligen. Es stellt eine Reihe von Maßnahmen vor, die sie ergreifen möchten, sowie Maßnahmen, die sie bereit sind, von der Regierung zu akzeptieren, um die Kohlenstoffemissionen zu reduzieren. Die Bürger können wählen, welche Maßnahmen sie ergreifen möchten, und so ein erreichbares Szenario entwickeln, das sie einreichen können und aus dem dann politische Entscheidungsträger Schlussfolgerungen ziehen können.",
  "Keep track of your carbon footprint by signing up.": "Keep track of your carbon footprint by signing up.",
  "Sign Up Now": "Sign Up Now",
  "Sign In": "Sign In",


  // CO2 Calculator - Tab Names
  "Welcome": "Willkommen",
  "Demographic": "General",
  "Cars": "Autos",
  "Flights": "Flüge",
  "Trains": "Züge",
  "Buildings": "Gebäude",
  "Waste Management": "Abfallwirtschaft",
  "Results": "Ergebnisse",

  // CO2 Calculator - Welcome
  "CO2 Calculator": "CO2 Rechner",
  "Country Question": "Sagen Sie uns bitte zunächst, wo Sie wohnen?",
  "Country Option": "Land",
  "Greece": "Griechenland",
  "greece": "Griechenland",
  "GREECE": "Griechenland",
  "Germany": "Deutschland",
  "germany": "Deutschland",
  "GERMANY": "Deutschland",
  "Belgium": "Belgien",
  "belgium": "Belgien",
  "BELGIUM": "Belgien",
  "Bulgaria": "Bulgarien",
  "bulgaria": "Bulgarien",
  "BULGARIA": "Bulgarien",
  "From": "Von",
  "To": "Zu",
  "Date Explanation": "Die Berechnung des CO2-Fußabdrucks basiert in der Regel auf den jährlichen Emissionen der letzten 12 Monate. Wenn Sie Ihren CO2-Fußabdruck für einen anderen Zeitraum berechnen möchten, verwenden Sie die unten stehenden Kalenderfelder:",
  "Next Steps": "Wählen Sie dann die entsprechende Registerkarte oben, um den Teil Ihres Lebensstils zu berechnen, der Sie am meisten interessiert, z. B. Ihre Flüge. Oder besuchen Sie jede der oben genannten Registerkarten, um Ihren gesamten CO2-Fußabdruck zu berechnen. Im Anschluss an Ihre Berechnung können Sie Ihre Emissionen durch eines unserer klimafreundlichen Projekte ausgleichen/neutralisieren.",

  // CO2 Calculator - Demographic
  "Demographic Characteristics": "General Information",
  "City Question": "In welcher Stadt leben Sie?",
  "City Option": "Stadt",
  "Athens": "Athen",
  "athens": "Athen",
  "Brussels": "Brüssel",
  "brussels": "Brüssel",
  "Berlin": "Berlin",
  "berlin": "Berlin",
  "Plovdiv": "Ploviv",
  "plovdiv": "Ploviv",
  "Sofia": "Sofia",
  "sofia": "Sofia",
  "Other city or village": "Other city or village",
  "Age Question": "Wie alt sind Sie?",
  "Age Option": "Alter",
  // add the age options
  "Below 18": "Unter 18",
  "Above 65": "Über 65",
  "Gender Question": "WWas ist ihr Geschlecht?",
  "Gender Option": "Geschlecht",
  // add the age options
  "Male": "Männlich",
  "Female": "Weiblich",
  "Non-binary": "Divers",
  "Do not wish to specify": "Ich möchte keine Angaben machen",
  "Marital Status Question": "Wie ist Ihr Familienstand?",
  "Marital Status Option": "Familienstand",
  // add the marital status options
  "Social Aid Question": "Erhalten Sie irgendeine Art von Sozialhilfe?",
  "Social Aid Option": "Sozialhilfe",
  "Education Level Question": "Welchen Schulabschluss haben Sie?",
  "Education Level Option": "Bildungsgrad",
  "Primary Education": "Grundschulbildung",
  "Secondary Education": "Sekundarbildung",
  "University Education": "Hochschulabschluss/Universtität",
  "Postgraduate Education": "Postgraduales Studium",
  "Municipal Communities Question": "In welcher Gemeinde leben Sie?",
  "Municipal Communities Option": "Gemeinde",
  "Previous": "Zurück",
  "Next": "Weiter",
  "How many times per week on average do you use the metro?": "Wie oft fahren Sie im Durchschnitt pro Woche mit der U-Bahn?",
  "How many times per week on average do you use the bus?": "Wie oft fahren Sie im Durchschnitt pro Woche mit dem Bus?",
  "How many times per week on average do you use the tram?": "Wie oft fahren Sie im Durchschnitt pro Woche mit der Straßenbahn?",
  "How many times per week on average do you use a bicycle?": "Wie oft fahren Sie im Durchschnitt pro Woche mit dem Fahrrad?", 
  "By answering the questions below you help us understand how factors like age, gender, and education affect the daily activities that contribute to carbon footprint. This data is not meant to and won't be viewed individually.": "By answering the questions below you help us understand how factors like age, gender, and education affect the daily activities that contribute to carbon footprint. This data is not meant to and won't be viewed individually.", 


  // CO2 Calculator - Cars
  "CO2 from Cars": "CO2 von Autos",
  "Vehicles Info Text": "Der Straßenverkehr ist für 1/5 der gesamten CO2-Emissionen in der EU verantwortlich. Wussten Sie, dass 75 % davon auf Personenkraftwagen entfallen?",
  "Distance Question": "Zurückgelegte Strecke (km pro festgelegte Zeitspanne)",
  "Liters Question": "Liter/100 KM",
  "Type of Fuel Question": "Art des Kraftstoffs", // used for Buildings as well
  "Type of Fuel Option": "Art des Kraftstoffs", // used for Buildings as well
  // "Type of Fuel Option": "Fuel Type", // used for Buildings as well
  "Diesel": "Diesel",
  "Petrol": "Ottokraftstoff",
  "LPG": "LPG",
  "CNG": "CNG",
  "Petrol + E10": "Benzin + E10",
  "Public Transport Question": "Öffentliche Verkehrsmittel",
  "Electric Car Question": "Elektroauto",
  "Liters/100km or kWh/100km": "Liter/100km oder kWh/100km",
  "Electricity": "Strom",
  "Add Vehicle": "Kraftfahrzeug hinzufügen",
  "Remove Last Vehicle": "Letztes Kraftfahrzeug entfernen",
  "CO2 from Vehicles": "CO2 aus Kraftfahrzeugen",
  "Vehicle": "Vehicle",
  "Vehicles": "Kraftfahrzeuge",
  "Required": "Erforderlich",


  // CO2 Calculator - Flights
  "CO2 from Flights": "CO2 aus Flügen",
  "Flights Info Text": "Wie viele Inlandsflüge, Kurz-, Mittel- oder Langstreckenflüge haben Sie in dem gewählten Zeitraum unternommen?",
  "Flight Description": "Beschreibung (Flugdauer)",
  "Number of Flights": "Anzahl von Flügen",
  "Avg Flight Distance": "Durchschnittliche Flugentfernung (km)",
  "Domestic": "Inländisch",
  "Short Haul Flight": "Kurzstrecke (<3 Stunden)",
  "Medium Haul Flight": "Mittelstrecke (3-6 Stunden)",
  "Long Haul Flight": "Langstrecke (6-12 Stunden)",
  "Flights Info Text 2": "Anschlussflüge und Rückflüge zählen als separate Flüge",


  // CO2 Calculator - Trains
  "CO2 from Trains": "CO2 von Zügen",
  "Trains Info Text": "Wie oft fahren Sie mit dem Zug?",
  "Number of Trips": "Anzahl der Fahrten",
  "Avg Trip Distance": "Durchschnittliche Entfernung der Fahrt (km)",
  
  
  // CO2 Calculator - Buildings
  "CO2 from Buildings": "CO2 von Gebäuden",
  "Fuel per Year": "Wie hoch ist Ihr Kraftstoffverbrauch im angegebenen Zeitraum?",
  "Fuel per Year Tooltip": "Wie hoch ist Ihr Kraftstoffverbrauch im angegebenen Zeitraum?",
  "Type of Fuel": "Art des Kraftstoffs",
  "Type of Fuel Tooltip": "Welche Art von Heizquelle verwenden Sie in Ihrem Haus?",
  "Heating Oil": "Heizöl",
  "Coal": "Kohle",
  "Biomass": "Biomasse",
  "Natural Gas": "Naturgas",
  "Not using any": "Not using any",
  "Electricity Buildings": "Elektrizität (kWh pro festgelegte Zeitspanne)",
  "Solar Thermal": "Use of solar for water heating",
  "Heat Pump": "Wärmepumpe",    
  "Is your refrigerator energy efficient?": "Ist Ihr Kühlschrank energieeffizient?",
  "Is your washing machine energy efficient?": "Ist Ihre Waschmaschine energieeffizient?",
  "Is your dishwasher energy efficient?": "Ist Ihre Spühlmaschine energieeffizient?",
  "Is your oven energy efficient?": "Ist Ihr Ofen energieeffizient?",
  "No": "Nein",
  "Semi-efficient": "Teilweise effizient",
  "Yes": "Ja",
  "I don't know": "Ich weiß es nicht",
  "I don't own one": "Ich besitze dieses Gerät nicht",
  "Regarding Energy Efficiency Labels": "Regarding Energy Efficiency Labels",
  "Match table explanation": "Am 1. März 2021 haben sich die Energieverbrauchskennzeichnungen für Geräte geändert. Unten finden Sie eine einfache Tabelle, die Ihnen hilft, die Energieklasse Ihres Geräts zu bestimmen, wenn Sie es vor dem 1. März 2021 gekauft haben.",
  "Refrigerator": "KÜHLSCHRANK",
  "Before 2021": "VOR 2021",
  "After 2021": "NACH 2021",
  "Washing Machine": "WASCHMASCHINE",
  "Dishwasher": "SPÜLMASCHINE",
  "Oven": "OFEN",
  "The energy classes for ovens remain the same": "The energy classes for ovens remain the same",

  // CO2 Calculator - Waste Management
  "CO2 from Waste Management": "CO2 aus der Abfallwirtschaft",
  "The average citizen in": "Der Durchschnittsbürger in",
  "recycles": "recycelt",
  "Kgs of waste": "Kilogramm Abfall",
  "and composts": "und kompostiert",
  "Recycle Tooltip": "Wie viel Papier, Glas, Plastik und Blechdosen recyceln Sie?",
  "Compost Tooltip": "Wie viele Küchenabfälle kompostieren Sie?",
  "Choose": "Wählen Sie",
  "Recycling": "Wiederverwertung",
  "No recycling": "Kein Recycling",
  "Less than average recycling": "Weniger als durchschnittliches Recycling",
  "Same as average recycling": "Gleiches Recycling wie der Durchschnitt",
  "More than average recycling": "Mehr als durchschnittliches Recycling",
  "Composting": "Kompostierung",
  "No composting": "Keine Kompostierung",
  "Less than average composting": "Weniger als die durchschnittlich Kompostierung",
  "Same as average composting": "Genauso viel wie der Durchschnitt an Kompostierung",
  "More than average composting": "Mehr als durchschnittliche Kompostierung",
  "Calculate": "Berechnen Sie",
  "That is the equivalent of": "Das entspricht",
  "30lt trash bags": "Müllbeutel 30l",
  "and": "und",

  // CO2 Calculator - Completion Alerts
  "Calculated successfully": "Erfolgreich berechnet!",
  "Check Results": "Auf der Registerkarte Ergebnisse können Sie die vorgenommenen Änderungen sehen.",
  "Uncompleted fields": "Nicht ausgefüllte Felder!", 
  "Fill out fields": "Bitte füllen Sie alle erforderlichen Felder aus, um die Ergebnisse zu berechnen.",

  
  // CO2 Calculator - Results
  "CO2 Calculator Results": "Ergebnisse des CO2-Rechners",
  "Calculator Results": "Berechnungsergebnisse",
  "Save": "Speichern",
  "Total": "Gesamt",
  "User": "Benutzer",
  "Waste": "Abfall",
  "Transportation": "Übersetzung",
  "For more information": "Mehr Informationen",
  "Your results compared to your country's average": "Ihre Ergebnisse im Vergleich zum Durchschnitt Ihres Landes",
  "You": "Sie",
  "Now that you know your carbon footprint, give us your opinion on how to reduce emissions.": "Now that you know your carbon footprint, give us your opinion on how to reduce emissions.",

  // Profile
  "Calculations List Title": "Berechnungen",
  "Your Calculator Results": "Ihre Berechnungsergebnisse",
  "tons CO2 per domain": "Tonnen CO2 pro Bereich",
  "tons CO2": "Tonnen CO2",
  "Kilometers/Year": "Kilometer/Jahr",
  "Liters/100 Km": "Liter/100 Km",
  "Full Name": "Vollständiger Name",
  "e-mail": "E-Mail",
  "Short Haul": "Kurzstrecke ",
  "Medium Haul": "Mittelstrecke",
  "Long Haul": "Langstrecke",
  "Europe": "Europa",
  "Export": "Exportieren",
  "Scenario": "Szenario",
  "Energy Efficient Refrigerator": "Energieeffizienter Kühlschrank",
  "Energy Efficient Washing Machine": "Energieeffiziente Waschmaschine",
  "Energy Efficient Dishwasher": "Energieeffiziente Spühlmaschine",
  "Energy Efficient Oven": "Energieeffizienter Ofen",
  "Car": "Car",
  "More Info": "Mehr Informationen",

  
  // Admin Page - 
  "Percentage": "Percentage",
  "Recommendations": "Empfehlungen",
  "Scenarios": "Szenarien",
  "Actions": "Aktionen",
  "Statistics": "Statistik",

  // Admin Page - Scenarios
  "Scenario Explanation": "Auf dieser Seite können Sie Ihre eigenen Szenarien erstellen, indem Sie Aktionen aus der nebenstehenden Spalte auswählen. Sie können auch die bestehenden Szenarien aktualisieren.",
  "Select slider action": "Slider-Aktion auswählen",    
  "Please select the appropriate levels of the actions for your scenario.": "Bitte wählen Sie die entsprechenden Stufen der Aktionen für Ihr Szenario.",
  "Add Scenario": "Szenario hinzufügen",
  "Cancel": "Abbrechen",
  "Update": "Aktualisieren",
  "Delete": "Löschen",
  "Please enter a title and a description for your scenario.": "Bitte geben Sie einen Titel und eine Beschreibung für Ihr Szenario ein.",
  "Citizen": "Bürgerinnen und Bürger",
  "Government": "Regierung",
  "Scenario Title": "Szenario-Titel",
  "Scenerio Description": "Szenario-Beschreibung",


  // Admin Page - Actions
  "Actions Title Text" : "Aktionen",
  "Actions Title Text secondary" : "Aktion auswählen",
  "Action Label" : "Aktion Etikett",
  "Action Contribution Admin Page Text": "0 <= input <= 1",
  "Action Title 1 Admin Page Text" : "",
  "Action Title 2 Admin Page Text" : "",
  "Action Title 3 Admin Page Text" : "",
  "Action Percentage 1 Admin Page Text" : "",
  "Action Percentage 2 Admin Page Text" : "",
  "Action Percentage 3 Admin Page Text" : "",
  "Empty Fields Error Text" : "Felder können nicht leer sein",
  "Clear" : "Zurücksetzen",
  "Category" : "Kategorie",
  "Contribution" : "Beitrag",
  "Title" : "Titel",
  "Add action" : "Aktion hinzufügen",
  "No Action" : "Keine Aktion",
  "First Action" : "Erste Action",
  "Second Action" : "Zweite Action",
  "Third Action" : "Dritte Action",
  "Please choose the percentage of the implementation of your action" : "Bitte wählen Sie den Prozentsatz der Umsetzung Ihrer Aktion",
  "You have to enter a number between 0 and 1 that represents a percentage" : "Sie müssen eine Zahl zwischen 0 und 1 eingeben, die einen Prozentsatz darstellt.",
  "Please choose the percentage of the implementation of the first stage of your action": "Bitte wählen Sie den Prozentsatz der Umsetzung Ihrer Aktion",
  "Please choose the percentage of the implementation of the second stage of your action": "Bitte geben Sie den Prozentsatz zur Umsetzung der zweiten Stufe Ihrer Maßnahme an",
  "Please choose the percentage of the implementation of the third stage of your action": "Bitte geben Sie den Prozentsatz zur Umsetzung der dritten Stufe Ihrer Maßnahme an",
  "Please add the title of the new action.": "Bitte geben Sie einen Titel für die neue Maßnahme ein",

  // Admin Page - Recommendations
  "Add Link" : "Link hinzufügen",
  "Remove Last Link" : "Letzten Link entfernen",
  "Link" : "Link",
  "Please enter the text for your new recommendation." : "Bitte geben Sie den Text für Ihre neue Empfehlung ein",
  "Recommendation Text" : "Empfehlungstext",
  "Add Case" : "Fall hinzufügen",
  "Add Recommendation" : "Empfehlung hinzufügen",
  "Operator" : "Operator",
  "Value" : "Wert",
  "Country" : "Land",


    // Admin Page - Statistics
    "Stage" : "Stufe",
    "Stage 0" : "Stufe 0",
    "Stage 1" : "Stufe 1",
    "Stage 2" : "Stufe 2",
    "Stage 3" : "Stufe 3",
    "Select Country" : "Land auswählen",
    "Select City" : "Stadt auswählen",
    "Select Municipal Region" : "Bezirk auswählen",
    "Select Age" : "Alter",
    "Select Gender" : "Geschlecht",
    "Select Social Aid" : "Soziale Hilfe",
    "Select Education Level" : "Bildungsgrad auswählen",
    "Scenario Simulation Statistics" : "Statistik der Szenario-Simulation",
    "Carbon Footprint Calculator Statistics" : "Statistik des CO2-Fußabdruck-Rechners",
    "Average" : "Durchschnitt",


  // Recommendations
  "Consider replacing old fluorescent lighting with LEDs" : "Erwägen Sie, alte Leuchtstofflampen durch LEDs zu ersetzen.",
  "Consider switching to Energy Efficient appliances" : "Erwägen Sie den Umstieg auf energieeffiziente Gerätes",
  "Consider using smart power strips" : "Erwägen Sie den Einsatz intelligenter Steckdosenleisten",
  "Consider turning down the heating by 1 degree Celsius" : "Erwägen Sie, die Heizung um 1 Grad Celcius herunterzudrehen.",
  "Consider unpluging electronical equipment when fully charged" : "Ziehen Sie den Stecker elektronischer Geräte aus der Steckdose, wenn sie voll aufgeladen sind.",
  "Consider insulating your home" : "Erwägen Sie, Ihr Haus zu isolieren",
  "Consider increasing cycling by 30% (micromobility)" : "Erwägen Sie eine Steigerung des Radverkehrs um 30 % (Mikromobilität)",
  "Consider using the train for medium length distances (insted of the car)" : "Überlegen Sie, ob Sie für mittellange Strecken den Zug benutzen wollen (statt des Autos)",
  "Consider carpooling to work" : "Bildung von Fahrgemeinschaften für den Arbeitsweg erwägen",
  "Consider using public transport in your daily routine" : "Erwägen Sie die Nutzung öffentlicher Verkehrsmittel in Ihrem Tagesablauf",
  "Consider replacing all business and first class trips by economy class trips" : "Erwägen Sie, alle Business- und First-Class-Reisen durch Economy-Reisen zu ersetzen.",
  "Consider replacing indirect flights with direct flights" : "Ersetzen Sie indirekte Flüge durch Direktflüge",
  "Consider reducing air travel by using trains or ferries" : "Erwägen Sie, Flugreisen durch die Nutzung von Zügen oder Fähren zu reduzieren.",
  "Consider using a reusable bad to the market" : "Erwägen Sie, einen wiederverwendbaren Einkaufswagen zu benutzen",
  "Consider shopping at a bulk foods store" : "Erwägen Sie den Einkauf in einem Großmarkt",
  "Good job!" : "Gute Arbeit!",
  "Consider reducing the use of single plastics!" : "Empfehlung: Einwegplastik weniger nutzen",
  "Consider using public transport" : "Erwägen Sie, die Nutzung öffentlicher Verkehrsmittel",
  "Consider car sharing" : "Carsharing in Betracht ziehen",
  "Consider replacing your washing machine with an energy efficient one" : "Empfehlung: Ersetzen Sie Ihre jetzige Waschmaschine durch eine energieeffiziente Waschmaschine",
  "Consider replacing your oven with an energy efficient one" : "Empfehlung: Ersetzen Sie Ihren jetzigen Kühlschrank durch einen energieeffizienten Kühlschrank ",
  "Consider replacing your refrigerator with an energy efficient one" : "Empfehlung: Ersetzen Sie Ihren jetzige Ofen durch einen energieeffizienten Ofen",
  "Consider replacing your dishwasher with an energy efficient one" : "Empfehlung: Ersetzen Sie Ihre jetzige Spühlmaschine durch eine energieeffiziente Spühlmaschine",
  "Consider reducing the use of single plastics":"Empfehlung: Einwegplastik weniger nutzen",
  "Consider using a reusable bag to the market":"Empfehlung: Nutzen Sie einen Stoffbeutel beim Einkaufen",
  "Consider purchasing preused items and/or materials":"Empfehlung: Kaufen Sie gebrauchte Gegenstände und/oder Stoffe",
  "Consider circular products instead of single use":"Empfehlung: Nutzen Sie Kreislaufprodukte statt Einwegprodukten",
  "Consider products made of regenerated material sources": "Empfehlung: Nutzen Sie Produkte aus nachwachsenden Rohstoffen",
  "Consider less use of air-condition": "Empfehlung: Klimaanlagen weniger nutzen",
  "Consider using your own thermo for coffee/tea/water takeaway": "Empfehlung: Nutzen Sie Ihre eigene Thermoskanne für Kaffee/Tee/Wasser wenn Sie unterwegs sind",
  "Consider using the composting bins of the city or your own": "Empfehlung: Nutzen Sie die kommunalen oder Ihrer eigenen Komposttonnen",
  "Consider performing maintenance on your heating system": "Empfehlung: Warten Sie Ihre Heizungsanlage",
  "Consider upgrading your heating system": "Empfehlung: Rüsten Sie Ihre Heizungsanlage auf",
  "Consider using a more efficient vehicle": "Empfehlung: Nutzen Sie ein effizienteres Fahrzeug",
  "Consider donating surplus production out of your garden or field": "Empfehlung: Spenden Sie die überzähligen Erzeugnisse aus Ihrem Garten oder Feld ",
  "Consider sharing formulas instead of purchasing (shared vehicles, machines...)": "Empfehlung: Nutzen Sie Sharing-Angebote anstatt Produkte zu kaufen (z.B. Car-Sharing, Maschinen, usw.)",

// Actions - Citizen
"Improve Transportation Habits" : "Verbesserung der Verkehrsgewohnheiten",
"20% increased Carpoolling" : "20% Vermehrt Fahrgemeinschaften",
"50% increased Carpoolling" : "50% Vermehrt Fahrgemeinschaften",
"70% increased Carpoolling" : "70% Vermehrt Fahrgemeinschaften",

"Change Flying Habits" : "Fluggewohnheiten ändern",
"20% Replace business class with economy" : "20% Ersetzen der Business Class durch Economy Class",
"50% Replace business class with economy" : "50% Ersetzen der Business Class durch Economy Class",
"90% Replace business class with economy" : "90% Ersetzen der Business Class durch Economy Class",

"Improve Flying Habits" : "Verbesserung der Fluggewohnheiten",
"20% Replace indirect flights with direct flights" : "20% Indirekte Flüge durch Direktflüge ersetzen",
"50% Replace indirect flights with direct flights" : "50% Indirekte Flüge durch Direktflüge ersetzen",
"90% Replace indirect flights with direct flights" : "90% Indirekte Flüge durch Direktflüge ersetzen",

"Change Travelling Habits" : "Änderung der Reisegewohnheiten",
"20% Train use for medium length distances" : "20% Zugbenutzung für mittellange Strecken",
"50% Train use for medium length distances" : "50% TZugbenutzung für mittellange Strecken",
"90% Train use for medium length distances" : "90% Zugbenutzung für mittellange Strecken",

"Lighting Habits" : "Gewohnheiten der Lichtnutzung",
"20% Replace old fluorescent lighting with LEDs" : "20% Ersetzen Sie alte Leuchtstoffröhren durch LEDs",
"50% Replace old fluorescent lighting with LEDs" : "50% Ersetzen Sie alte Leuchtstoffröhren durch LEDs",
"100% Replace old fluorescent lighting with LEDs" : "100% Ersetzen Sie alte Leuchtstoffröhren durch LEDs",

"Appliances Habits" : "Gewohnheiten im Haushalt",
"20% Energy Efficient appliances" : "20% Energieeffiziente Geräte",
"50% Energy Efficient appliances" : "50% Energieeffiziente Geräte",
"100% Energy Efficient appliances" : "100% Energieeffiziente Geräte",

"Insulation Habits" : "Möglichkeiten der Wärmeisolierung",
"20% Insulation Thickness" : "20% Insulation Thickness",
"50% Insulation Thickness" : "50% Insulation Thickness",
"100% Insulation Thickness" : "100% Insulation Thickness",

"Improve Recycling Habits" : "Verbesserte Recycling-Gewohnheiten", // needs fix
"20% Increased recycling of plastics + paper + metal cans + glass" : "20% Verstärktes Recycling von Kunststoffen, Papier, Metalldosen und Glas",
"40% Increased recycling of plastics + paper + metal cans + glass" : "40% Verstärktes Recycling von Kunststoffen, Papier, Metalldosen und Glas",
"70% Increased recycling of plastics + paper + metal cans + glass" : "70% Verstärktes Recycling von Kunststoffen, Papier, Metalldosen und Glas",

"Introduce composting in everyday life": "Einführung der Kompostierung im Alltag",
"20% compost of organic waste by placing a bin": "20% Kompostierung von Bioabfällen durch Anbringung einer gesonderten Tonne",
"40% compost of organic waste by placing a bin": "40% Kompostierung von Bioabfällen durch Anbringung einer gesonderten Tonne",
"70% compost of organic waste by placing a bin": "70% Kompostierung von Bioabfällen durch Anbringung einer gesonderten Tonne",

"Unplug devices that are not used or when on vacation": "Stecken Sie Geräte aus, die nicht benutzt werden oder wenn Sie im Urlaub sind",
"20% Unplug devices that are not operating": "20% Stecken Sie Geräte aus, die nicht in Betrieb sind",
"40% Unplug devices that are not operating": "40% Stecken Sie Geräte aus, die nicht in Betrieb sind",
"70% Unplug devices that are not operating": "70% Stecken Sie Geräte aus, die nicht in Betrieb sind",

"Improve everyday mobility": "Bewegen Sie sich mehr im Alltag",
"20% Increase everyday's cycling": "20% Fahren Sie mehr Fahrrad im Alltag",
"40% Increase everyday's cycling": "40% Fahren Sie mehr Fahrrad im Alltag",
"70% Increase everyday's cycling": "70% Fahren Sie mehr Fahrrad im Alltagg",

// Actions - Citizen
"Capture and storage of CO2 from fossil fuel combustion" : "Abscheidung und Speicherung von CO2 aus der Verbrennung fossiler Brennstoffe",
"40% Capture and storage of CO2" : "40% Abscheidung und Speicherung von CO2",
"60% Capture and storage of CO2" : "60% Abscheidung und Speicherung von CO2",
"100% Capture and storage of CO2" : "100% Abscheidung und Speicherung von CO2",

"Photovoltaic parks at petrol stations" : "Fotovoltaikanlagen an Tankstellen",
"30% Installed photovoltaic parks at petrol stations" : "30% Installierte Fotovoltaikanlagen an Tankstellen",
"60% Installed photovoltaic parks at petrol stations" : "60% Installierte Fotovoltaikanlagen an Tankstellen",
"100% Installed photovoltaic parks at petrol stations" : "100% IInstallierte Fotovoltaikanlagen an Tankstellen",

"Wind Energy" : "Windenergie",
"30% penetration from wind energy" : "30% Marktdurchdringung durch Windenergie",
"60% penetration from wind energy" : "60% Marktdurchdringung durch Windenergie",
"90% penetration from wind energy" : "90% Marktdurchdringung durch Windenergie",

"Replacing cars with electric ones": "Ersetzen von PKWs durch Elektrofahrzeuge",
"40% Replacing cars with electric ones": "40% Ersetzen von PKWs durch Elektrofahrzeuge",
"60% Replacing cars with electric ones": "60% Ersetzen von PKWs durch Elektrofahrzeuge",
"100% Replacing cars with electric ones": "100% Ersetzen von PKWs durch Elektrofahrzeuge",

"Establish a speed limit of 30 km/h in cities": "Einführung eines Tempolimits von 30 km/h in Städten",
"20% Establish a speed limit of 30 km/h in cities": "20% Einführung eines Tempolimits von 30 km/h in Städten",
"60% Establish a speed limit of 30 km/h in cities": "60% Einführung eines Tempolimits von 30 km/h in Städten",
"100% Establish a speed limit of 30 km/h in cities": "100% Einführung eines Tempolimits von 30 km/h in Städten",

"Ιncreasing bike lanes in the city": "Ausbau von Fahrradwegen in der Stadt",
"20% Ιncreasing bike lanes in the city": "20% Ausbau von Fahrradwegen in der Stadt",
"40% Ιncreasing bike lanes in the city": "40% Ausbau von Fahrradwegen in der Stadt",
"100% Ιncreasing bike lanes in the city": "100% Ausbau von Fahrradwegen in der Stadt",

"Create green ports": "Grüne Häfen bauen",
"20% of cities ports become Green ports": "20% der städtischen Häfen zu grünen Häfen umbauen",
"40% of cities ports become Green ports": "40% der städtischen Häfen zu grünen Häfen umbauen",
"100% of cities ports become Green ports": "100% der städtischen Häfen zu grünen Häfen umbauen",

"Financial Assistance Program for Home Insulation Applications": "Finanzhilfeprogramm bei Anträgen auf Gebäudedämmung",
"20% Improve Home Insulation": "20% Gebäudedämmung verbessern",
"50% Improve Home Insulation": "50% Gebäudedämmung verbessern",
"100% Improve Home Insulation": "100% Gebäudedämmung verbessern",

"Financial Support Program for Replacing Domestic Heating Appliances": "Finanzhilfeprogramm zum Austausch von Heizungsanlagen in Haushalten",
"20% Replacing fossil fuel boilers with heat pumps": "20% Ersetzen von Heizkesseln mit fossilen Brennstoffen durch Wärmepumpen",
"40% Replacing fossil fuel boilers with heat pumps": "40% Ersetzen von Heizkesseln mit fossilen Brennstoffen durch Wärmepumpen",
"100% Replacing fossil fuel boilers with heat pumps": "100% Ersetzen von Heizkesseln mit fossilen Brennstoffen durch Wärmepumpen",

// Actions - Local Government
"Improvement in rail service quality (frequency,speed)": "Verbesserung der Qualität der Eisenbahndienste (Frequenz, Geschwindigkeit)",
"20% Increase in rail usage": "20% Erhöhung der Bahnnutzung",
"40% Increase in rail usage": "40% Erhöhung der Bahnnutzung",
"90% Increase in rail usage": "90% Erhöhung der Bahnnutzunge",

"Improving the energy efficiency of existing buildings (goverment support programme)": "Verbesserung der Energieeffizienz bestehender Gebäude (staatliches Förderprogramm)",
"20% Improving the energy efficiency of existing buildings": "20% Verbesserung der Energieeffizienz von bestehenden Gebäuden",
"40% Improving the energy efficiency of existing buildings": "40% Verbesserung der Energieeffizienz von bestehenden Gebäuden",
"90% Improving the energy efficiency of existing buildings": "90% Verbesserung der Energieeffizienz von bestehenden Gebäuden",

"Increase Compostable stations": "Mehr kompostierbare Stationen bauen",
"20% Increase Compostable stations": "20% Mehr kompostierbare Stationen bauen",
"40% Increase Compostable stations": "40% Mehr kompostierbare Stationen bauen",
"90% Increase Compostable stations": "90% Mehr kompostierbare Stationen bauen",

"Bike-sharing programs at convenient locations": "Bike-Sharing-Programme an geeigneten Standorten",
"20% Increase bike usage": "20% Erhöhung der Fahrradnutzung",
"40% Increase bike usage": "40% Erhöhung der Fahrradnutzung",
"70% Increase bike usage": "70% Erhöhung der Fahrradnutzung",

"Start creating bicycle lanes": "Einrichtung von Fahrradspuren",
"20% expand bicycle lanes": "20% Radwege ausbauen",
"40% expand bicycle lanes": "40% Radwege ausbauen",
"90% expand bicycle lanes": "90% Radwege ausbauen",

};

export default deJSON;