import React from 'react';

import {Button, Grid, FormControl, Select, MenuItem, Box, InputLabel} from "@mui/material"
// import TextField from '@mui/material/TextField';
// import { DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

const Welcome = ({
  toNextTab,
  handleCountry,
  handleStartDate,
  handleEndDate,
  formData,
  setFormData,
  locked
}) => {

  const { t } = useTranslation();


  const selectCountry = (c) => {
    handleCountry(c);
    setFormData({ ...formData, country: c });
  };

  const selectStartDate = (s) => {
    handleStartDate(s);
    setFormData({ ...formData, startDate: s });
  };

  const selectEndDate = (e) => {
    handleEndDate(e);
    setFormData({ ...formData, endDate: e });
  };

  // const selectCountry = (c) => {
  //   handleCountry(c)
  //   setFormData({...formData, country: c});
  //   console.log(formData)
  //  }


  const { trackPageView } = useMatomo()
  // const { trackPageView, setDocumentTitle } = useMatomo();

  // Track page view
  // React.useEffect(() => {
  //   trackPageView()
  // }, [])

  useEffect(() => {
    // Set the document title
    // setDocumentTitle('CO2 Calculator');
    document.title = "CO2 Calculator - Carbon Footprint Simulation Dashboard - CompAir";
    _paq.push(['setDocumentTitle', "CO2 Calculator"]);
    // Track the page view
    trackPageView();
  }, []);




  return (
    <>
      <Helmet>
        <title>CO2 Calculator - Carbon Footprint Simulation Dashboard - CompAir</title>
      </Helmet>
  
      <Box sx={{ border: 1, borderRadius: 1, borderColor: "lightgray" }} backgroundColor="white">
        <Grid container justifyContent="center">
          <Grid item xs={12} sx={{ textAlign: 'center', px: 2, py: 1, lineHeight: 1.5 }}>
            <h5>{t("CO2 Calculator")}</h5>
          </Grid>
  
            
          <Grid item xs={12} sx={{ textAlign: 'center', px: 2, py: 1, lineHeight: 1.5 }}>
            {t("Country Question")}
          </Grid>

          <Grid item xs={12} md={6} sx={{ px: 2, py: 1, lineHeight: 1.5 }}>

          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={6} sx={{ px: 2, py: 1, lineHeight: 1.5 }}>

              <FormControl fullWidth>
                <InputLabel id="selectlabel">{t("Country Option")}</InputLabel>
                <Select
                  labelId="selectLabel"
                  defaultValue={''}
                  value={formData.country}
                  onChange={(e) => { selectCountry(e.target.value); }}
                  style={{ textAlign: 'center' }} 
                >
                  <MenuItem value={"greece"}>{t("Greece")}</MenuItem>
                  <MenuItem value={"germany"}>{t("Germany")}</MenuItem>
                  <MenuItem value={"bulgaria"}>{t("Bulgaria")}</MenuItem>
                  <MenuItem value={"belgium"}>{t("Belgium")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            </Grid>

          </Grid>

          <Grid item xs={12} sx={{ textAlign: 'center', px: 2, py: 1, lineHeight: 1.5 }}>
            {t("Date Explanation")}
          </Grid>
  

          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={12} md={4} sx={{ px: 2, py: 1, lineHeight: 1.5 }}>
              <label className="form-label">{t("From")}</label>
              <input
                type="date"
                className="form-control"
                value={formData.startDate}
                onChange={(e) => { selectStartDate(e.target.value); }}
                style={{ textAlign: 'center' }}
              />
            </Grid>

            <Grid item xs={12} md={4} sx={{ px: 2, py: 1, lineHeight: 1.5 }}>
              <label className="form-label" style={{ marginRight: '8px' }}>{t("To")}</label>
              <input
                type="date"
                className="form-control"
                value={formData.endDate}
                onChange={(e) => { selectEndDate(e.target.value); }}
                style={{ textAlign: 'center' }}
              />
            </Grid>
          </Grid>
  
          <Grid item xs={12} sx={{ textAlign: 'center', px: 2, py: 1, lineHeight: 1.5 }}>
            {t("Next Steps")}
          </Grid>
        </Grid>
  
        <Grid container justifyContent="center">
          <Grid item xs={12} sx={{ textAlign: 'center', px: 2, py: 1, lineHeight: 2.5 }}>
            <Button disabled={locked} onClick={(e) => toNextTab(e)} fullWidth>
              {t("Next")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Welcome;
