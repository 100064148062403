import React from 'react';
import User from './User';
import Country from './Country';
import 'chart.js/auto';
// import { PolarArea } from 'react-chartjs-2';
// import { Doughnut, Bar, PolarArea, Radar } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
// import Modal from './Modal';
import Europe from './Europe';
import Dashboard from './Dashboard';
import Recommendations from './Recommendations';
import {Box, Grid}  from "@mui/material"
import { useTranslation } from 'react-i18next';

const Results = ({
  country,
  carC,
  trainC,
  buildingC,
  wasteC,
  car,
  flight,
  train,
  building,
  waste,
  formData,
  carE,
  trainE,
  buildingE,
  wasteE,
  flightC,
  flightE
}) => {
  
  const { t } = useTranslation();
  
  const optionsCars = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: t("Vehicles")
      }
    },
    scales: {
      x: {
        barPercentage: 0.3, // Adjust the width of the bars (0.5 means 50% of the available space)
      },
    },
  };

  /* Bar chart */
  const dataCars = {
    labels: [t("tons CO2")],
    datasets: [
      {
        label: 'You',
        data: [car],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
      {
        label: t(country),
        data: [carC],
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
      {
        label: 'Europe',
        data: [carE],
        backgroundColor: 'rgba(75, 192, 192, 0.2)', 
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      }
    ],
  };

  const optionsFlights = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: t("Flights")
      }
    },
    scales: {
      x: {
        barPercentage: 0.3, // Adjust the width of the bars (0.5 means 50% of the available space)
      },
    },
  };

  const dataFlights = {
    labels: [t("tons CO2")],
    datasets: [
      {
        label: 'You',
        data: [flight],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
      {
        label: t(country),
        data: [flightC],
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
      {
        label: 'Europe',
        data: [flightE],
        backgroundColor: 'rgba(75, 192, 192, 0.2)', 
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      }
    ],
  };

  const optionsTrains = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: t("Trains")
      }
    },
    scales: {
      x: {
        barPercentage: 0.3, // Adjust the width of the bars (0.5 means 50% of the available space)
      },
    },
  };

  const dataTrains = {
    labels: [t("tons CO2")],
    datasets: [
      {
        label: 'You',
        data: [train],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
      {
        label: t(country),
        data: [trainC],
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
      {
        label: 'Europe',
        data: [trainE],
        backgroundColor: 'rgba(75, 192, 192, 0.2)', 
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      }
    ],
  };

  const optionsBuildings = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: t("Buildings")
      }
    },
    scales: {
      x: {
        barPercentage: 0.3, // Adjust the width of the bars (0.5 means 50% of the available space)
      },
    },
  };

  const dataBuildings = {
    labels: [t("tons CO2")],
    datasets: [
      {
        label: 'You',
        data: [building],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
      {
        label: t(country),
        data: [buildingC],
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
      {
        label: 'Europe',
        data: [buildingE],
        backgroundColor: 'rgba(75, 192, 192, 0.2)', 
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      }
    ],
  };

  const optionsWaste = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: t("Waste")
      }
    },
    scales: {
      x: {
        barPercentage: 0.3, // Adjust the width of the bars (0.5 means 50% of the available space)
      },
    },
  };

  const dataWaste = {
    labels: [t("tons CO2")],
    datasets: [
      {
        label: 'You',
        data: [waste],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
      {
        label: t(country),
        data: [wasteC],
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
      {
        label: 'Europe',
        data: [wasteE],
        backgroundColor: 'rgba(75, 192, 192, 0.2)', 
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      }
    ],
  };
  
  return (
    <>
    <Box sx={{border:1, borderRadius:1, borderColor:"lightgray"}} backgroundColor="white" >
    <div className="card">
        <div className="card-body">
          <h5 className="card-title text-center">{t("CO2 Calculator Results")}</h5>
          <div className="row justify-content-center py-2">
            <div className="col-lg-7 py-2">
              <User
                car={car}
                flight={flight}
                train={train}
                building={building}
                waste={waste}
              />
            </div>
          </div>
          <div className="row justify-content-center py-2">
            <div className="col-lg-7 py-2">
              <Country
                carC={carC}
                trainC={trainC}
                buildingC={buildingC}
                wasteC={wasteC}
                country={country}
                flightC={flightC}
              />
            </div>
          </div>
          <div className="row justify-content-center py-2">
            <div className="col-lg-7 py-2">
              <Europe
                carE={carE}
                trainE={trainE}
                buildingE={buildingE}
                wasteE={wasteE}
                flightE={flightE}
              />
            </div>
          </div>

          <div className="row justify-content-center py-2">
            <div className="col-lg-7 py-2">
              <Dashboard
                country={country}
              />
            </div>
          </div>
          
          <div className="row justify-content-center align-items-center mt-3">
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} >
                <div className="my-3">
                  <Bar options={optionsCars} data={dataCars}  />
                </div>
              </Grid>
              <Grid item xs={12} md={4} >
                <div className="my-3">
                  <Bar options={optionsFlights} data={dataFlights}  />
                </div>
              </Grid>
              <Grid item xs={12} md={4} >
                <div className="my-3">
                  <Bar options={optionsTrains} data={dataTrains}  />
                </div>
              </Grid>
              <Grid item xs={12} md={4} >
                <div className="my-3">
                  <Bar options={optionsBuildings} data={dataBuildings}  />
                </div>
              </Grid>
              <Grid item xs={12} md={4} >
                <div className="my-3">
                  <Bar options={optionsWaste} data={dataWaste}  />
                </div>
              </Grid>
            </Grid>
          </div>

        </div>
      </div>
      {console.log(formData)}
      <Recommendations
        countryCar={carC}
        countryTrain={trainC}
        countryBuilding={buildingC}
        countryWaste={wasteC}
        countryFlight={10}
        calculatedCar={car}
        calculatedFlight={flight}
        calculatedTrain={train}
        calculatedBuilding={building}
        calculatedWaste={waste}
        formData={formData}
      />
    </Box>

    </>
  );
};

export default Results;
