import React from 'react';
import {Button} from "@mui/material"
import {Grid, TextField, Box}  from "@mui/material"
import { useTranslation } from 'react-i18next';


const Trains = ({
  toNextTab,
  toPrevTab,
  // start,
  // end,
  handleTrain,
  formData,
  setFormData
}) => {
  const trainsForm = (e) => {
    e.preventDefault();
    handleTrain();
  };

  const { t } = useTranslation();

  return (
    <Box sx={{ border: 1, borderRadius: 1, borderColor: "lightgray", p: 2 }} backgroundColor="white">
      <form onSubmit={(e) => { trainsForm(e) }}>
        <Grid container justifyContent="center" alignContent="center">
          <Grid item xs={12} sx={{ textAlign: 'center', mb: 2 }}>
            <h5>{t("CO2 from Trains")}</h5>
          </Grid>
  
          <Grid item xs={12} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            <p>{t("Trains Info Text")}</p>
          </Grid>
  

          <Grid container>
            {/* eslint-disable-next-line */}
            <Grid item xs={12} sm={2} sx={{ px: 2, py: 1, textAlign: 'center' }}></Grid>
            <Grid item xs={6} sm={4} sx={{ px: 2, py: 1, textAlign: 'center' }}>
              {t("From")}
            </Grid>
            <Grid item xs={6} sm={4} sx={{ px: 2, py: 1, textAlign: 'center' }}>
              {t("To")}
            </Grid>
            {/* eslint-disable-next-line */}
            <Grid item xs={12} sm={2} sx={{ px: 2, py: 1, textAlign: 'center' }}></Grid>
          </Grid>
          
          <Grid container>
          {/* eslint-disable-next-line */}
          <Grid item xs={12} sm={2} sx={{ px: 2, py: 1, textAlign: 'center' }}></Grid>
          <Grid item xs={6} sm={4} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            <TextField
              key={formData.startDate}
              defaultValue={formData.startDate}
              variant="outlined"
              InputProps={{
                readOnly: true,
                inputProps: { style: { textAlign: 'center' } }
              }}
              fullWidth
              size="small"
            />
          </Grid>
  
          <Grid item xs={6} sm={4} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            <TextField
              key={formData.endDate}
              defaultValue={formData.endDate}
              variant="outlined"
              InputProps={{
                readOnly: true,
                inputProps: { style: { textAlign: 'center' } }
              }}
              fullWidth
              size="small"
            />
          </Grid>

          {/* eslint-disable-next-line */}
          <Grid item xs={12} sm={2} sx={{ px: 2, py: 1, textAlign: 'center' }}></Grid>
          </Grid>

          <Grid item xs={6} md={4} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            <TextField
              required
              label={t("Number of Trips")}
              defaultValue={formData.trips}
              type="number"
              InputProps={{
                inputProps: {
                  min: 0
                }
              }}
              variant="outlined"
              fullWidth
              onChange={(e) => setFormData({ ...formData, trips: e.target.value })}
            />
          </Grid>
  
          <Grid item xs={6} md={4} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            <TextField
              required
              label={t("Avg Trip Distance")}
              defaultValue={formData.train_distance}
              type="number"
              InputProps={{
                inputProps: {
                  min: 0
                }
              }}
              variant="outlined"
              fullWidth
              onChange={(e) => setFormData({ ...formData, train_distance: e.target.value })}
            />
          </Grid>
  
          <Grid item xs={6}>
        <Button variant="text" onClick={(e) => toPrevTab(e)} fullWidth>
          {t("Previous")}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button variant="text" onClick={(e) => toNextTab(e)} fullWidth>
          {t("Next")}
        </Button>
      </Grid>

        </Grid>
      </form>
    </Box>
  );
};

export default Trains;
