import { useState } from 'react';

import {
  Box,
  Card,
  Typography,
  List,
  Divider,
  ToggleButtonGroup,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Scrollbar from 'src/components/Scrollbar';
import Range from "./Range"
// import SliderAdder from './SliderAdder';
import { Fragment } from 'react';
import { styled } from "@mui/material/styles";
import MuiToggleButton from "@mui/material/ToggleButton";


function SliderListAdmin({ sliders, sliderValueHandler }) {

  const ToggleButton = styled(MuiToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: 'primary'
    }
  });

  const { t } = useTranslation();
  const theme = useTheme();

  const [tabs, setTab] = useState('activity');

  const handleViewOrientation = (_event, newValue) => {
    setTab(newValue);
  };

  const citizenSliders = []
  const governmentSliders = []

  sliders.forEach(slider => {
    if (slider.category === "Citizen") {
      citizenSliders.push(slider)
    } else {
      governmentSliders.push(slider)
    }
  });
  const checkTitleLanguage = (slider) => {
    return t(slider["titles"]["en"][slider.value])
  }
  const checkNameLanguage = (slider) => {
      return t(slider["name"]["en"] )
  }

  const loadSliders = () => {

    let sliderList = []

    let counter = 0
    citizenSliders.forEach(function (slider) {
      counter += 1
      let cslider = sliders.get(slider._id)

      sliderList.push(

        <Fragment key={counter}>

          <Range
            id={cslider._id}
            name={checkNameLanguage(cslider)}
            title={checkTitleLanguage(cslider)}
            value={cslider.value}
            sliderValueHandler={sliderValueHandler}
            />
          <Divider />

        </Fragment>


      )
    })

    return sliderList
  }

  return (
    <Card>
      <Box
        p={2.2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography gutterBottom variant="h4">
            {t('Actions')}
          </Typography>       
          <Typography variant="subtitle2">
            {t('Select slider action')}
          </Typography>
        </Box>
        <ToggleButtonGroup
          size="small"
          value={tabs}
          exclusive
          onChange={handleViewOrientation}

        >
          <ToggleButton
            sx={{
              px: 4,
              py: 0.5,
              lineHeight: 1.5,
              fontSize: `${theme.typography.pxToRem(12)}`,
            }}
            disableRipple
            value="activity"
          >
            {t('Citizen')}
          </ToggleButton>
          <ToggleButton
            sx={{
              px: 2,
              py: 0.5,
              lineHeight: 1.5,
              fontSize: `${theme.typography.pxToRem(12)}`
            }}
            disableRipple
            value="trading"
          >
            {t('Government')}
          </ToggleButton>


        </ToggleButtonGroup>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h7" align='center'>
          {t('Please select the appropriate levels of the actions for your scenario.')}
        </Typography>   
      </Box>
      <Divider />

      {tabs === 'activity' && (
        <>
          <Box
            sx={{
              height: 540 // box length = chart length
            }}
          >
            <Scrollbar>

              <List disablePadding>

                {loadSliders()}
              </List>
              <Box
                px={2}
                py={3}
                sx={{
                  textAlign: 'center'
                }}
              >
                <Typography variant="subtitle2">
                  {t("You've reached the end of the list")}!
                </Typography>
              </Box>
            </Scrollbar>
          </Box>



        </>
      )}

      {
        /* 
        tabs === 'add' && (
          <>
            <Box
              sx={{
                height: 515
              }}
            >
             <SliderAdder addSlider={addSlider}/>
            </Box>
           
          </>
            )
            */
      }
      {tabs === 'trading' && (
        <>
          <Box
            sx={{
              height: 440
            }}
          >
            <Scrollbar>

              <List disablePadding>

                {
                  governmentSliders.map((gslider) => (
                    <>
                      <Range
                        id={gslider._id}
                        name={checkNameLanguage(gslider)}
                        title={checkTitleLanguage(gslider)}
                        value={gslider.value}
                        sliderValueHandler={sliderValueHandler}
                      />
                      <Divider />

                    </>
                  ))}
              </List>

              <Box
                px={2}
                py={3}
                sx={{
                  textAlign: 'center'
                }}
              >
                <Typography variant="subtitle2">
                  {t("You've reached the end of the list")}!
                </Typography>
              </Box>





            </Scrollbar>
          </Box>

        </>
      )}
      {!tabs && (
        <Box
          p={3}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            height: 440,
            textAlign: 'center'
          }}
        >
          <Box>
            <Typography
              align="center"
              variant="h2"
              fontWeight="normal"
              color="text.secondary"
              sx={{
                mt: 3
              }}
              gutterBottom
            >
              {t('Select one of the tabs to continue')}
            </Typography>

          </Box>
        </Box>
      )}
    </Card>
  );
}

export default SliderListAdmin;
