import React from "react";
import { Grid, Card, Box,FormControl,InputLabel,Select,MenuItem } from "@mui/material"
import { useState, useEffect } from "react";
import axios from "axios"
import { Fragment } from 'react';
import Range from './Range';
import CsvDownload from 'react-json-to-csv';
import jwtDecode  from 'jwt-decode';
import { useTranslation } from 'react-i18next';

const UserScenariosList = () => {

    const client = axios.create({
        baseURL: "https://co2.wecompair.eu/co2api"
      });

    const [userScenarios, setUserScenarios] = useState([])
    const [userScenarioMap, setUserScenarioMap] = useState(new Map())
    const [selectedUserScenario, setSelectedUserScenario] = useState("")

    const accessToken = localStorage.getItem('accessToken');   
    let sub = null;
    if (accessToken != null) {
        const decoded = jwtDecode(accessToken);
        sub = decoded.sub;
    }

    useEffect(() => {
        client.get(`/userScenario/${sub}`)
            .then((response) => {
      
                setUserScenarios(response.data)
      
                response.data.forEach(function (uscenario) {
                    setUserScenarioMap(new Map(userScenarioMap.set(uscenario._id, uscenario)))
                })
            });       
      }, []);


    const loadUserScenario = (scenarioID) =>{
        
        let scenarioList = []

        let scenario = userScenarioMap.get(scenarioID)
        

            let itemsInArrays = scenario.actionNames.length
            for (let i = 0; i < itemsInArrays; i++) {

                scenarioList.push(
                    <Fragment key={scenario._id+i}>
                        <Box>
                        <Range 
                        id={scenario._id}
                        name={scenario.actionNames[i]}
                        title={scenario.actionsSelectedTitles[i]}
                        value={scenario.stages[i]}
                        />
                        </Box>
                    </Fragment>
                )
        }

        return scenarioList
    }

    const loadScenarios = () =>{
        
        let scenarioList = []

        userScenarios.forEach((scenario) => {
                
                let timestamp = scenario._id.toString().substring(0,8)
                let date = new Date( parseInt( timestamp, 16 ) * 1000 )


                scenarioList.push(
                        <MenuItem value={scenario._id}>{date.toString()}</MenuItem>
                )    
        })

        return scenarioList
    }

    const { t } = useTranslation();

    const handleUserScenario = (e) => {
        setSelectedUserScenario(e.target.value)
        loadUserScenario(e.target.value)
      }

    return (
        <>
            <Grid container justifyContent="center" marginTop={1}>
                <Grid item xs={5}>
                    <Card>
                        <Box>
                        <FormControl variant="standard" sx={{ minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-standard-label">{t("Scenario")}</InputLabel>
                        <Select
                        label="scenario"
                        variant="standard"
                        name="scenario"
                        value={selectedUserScenario}
                        onChange={(e) => { 
                            handleUserScenario(e) 
                        }}
                        >
                                {loadScenarios()}
                            

                        </Select>
                    </FormControl>
                        </Box>
                    
                        <CsvDownload data={userScenarios} />

                    </Card>
                </Grid>

                <Grid item xs={5}>
                    <Card>
                        <Box>
                            {
                            (selectedUserScenario !== "")?
                            loadUserScenario(selectedUserScenario) : null
                            }
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default UserScenariosList;
