import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
 import useAuth from 'src/hooks/useAuth';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import AppInit from './components/AppInit';
// import { SessionProvider } from './components/SessionContext/SessionContext.js';

// import { useEffect, useState } from 'react';

// import Cookies from 'js-cookie';
import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react'
import { Helmet, HelmetProvider } from 'react-helmet-async';


function App() {
  const content = useRoutes(router);
  const auth = useAuth();
  // const [jwt, setJwt] = useState(null);
  // const content = useRoutes(router, { jwt });
  // const accessToken2 = localStorage.getItem('accessToken');
  // console.log("token2")
  // console.log(accessToken2)

  



  // useEffect(() => {
  //   window.addEventListener('message', event => {
  //     if (event.data.jwt) {
  //       // If a JWT is received, store it in state and set a cookie
  //       setJwt(event.data.jwt);
  //       Cookies.set('jwt', event.data.jwt, { expires: 0.02 }); // set expiration time to 30 minutes
  //     }
  //   });

  //   // Check if there is a JWT stored in a cookie when the component mounts
  //   const cookieJwt = Cookies.get('jwt');
  //   if (cookieJwt) {
  //     setJwt(cookieJwt);
  //   }

  //     // use the jwt variable here, for example:
  // console.log('The JWT token is:', jwt);

  // const subdomain = 'https://monitoring.dev.wecompair.eu/';
  // // const keyName = `${subdomain}_accessToken`;
  // const accessToken = localStorage.getItem(`${subdomain}_accessToken`);
  // // const accessToken = localStorage.getItem(keyName);
  // console.log("token")
  // console.log(accessToken)

  // }, []);

  


  // const content = useRoutes(router, { jwt });

  // Matomo stuff 
  const instance = createInstance({
    urlBase: 'https://matomo.wecompair.eu/',
    siteId: 2,
  })
  

  return (
    // <SessionProvider>
    <MatomoProvider value={instance}>

      <HelmetProvider>

      <Helmet>
        <meta httpEquiv="Content-Security-Policy" content="
        default-src 'self' https://co2.wecompair.eu https://monitoring.wecompair.eu/ data:; 
        script-src 'self' https://co2.wecompair.eu https://monitoring.wecompair.eu/ 'unsafe-inline'; 
        style-src 'self' https://fonts.googleapis.com 'unsafe-inline'; 
        font-src 'self' https://fonts.gstatic.com; 
        connect-src 'self' wss://co2.wecompair.eu:3000 https://services.wecompair.eu https://matomo.wecompair.eu;
        img-src 'self' https://a.tile.openstreetmap.org https://b.tile.openstreetmap.org https://c.tile.openstreetmap.org data:;"
        />
        <meta httpEquiv="Strict-Transport-Security" content="max-age=31536000; includeSubDomains" />
        <meta httpEquiv="X-Frame-Options" content="ALLOW-FROM https://monitoring.wecompair.eu/" />
        <meta httpEquiv="X-Content-Type-Options" content="nosniff" />
        <meta httpEquiv="Referrer-Policy" content="no-referrer" />
        <meta httpEquiv="Permissions-Policy" content="geolocation=(self 'https://co2.wecompair.eu' 'https://monitoring.wecompair.eu'), microphone=()" />
      </Helmet>

        <ThemeProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SnackbarProvider
              maxSnack={6}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
            >
              <CssBaseline />
              {auth.isInitialized ? content : <AppInit />}
            </SnackbarProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </HelmetProvider>
    </MatomoProvider>
    // </SessionProvider>
  );
}
export default App;