import React from 'react';
import { useTranslation } from 'react-i18next';

const Alert = ({ alert, hideAlert, status }) => {
  const { t } = useTranslation();

  return (
    <div className="col-12 py-1">
      <div
        // className="alert alert-success alert-dismissible fade show"
        className={
          status === 'success'
            ? 'alert alert-success alert-dismissible fade show'
            : 'alert alert-warning alert-dismissible fade show'
        }
        role="alert"
        hidden={alert}
      >
        {status === 'success' ? (
          <>
            <strong> {t("Calculated successfully")} </strong> {t("Check Results")}
          </>
        ) : (
          <>
            <strong> {t("Uncompleted fields")} </strong> {t("Fill out fields")}
          </>
        )}
        <button
          type="button"
          className="btn-close"
          onClick={(e) => {
            hideAlert(e);
          }}
          aria-label="Close"
        />
      </div>
    </div>
  );
};

export default Alert;
