import React from "react";
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box'

const Range = ({ id, name, title, sliderValueHandler, value }) => {




  return (
    <>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ mt: 1.5 ,textAlign: 'center'}}
      >
        <Typography id="sliderLabel">
          {name}
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ mb: 2 }}
        >
        <Slider
          style={{ height: 7, color: "primary", width: "80%"}}
          id={id}
          value={value}
          step={1}
          min={0}
          max={3}
          marks
          onChange={(e) => {

            if (e.target.value !== value) {
              sliderValueHandler(e.target.value, id)
            }
          }}


        />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ mt: -1. , mb: 1, textAlign: 'center'}}
      >
        <Typography id="sliderTitle" >
          {title}
        </Typography>
      </Box>

    </>
  );
};

export default Range;
