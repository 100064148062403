import React from 'react';
import { useTranslation } from 'react-i18next';


const WhatIs = () => {
  const { t } = useTranslation();

  return (
    <section id="whatis">
      <div className="container my-5 py-5 rounded bg-white">
        <div className="row justify-content-center">
          <div className="col-11">
            <h1 className="display-6 mb-3 py-1 text-center">
              {t("Explanation Title")}
            </h1>
            <p className="lead mb-4">
              {t("Explanation Paragraph 1")}
              <br />
              <br />
              {t("Explanation Paragraph 2")}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatIs;
