const grJSON = {
  Blueprints: 'Plans',

  // Scenario Simulation
  "Scenario Simulation": "Scenario Simulation",
  "Emission text":"Ο στόχος της Ευρωπαϊκής Ένωσης είναι 55% καθαρή μείωση στις εκπομπές αερίων θερμοκηπίου μέχρι το 2030",
  "% CO2 reduction" : "% Μείωση CO2",
  "end of list text" : "Φτάσατε στο τέλος της λίστας ",
  "Save Button Label" : "Αποθήκευση",
  "Empty Tabs Text" : "Επιλέξτε ένα tab για να συνεχίσετε",
  "Citizen Tab Text" : "Δράσεις που θα κάνατε ως πολίτης",
  "Government Tab Text" : "Δράσεις που θα υιοθετούσατε από το κράτος",
  "Local Government Tab Text" : "Δράσεις που θα υιοθετούσατε από την τοπική αυτοδιοίκηση",
  "Local Government" : "Τοπική αυτοδιοίκηση",
  "Current" : "Παρούσα κατάσταση",
  "Target" : "Στόχος",
  "Example Scenarios Tooltip" : "Μπορείτε να επιλέξετε μεταξύ ενδεικτικών σεναρίων. Από την μικρότερη απαιτούμενη προσπάθεια (min σενάριο δράσης) προς την μεγαλύτερη (max σενάριο δράσης) ως προς τη μείωση του αποτυπώματος άνθρακα",
  "Emission Tooltip" : "Μπορείτε να επιλέξετε μεταξύ ενδεικτικών σεναρίων. Από την μικρότερη απαιτούμενη προσπάθεια (min σενάριο δράσης) προς την μεγαλύτερη (max σενάριο δράσης) ως προς τη μείωση του αποτυπώματος άνθρακα",
  "Optimistic Scenario" : "Βέλτιστο σενάριο",
  "In an optimistic scenario where citizens change their everyday habits to be more sustainable and environmentally friendly." : "Στο βέλτιστο σενάριο οι πολίτες αλλάζουν τις καθημερινές τους συνήθειες σε πιο 'πράσινες' και φιλικές στο περιβάλλον",
  "Pessimistic Scenario" : "Απαισιόδοξο σενάριο",
  "In a pessimistic scenario where citizens do not change their everyday habits, the negative impacts on the environment would continue to exist." : "  Στο απαισιόδοξο σενάριο οι πολίτες δεν αλλάζουν τις καθημερινές τους συνήθειες και οι αρνητικές συνέπειες για το περιβάλλον παραμένουν.",
  "Realistic Scenario" : "Βέλτιστο σενάριο",
  "In a realistic scenario, citizens will try to change their everyday habbits towards achieving sustainability and improving the environment, while also being aware of the limitations and challenges that may exist." : "Στο ρεαλιστικό σενάριο οι πολίτες προσπαθούν να αλλάζουν τις καθημερινές τους συνήθειες σε πιο 'πράσινες' και πιο φιλικές για το περιβάλλον γνωρίζοντας τις δυσκολίες που μπορεί να υπάρχουν.",


  // Landing Page
  "Landing Page Title": "Πίνακας ελέγχου προσομοίωσης αποτυπώματος άνθρακα",
  "Landing Page Subtitle": "Μαζί μπορούμε να επιτύχουμε καθαρό αέρα για όλους. Χρησιμοποιήστε τον πίνακα ελέγχου προσομοίωσης ανθρακικού αποτυπώματος και δράστε σήμερα.",
  "Get Started Button": "Ξεκινήστε",
  "Explanation Title": "Τι είναι ο πίνακας ελέγχου προσομοίωσης ανθρακικού αποτυπώματος",
  "Explanation Paragraph 1": "Ο Dashboard Προσωμοίωσης CO2 αποτελείται από δύο εργαλεία. Το πρώτο είναι ο Υπολογιστής CO2. Αυτό το εργαλείο στοχεύει στο να βοηθάει τους πολίτες να κατανοήσουν πως επηρεάζεται το ενεργειακό τους αποτύπωμα από τις καθημερινές τους συνήθειες, καθώς και να παρέχει προτάσεις για την μείωση του αποτυπώματος άνθρακα.",    
  "Explanation Paragraph 2": "Το δεύτερο εργαλείο είναι το Dashboard Προσομοίωσης Σεναρίων. Αυτό το εργαλείο επιτρέπει στους πολίτες να συμμετέχουν στη λήψη αποφάσεων. Παρουσιάζει ένα σύνολο δράσεων που οι πολίτες θα ήταν διατεθειμένοι να δεχτούν από το κράτος με στόχο την μείωση εκπομπών άνθρακα. Οι πολίτες μπορούν να επιλέγουν ποιές δράσεις θα ήθελαν να υιοθετήσουν και στη συνέχεια να τις υποβάλλουν ως σενάρια, ώστε να ενημερώνονται οι υπεύθυνοι χάραξης πολιτικής.",
  "Keep track of your carbon footprint by signing up.": "Keep track of your carbon footprint by signing up.",
  "Sign Up Now": "Sign Up Now",
  "Sign In": "Sign In",


  // CO2 Calculator - Tab Names
  "Welcome": "Καλώς ήλθατε",
  "Demographic": "Γενικά",
  "Cars": "Αυτοκίνητα ",
  "Flights": "Πτήσεις ",
  "Trains": "Τρένα",
  "Buildings": "Κτήρια",
  "Waste Management": "Διαχείριση αποβλήτων",
  "Results": "Αποτελέσματα",

  // CO2 Calculator - Welcome
  "CO2 Calculator": "Υπολογιστής CO2",
  "Country Question": "Πρώτα, παρακαλώ πείτε μας που μένετε",
  "Country Option": "Χώρα",
  "Greece": "Ελλάδα",
  "greece": "Ελλάδα",
  "GREECE": "Ελλάδα",
  "Germany": "Γερμανία",
  "germany": "Γερμανία",
  "GERMANY": "Γερμανία",
  "Belgium": "Βέλγιο",
  "belgium": "Βέλγιο",
  "BELGIUM": "Βέλγιο",
  "Bulgaria": "Βουλγαρία",
  "bulgaria": "Βουλγαρία",
  "BULGARIA": "Βουλγαρία",
  "From": "Από",
  "To": "Ως",
  "Date Explanation": "Οι υπολογισμοί του αποτυπώματος άνθρακα βασίζονται συνήθως σε ετήσιες εκπομπές από τους προηγούμενους 12 μήνες. Εάν θέλετε να υπολογίσετε το αποτύπωμα άνθρακα για διαφορετική περίοδο, χρησιμοποιήστε το παρακάτω ημερολόγιο:",
  "Next Steps": "Στη συνέχεια, επιλέξτε την κατάλληλη καρτέλα παραπάνω για να υπολογίσετε το τμήμα του τρόπου ζωής που σας ενδιαφέρει περισσότερο, π.χ. τις πτήσεις. Εναλλακτικά, επισκεφτείτε κάθε μία από τις παραπάνω καρτέλες για να υπολογίσετε το πλήρες αποτύπωμα άνθρακα. Μετά τον υπολογισμό, μπορείτε να μειώσετε τις εκπομπές σας μέσω υποθετικών σεναρίων.",

  // CO2 Calculator - Demographic
  "Demographic Characteristics": "Γενικές Πληροφορίες",
  "City Question": "Σε ποια πόλη μένετε;",
  "City Option": "Πόλη",
  "Athens": "Αθήνα",
  "athens": "Αθήνα",
  "Brussels": "Βρυξέλλες",
  "brussels": "Βρυξέλλες",
  "Berlin": "Βερολίνο",
  "berlin": "Βερολίνο",
  "Plovdiv": "Ποντγκόριτσα",
  "plovdiv": "Ποντγκόριτσα",
  "Sofia": "Σόφια",
  "sofia": "Σόφια",
  "Other city or village": "Other city or village",
  "Age Question": "Ποια είναι η ηλικία σας;",
  "Age Option": "Ηλικία",
  // add the age options
  "Below 18": "Κάτω των 18",
  "Above 65": "Άνω των 65",
  "Gender Question": "Ποιο ειναι το φύλο σας;",
  "Gender Option": "Φύλο",
  // add the age options
  "Male": "Άντρας",
  "Female": "Γυναίκα",
  "Non-binary": "Μη δυαδικό φύλο",
  "Do not wish to specify": "Δε θα ήθελα να αναφέρω",
  "Marital Status Question": "Ποια είναι η οικογενειακή σας κατάσταση;",
  "Marital Status Option": "Οικογενειακή Κατάσταση",
  // add the marital status options
  "Social Aid Question": "Λαμβάνετε κάποιο κοινωνικό βοήθημα;",
  "Social Aid Option": "Κοινωνικό βοήθημα",
  "Education Level Question": "Ποιό είναι το επίπεδο εκπαίδευσής σας;",
  "Education Level Option": "Επίπεδο Εκπαίδευσης",
  "Primary Education": "Πρωτοβάθμια εκπαίδευση",
  "Secondary Education": "Δευτεροβάθμια εκπαίδευση",
  "University Education": "Πανεπιστημιακή εκπαίδευση",
  "Postgraduate Education": "Μεταπτυχιακή εκπαίδευση",
  "Municipal Communities Question": "Σε ποια Δημοτική Κοινότητα μένετε;",
  "Municipal Communities Option": "Δημοτική Κοινότητα ",
  "Previous": "Προηγούμενο",
  "Next": "Επόμενο",
  "How many times per week on average do you use the metro?": "Πόσες φορές την εβδομάδα κατά μέσο όρο χρησιμοποιείτε το μετρό;",
  "How many times per week on average do you use the bus?": "Πόσες φορές την εβδομάδα κατά μέσο όρο χρησιμοποιείτε το λεωφορείο;",
  "How many times per week on average do you use the tram?": "Πόσες φορές την εβδομάδα κατά μέσο όρο χρησιμοποιείτε το τραμ;",
  "How many times per week on average do you use a bicycle?": "Πόσες φορές την εβδομάδα κατά μέσο όρο χρησιμοποιείτε ποδήλατο;", 
  "By answering the questions below you help us understand how factors like age, gender, and education affect the daily activities that contribute to carbon footprint. This data is not meant to and won't be viewed individually.": "By answering the questions below you help us understand how factors like age, gender, and education affect the daily activities that contribute to carbon footprint. This data is not meant to and won't be viewed individually.", 


  // CO2 Calculator - Cars
  "CO2 from Cars": "CO2 από αυτοκίνητα",
  "Vehicles Info Text": "Οι οδικές μεταφορές ευθύνονται για το 1/5 των συνολικών εκπομπών CO2 της ΕΕ. Γνωρίζατε ότι τα επιβατικά αυτοκίνητα ευθύνονται για το 75% αυτών;",
  "Distance Question": "Διανηθείσα απόσταση (χλμ την καθορισμένη περίοδο)",
  "Liters Question": "Λίτρα/100χλμ ή kWh/100χλμ",
  "Type of Fuel Question": "Τύπος καύσιμου", // used for Buildings as well
  "Type of Fuel Option": "Τύπος καύσιμου", // used for Buildings as well
  // "Type of Fuel Option": "Fuel Type", // used for Buildings as well
  "Diesel": "Diesel",
  "Petrol": "Βενζίνη",
  "LPG": "LPG",
  "CNG": "CNG",
  "Petrol + E10": "Βενζίνη + E10",
  "Public Transport Question": "ΜΜΜ",
  "Electric Car Question": "Ηλεκτρικό αυτοκίνητο",
  "Liters/100km or kWh/100km": "Λίτρα/100χλμ ή kWh/100χλμ",
  "Electricity": "Ηλεκτρισμός",
  "Add Vehicle": "Προσθήκη οχήματος",
  "Remove Last Vehicle": "Διαγραφή τελευταίου οχήματος",
  "CO2 from Vehicles": "CO2 από οχήματα",
  "Vehicle": "Όχημα",
  "Vehicles": "Οχήματα",
  "Required": "Απαραίτητο",


  // CO2 Calculator - Flights
  "CO2 from Flights": "CO2 απο τις πτήσεις ",
  "Flights Info Text": "Πόσες πτήσεις εσωτερικού, μικρών, μεσαίων ή μεγάλων αποστάσεων έχετε κάνει για την επιλεγμένη περίοδο;",
  "Flight Description": "Περιγραφή ",
  "Number of Flights": "Αριθμός πτήσεων",
  "Avg Flight Distance": "Μέσος όρος απόστασης πτήσης (χλμ)",
  "Domestic": "Πτήση εσωτερικού",
  "Short Haul Flight": "Μικρή απόσταση (<3 ώρες)",
  "Medium Haul Flight": "Μεσαία απόσταση (3-6 ώρες)",
  "Long Haul Flight": "Μεγαλη απόσταση (6-12 ώρες)",
  "Flights Info Text 2": "Οι ενδιάμεσες πτήσεις και οι πτήσεις επιστροφής θεωρούνται ξεχωριστές πτήσεις η κάθεμία.",


  // CO2 Calculator - Trains
  "CO2 from Trains": "CO2 από τρένα",
  "Trains Info Text": "Πόσο συχνά ταξιδεύετε με τρένο;",
  "Number of Trips": "Αριθμός ταξιδιών",
  "Avg Trip Distance": "Μέση Απόσταση ταξιδιών (χλμ)",
  
  
  // CO2 Calculator - Buildings
  "CO2 from Buildings": "CO2 από κτήρια",
  "Fuel per Year": "Ποιά ήταν η κατανάλωση καυσίμων σας για την καθορισμένη περίοδο;",
  "Fuel per Year Tooltip": "Ποιά ήταν η κατανάλωση καυσίμων σας για την καθορισμένη περίοδο;",
  "Type of Fuel": "Τύπος καύσιμου",
  "Type of Fuel Tooltip": "Ποιά είναι η πηγή θέρμανσης του σπιτιού σας;",
  "Heating Oil": "Πετρέλαιο θέρμανσης",
  "Coal": "Γαιάνθρακας (κάρβουνο)",
  "Biomass": "Βιομάζα",
  "Natural Gas": "Φυσικό αέριο",
  "Not using any": "Δεν χρησιμοποιώ",
  "Electricity Buildings": "Ηλεκτρισμός (kWh την καθορισμένη περίοδο)",
  "Solar Thermal": "Χρήση ηλιακού για το ζεστό νερό",
  "Heat Pump": "Αντλία Θερμότητας",
  "Is your refrigerator energy efficient?": "Είναι το ψυγείο σας μέγιστης ενεργειακής κλάσης;",
  "Is your washing machine energy efficient?": "Είναι το πλυντήριο ρούχων σας μέγιστης ενεργειακής κλάσης;",
  "Is your dishwasher energy efficient?": "Είναι το πλυντήριο πιάτων μέγιστης ενεργειακής κλάσης;",
  "Is your oven energy efficient?": "Είναι ο φούρνος σας μέγιστης ενεργειακής κλάσης;",
  "No": "Όχι",
  "Semi-efficient": "Μεσαίας ενεργειακής κλάσης",
  "Yes": "Ναι",
  "I don't know": "Δεν γνωρίζω",
  "I don't own one": "Δεν έχω",
  "Regarding Energy Efficiency Labels": "Σχετικά με τις ετικέτες ενεργειακής απόδοσης",
  "Match table explanation": "Στις 1/3/2021 τα επίπεδα ενεργειακής κλάσεις των οικιακών συσκευών επικαιροποιήθηκαν. Παρακάτω μπορείτε να βρείτε έναν απλό πίνακα αντιστοίχισης ώστε να κατηγοριοποιήσετε τις συσκευές σας σε ενεργειακές κλάσεις αν έχουν αγοραστεί πριν τις 1/3/2021",
  "Refrigerator": "Ψυγείο",
  "Before 2021": "Πριν το 2021",
  "After 2021": "Μετά το 2021",
  "Washing Machine": "Πλυντήριο ρούχων",
  "Dishwasher": "Πλυντήριο πιάτων",
  "Oven": "Φούρνος",
  "The energy classes for ovens remain the same": "Οι ενεργειακές κλάσεις για τους φούρνους παραμένουν οι ίδιες",
  
  // CO2 Calculator - Waste Management
  "CO2 from Waste Management": "CO2 από Διαχείριση απορριμμάτων",
  "The average citizen in": "Ο μέσος πολίτης στην",
  "recycles": "ανακυκλώνει",
  "Kgs of waste": "Κιλά απορριμμάτων",
  "and composts": "και κομποστοποιεί",
  "Recycle Tooltip": "Πόσο χαρτί, γυαλί, πλαστικό και αλουμίνιο ανακυκλώνετε;",
  "Compost Tooltip": "Πόσα απορρίμματα κουζίνας κάνετε κομποστοποίηση;",
  "Choose": "Επιλογή",
  "Recycling": "Ανακύκλωση",
  "No recycling": "Χωρίς ανακύκλωση",
  "Less than average recycling": "Λιγότερο από το μέσο όρο ανακύκλωση",
  "Same as average recycling": "Το ίδιο με τη μέση ανακύκλωση",
  "More than average recycling": "Περισσότερο από το μέσο όρο ανακύκλωση",
  "Composting": "Κομποστοποίηση",
  "No composting": "Χωρίς κομποστοποίηση",
  "Less than average composting": "Λιγότερο από τη μέση κομποστοποίηση",
  "Same as average composting": "Ίσα με την μέση κομποστοποίηση",
  "More than average composting": "Περισσότερο από τη μέση κομποστοποίηση",
  "Calculate": "Υπολογισμός",
  "That is the equivalent of": "Αυτό αντιστοιχεί σε",
  "30lt trash bags": "σακούλες απορριμάτων 30 λίτρων",
  "and": "και",

  // CO2 Calculator - Completion Alerts
  "Calculated successfully": "Υπολογίστηκε με επιτυχία!",
  "Check Results": "Μπορείτε να ελέγξετε την καρτέλα Αποτελέσματα για να δείτε τις αλλαγές που εφαρμόστηκαν.",
  "Uncompleted fields": "Μη συμπληρωμένα πεδία!", 
  "Fill out fields": "Παρακαλώ συμπληρώστε όλα τα απαιτούμενα πεδία ώστε να υπολογιστούν τα αποτελέσματα.",

  
  // CO2 Calculator - Results
  "CO2 Calculator Results": "Αποτελέσματα υπολογισμού CO2",
  "Calculator Results": "Αποτελέσματα υπολογισμού",
  "Save": "Αποθήκευση",
  "Total": "Σύνολο",
  "User": "Χρήστης",
  "Waste": "Απορρίμματα",
  "Transportation": "Μετακίνηση",
  "For more information": "Για περισσότερες πληροφορίες",
  "Your results compared to your country's average": "Τα αποτελέσματά σας συγκριτικά με το μέσο όρο της χώρας σας",
  "You": "Εσείς",
  "Now that you know your carbon footprint, give us your opinion on how to reduce emissions.": "Now that you know your carbon footprint, give us your opinion on how to reduce emissions.",

  // Profile
  "Calculations List Title": "Υπολογισμοί",
  "Your Calculator Results": "Αποτελέσματα υπολογισμού",
  "tons CO2 per domain": "Αποτελέσματα υπολογισμού (τόνοι CO2 ανά τομέα)",
  "tons CO2": "τόνοι CO2",
  "Kilometers/Year": "Χιλιόμετρα/έτος",
  "Liters/100 Km": "Λίτρα/100 χλμ",
  "Full Name": "Όνοματεπώνυμο",
  "e-mail": "email",
  "Short Haul": "Μικρή απόσταση",
  "Medium Haul": "Μεσαία απόσταση",
  "Long Haul": "Μεγαλη απόσταση",
  "Europe": "Ευρώπη",
  "Export": "Εξαγωγή",
  "Scenario": "Σενάριο",
  "Energy Efficient Refrigerator": "Ψυγείο μέγιστης ενεργειακής κλάσης",
  "Energy Efficient Washing Machine": "Πλυντήριο ρούχων μέγιστης ενεργειακής κλάσης",
  "Energy Efficient Dishwasher": "Πλυντήριο πιάτων μέγιστης ενεργειακής κλάσης",
  "Energy Efficient Oven": "Φούρνος μέγιστης ενεργειακής κλάσης",
  "Car": "Αυτοκίνητο",
  "More Info": "Περισσότερες πληροφορίες ",

  
  // Admin Page - 
  "Percentage": "Ποσοστό",
  "Recommendations": "Συστάσεις",
  "Scenarios": "Σενάρια",
  "Actions": "Δράσεις",
  "Statistics": "Στατιστικά ",

  // Admin Page - Scenarios
  "Scenario Explanation": "Σε αυτη τη σελίδα μπορείτε να δημιουργήσετε τα δικά σας σεναρια επιλέγοντας ενέργειες από τη διπλανή στήλη. Μπορείτε επίσης να ενημερώσετε τα υπάρχοντα σενάρια",
  "Select slider action": "Επιλογή δράσης",    
  "Please select the appropriate levels of the actions for your scenario.": "Επιλέξτε τα κατάλληλα επίπεδα ενεργειών για το σενάριο σας",
  "Add Scenario": "Προσθήκη σεναρίου",
  "Cancel": "Ακύρωση",
  "Update": "Update",
  "Delete": "Ανανέωση ",
  "Please enter a title and a description for your scenario.": "Παρακαλούμε προσθέστε τίτλο και περιγραφή στο σενάριο.",
  "Citizen": "Πολίτης",
  "Government": "Κράτος",
  "Scenario Title": "Τίτλος σεναρίου",
  "Scenerio Description": "Περιγραφή σεναρίου",


  // Admin Page - Actions
  "Actions Title Text" : "Δράσεις",
  "Actions Title Text secondary" : "Επιλογή δράσης",
  "Action Label" : "Action Label",
  "Action Contribution Admin Page Text": "0 <= input <= 1",
  "Action Title 1 Admin Page Text" : "",
  "Action Title 2 Admin Page Text" : "",
  "Action Title 3 Admin Page Text" : "",
  "Action Percentage 1 Admin Page Text" : "",
  "Action Percentage 2 Admin Page Text" : "",
  "Action Percentage 3 Admin Page Text" : "",
  "Empty Fields Error Text" : "Τα πεδία δεν μπορούν να μείνουν κενά",
  "Clear" : "Καθαρισμός",
  "Category" : "Κατηγορία ",
  "Contribution" : "Συνεισφορά ",
  "Title" : "Τίτλος",
  "Add action" : "Προσθήκη δράσης",
  "No Action" : "Καμμία δράση",
  "First Action" : "Πρώτη δράση",
  "Second Action" : "Δεύτερη δράση",
  "Third Action" : "Τρίτη δράση",
  "Please choose the percentage of the implementation of your action" : "Επιλέξτε το ποσοστό υλοποίησης της δράσης σας",
  "You have to enter a number between 0 and 1 that represents a percentage" : "Πρέπει να εισαγάγετε έναν αριθμό μεταξύ 0 και 1 που αντιπροσωπεύει ένα ποσοστό",
  "Please choose the percentage of the implementation of the first stage of your action": "Επιλέξτε το ποσοστό υλοποίησης του πρώτου σταδίου της δράσης σας",
  "Please choose the percentage of the implementation of the second stage of your action": "Επιλέξτε το ποσοστό υλοποίησης του δεύτερου σταδίου της δράσης σας",
  "Please choose the percentage of the implementation of the third stage of your action": "Επιλέξτε το ποσοστό υλοποίησης του τρίτου σταδίου της δράσης σας",
  "Please add the title of the new action.": "Παρακαλούμε προσθέστε τίτλο στη δράση.",

  // Admin Page - Recommendations
  "Add Link" : "Προσθήκη υπερσυνδέσμου",
  "Remove Last Link" : "Διαγραφή υπερσυνδέσμου",
  "Link" : "Υπερσύνδεσμος",
  "Please enter the text for your new recommendation." : "Παρακαλούμε προσθέστε κείμενο στην πρόταση",
  "Recommendation Text" : "Κείμενο συστάσεων ",
  "Add Case" : "Προσθήκη ",
  "Add Recommendation" : "Προσθήκη συστάσεων ",
  "Operator" : "Μαθηματικό σύμβολο",
  "Value" : "Aξία ",
  "Country" : "Χώρα",


  // Admin Page - Statistics
  "Stage" : "Στάδιο",
  "Stage 0" : "Στάδιο 0",
  "Stage 1" : "Στάδιο 1",
  "Stage 2" : "Στάδιο 2",
  "Stage 3" : "Στάδιο 3",
  "Select Country" : "Επιλογή χώρας",
  "Select City" : "Επιλογή πόλης",
  "Select Municipal Region" : "Επιλογή δημοτικής περιοχής",
  "Select Age" : "Επιλογή ηλικίας",
  "Select Gender" : "Επιλογή φύλου",
  "Select Social Aid" : "Επιλογή κοινωνικού βοηθήματος",
  "Select Education Level" : "Επιλέξτε Επίπεδο Μόρφωσης ",
  "Scenario Simulation Statistics" : "Στατιστικά προσομοίωσης σεναρίων",
  "Carbon Footprint Calculator Statistics" : "Στατιστικά υπολογισμού αποτυπώματος άνθρακα",
  "Average" : "Μέσος όρος",


  // Recommendations
  "Consider replacing old fluorescent lighting with LEDs" : "Αντικατάσταση παλιού φωτισμού φθορισμού με LED",
  "Consider switching to Energy Efficient appliances" : "Αντικατάσταση συσκεύων με μεγαλύτερη ενεργειακή κλάση",
  "Consider using smart power strips" : "Χρήση έξυπνων πολύπριζων",
  "Consider turning down the heating by 1 degree Celsius" : "Μείωση της θέρμανσης κατά 1 C",
  "Consider unpluging electronical equipment when fully charged" : "Εφόσον έχουν φορτιστεί πλήρως οι συσκευές να βγαίνουν από την πρίζα",
  "Consider insulating your home" : "Μόνωση σπιτιού",
  "Consider increasing cycling by 30% (micromobility)" : "Αύξηση χρήσης ποδηλάτου (ή μικροκινητικότητας) κατά 30%",
  "Consider using the train for medium length distances (insted of the car)" : "Αντικατάσταση αυτοκινήτου με τρένο για μεσαίες αποστάσεις",
  "Consider carpooling to work" : "Carpooling για την πρόσβαση στην δουλειά",
  "Consider using public transport in your daily routine" : "Χρήση ΜΜΜ στην καθημερινότητα",
  "Consider replacing all business and first class trips by economy class trips" : "Αντικατάσταση business και 1ης θέσης με οικονομικές θέσεις",
  "Consider replacing indirect flights with direct flights" : "Αντικατάσταση δρομολογίων με ενδιαμέσες στάσεις με απευθείας δρομολόγια",
  "Consider reducing air travel by using trains or ferries" : "Μείωση πτήσεων με τη χρήση τρένων ή καραβιών",
  "Consider using a reusable bad to the market" : "Χρήση επαναχρησιμοποιούμενης τσάντας στα ψώνια",
  "Consider shopping at a bulk foods store" : "Αγορές σε καταστήματα χονδρικής",
  "Good job!" : "Μπράβο!",
  "Consider reducing the use of single plastics!" : "Μειώστε τη χρήση πλαστικού  ",
  "Consider using public transport" : "Χρήση ΜΜΜ",
  "Consider car sharing" : "Μοιραστείτε τις διαδρομές με το όχημά σας",
  "Consider replacing your washing machine with an energy efficient one" : "Αντικαταστήστε το πλυντήριο ρούχων με ένα πλυντήριο καλύτερης ενεργειακής κλάσης",
  "Consider replacing your oven with an energy efficient one" : "Αντικαταστήστε τον φούρνο με έναν φούρνο καλύτερης ενεργειακής κλάσης",
  "Consider replacing your refrigerator with an energy efficient one" : "Αντικαταστήστε το ψυγείο με ένα ψυγείο καλύτερης ενεργειακής κλάσης",
  "Consider replacing your dishwasher with an energy efficient one" : "Αντικαταστήστε το πλυντήριο πιάτων με ένα πλυντήριο καλύτερης ενεργειακής κλάσης",
  "Consider reducing the use of single plastics":"Μειώστε τη χρήση πλαστικού ",
  "Consider using a reusable bag to the market":"Χρησιμοποιείστε επαναχρησιμοποιούμενες σακούλες για το σούπερ μαρκετ ",
  "Consider purchasing preused items and/or materials":"Προμηθευτείτε μεταχειρισμένα είδη ή/και υλικά ",
  "Consider circular products instead of single use":"Χρησιμοποιείστε επαναχρησιμοποιούμενα προΪόντα αντί μιας χρήσης",
  "Consider products made of regenerated material sources": "Χρησιμοποιείστε προϊόντα από ανανεώσιμες πηγες ",
  "Consider less use of air-condition": "Χρησιμοποιείστε λιγότερο τον κλιματισμό",
  "Consider using your own thermo for coffee/tea/water takeaway": "Χρησιμοποιείστε το δικό σας θερμός για καφέ/τσάι/νερό που προμηθεύεστε απ'έξω",
  "Consider using the composting bins of the city or your own": "Χρησιμοποιείστε τους κάδους κομποστοποίησης της πόλης ή τους δικους σας",
  "Consider performing maintenance on your heating system": "Συντηρείστε το σύστημα θέρμανσής σας",
  "Consider upgrading your heating system": "Αναβαθμίστε το σύστημα θέρμανσής σας",
  "Consider using a more efficient vehicle": "Χρησιμοποιείστε ενα πιο αποδοτικό όχημα",
  "Consider donating surplus production out of your garden or field": "Δωρείστε την περίσσεια τροφής από τον κήπο ή το χωράφι σας ",
  "Consider sharing formulas instead of purchasing (shared vehicles, machines...)": "Εξετάστε το ενδεχόμενο να μοιραζεστε οχήματα, μηχανές κλπ",

  // Actions - Citizen
  "Improve Transportation Habits" : "Βελτίωση συνηθειών μετακίνησης",
  "20% increased Carpoolling" : "20% Αύξηση carpoolling",
  "50% increased Carpoolling" : "50% Αύξηση carpoolling",
  "70% increased Carpoolling" : "50% Αύξηση carpoolling",

  "Change Flying Habits" : "Αλλαγή συνηθειών πτήσεων",
  "20% Replace business class with economy" : "20% Ανταλλαγή business θέσης με οικονομική",
  "50% Replace business class with economy" : "50% Ανταλλαγή business θέσης με οικονομική",
  "90% Replace business class with economy" : "90% Ανταλλαγή business θέσης με οικονομική",

  "Improve Flying Habits" : "Βελτίωση συνηθειών πτήσεων",
  "20% Replace indirect flights with direct flights" : "20% Αντικατάσταση δρομολογιών ενδιάμεσης με απευθείας πτήσεις",
  "50% Replace indirect flights with direct flights" : "50% Αντικατάσταση δρομολογιών ενδιάμεσης με απευθείας πτήσεις",
  "90% Replace indirect flights with direct flights" : "90% Αντικατάσταση δρομολογιών ενδιάμεσης με απευθείας πτήσεις",
  
  "Change Travelling Habits" : "Αλλαγή συνηθειών ταξιδιών",
  "20% Train use for medium length distances" : "20% Χρήση τρένου για μεσαίες αποστάσεις",
  "50% Train use for medium length distances" : "50% Χρήση τρένου για μεσαίες αποστάσεις",
  "90% Train use for medium length distances" : "90% Χρήση τρένου για μεσαίες αποστάσεις",
  
  "Lighting Habits" : "Συνήθειες φωτισμού",
  "20% Replace old fluorescent lighting with LEDs" : "20% Αντικατάσταση λαμπτήρων φθορισμού με LED",
  "50% Replace old fluorescent lighting with LEDs" : "50% Αντικατάσταση λαμπτήρων φθορισμού με LED",
  "100% Replace old fluorescent lighting with LEDs" : "100% Αντικατάσταση λαμπτήρων φθορισμού με LED",
  
  "Appliances Habits" : "Συνήθειες χρήσης συσκευών",
  "20% Energy Efficient appliances" : "20% Συσκευές μεγάλης ενεργειακής κλάσης",
  "50% Energy Efficient appliances" : "50% Συσκευές μεγάλης ενεργειακής κλάσης",
  "100% Energy Efficient appliances" : "100% Συσκευές μεγάλης ενεργειακής κλάσης",
  
  "Insulation Habits" : "Συνήθειες μόνωσης",
  "20% Insulation Thickness" : "20% Insulation Thickness",
  "50% Insulation Thickness" : "50% Insulation Thickness",
  "100% Insulation Thickness" : "100% Insulation Thickness",

  "Improve Recycling Habits" : "Βελτίωση συνηθειών ανακύκλωσης", // needs fix
  "20% Increased recycling of plastics + paper + metal cans + glass" : "20% Αύξηση ανακύκλωσης πλαστικού+ χαρτιού+αλουμινίου+γυαλιού",
  "40% Increased recycling of plastics + paper + metal cans + glass" : "40% Αύξηση ανακύκλωσης πλαστικού+ χαρτιού+αλουμινίου+γυαλιού",
  "70% Increased recycling of plastics + paper + metal cans + glass" : "70% Αύξηση ανακύκλωσης πλαστικού+ χαρτιού+αλουμινίου+γυαλιού",

  "Introduce composting in everyday life": "Εισαγωγή κομποστοποίησης στην καθημερινότητα",
  "20% compost of organic waste by placing a bin": "Κομποστοποίηση οργανικών απορριμάτων κατά 20% με τη χρήση κάδου κομποστοποίησης",
  "40% compost of organic waste by placing a bin": "Κομποστοποίηση οργανικών απορριμάτων κατά 40% με τη χρήση κάδου κομποστοποίησης",
  "70% compost of organic waste by placing a bin": "Κομποστοποίηση οργανικών απορριμάτων κατά 70% με τη χρήση κάδου κομποστοποίησης",

  "Unplug devices that are not used or when on vacation": "Αποσύνδεση από την πρίζα συσκευές όταν δεν χρησιμοποιούνται ή όταν λείπετε σε διακοπές",
  "20% Unplug devices that are not operating": "20% αποσύνδεσης από την πρίζα συσκευών που δεν είναι σε λειτουργία",
  "40% Unplug devices that are not operating": "40% αποσύνδεσης από την πρίζα συσκευών που δεν είναι σε λειτουργία",
  "70% Unplug devices that are not operating": "70% αποσύνδεσης από την πρίζα συσκευών που δεν είναι σε λειτουργία",

  "Improve everyday mobility": "Βελτίωση κινητικότητας στην καθημερινότητα",
  "20% Increase everyday's cycling": "Αύξηση 20% στις καθημερινές διαδρομές με ποδήλατο",
  "40% Increase everyday's cycling": "Αύξηση 40% στις καθημερινές διαδρομές με ποδήλατο",
  "70% Increase everyday's cycling": "Αύξηση 70% στις καθημερινές διαδρομές με ποδήλατο",

  // Actions - Government
  "Capture and storage of CO2 from fossil fuel combustion" : "Συλλογή και αποθήκευση CO2 από την καυση ορυκτών καυσίμων",
  "40% Capture and storage of CO2" : "40% Συλλογή και αποθήκευση CO2",
  "60% Capture and storage of CO2" : "60% Συλλογή και αποθήκευση CO2",
  "100% Capture and storage of CO2" : "100% Συλλογή και αποθήκευση CO2",

  "Photovoltaic parks at petrol stations" : "Φωτοβολταϊκοί σταθμοί σε βενζινάδικα",
  "30% Installed photovoltaic parks at petrol stations" : "30% Εγκατάσταση φωτοβολταϊκών σταθμών σε βενζινάδικα",
  "60% Installed photovoltaic parks at petrol stations" : "60% Εγκατάσταση φωτοβολταϊκών σταθμών σε βενζινάδικα",
  "100% Installed photovoltaic parks at petrol stations" : "100% Εγκατάσταση φωτοβολταϊκών σταθμών σε βενζινάδικα",

  "Wind Energy" : "Αιολική ενέργεια",
  "30% penetration from wind energy" : "30% Αύξηση αιολικής ενέργειας",
  "60% penetration from wind energy" : "60% Αύξηση αιολικής ενέργειας",
  "90% penetration from wind energy" : "90% Αύξηση αιολικής ενέργειας",

  "Replacing cars with electric ones": "Αντικατάσταση αυτοκινήτων με ηλεκτρικά",
  "40% Replacing cars with electric ones": "40% Αντικατάσταση αυτοκινήτων με ηλεκτρικά",
  "60% Replacing cars with electric ones": "60% Αντικατάσταση αυτοκινήτων με ηλεκτρικά",
  "100% Replacing cars with electric ones": "100% Αντικατάσταση αυτοκινήτων με ηλεκτρικά",

  "Establish a speed limit of 30 km/h in cities": "Establish a speed limit of 30 km/h in cities",
  "20% Establish a speed limit of 30 km/h in cities": "20% Establish a speed limit of 30 km/h in cities",
  "60% Establish a speed limit of 30 km/h in cities": "60% Establish a speed limit of 30 km/h in cities",
  "100% Establish a speed limit of 30 km/h in cities": "100% Establish a speed limit of 30 km/h in cities",

  "Ιncreasing bike lanes in the city": "Αύξηση ποδηλατοδρόμων στην πόλη",
  "20% Ιncreasing bike lanes in the city": "20% Αύξηση ποδηλατοδρόμων στην πόλη",
  "40% Ιncreasing bike lanes in the city": "40% Αύξηση ποδηλατοδρόμων στην πόλη",
  "100% Ιncreasing bike lanes in the city": "100% Αύξηση ποδηλατοδρόμων στην πόλη",

  "Create green ports": "Δημιουργία πράσινων λιμανιών",
  "20% of cities ports become Green ports": "Μετατροπή των αστικών λιμανιών σε πράσινα λιμάνια κατά 20%",
  "40% of cities ports become Green ports": "Μετατροπή των αστικών λιμανιών σε πράσινα λιμάνια κατά 40%",
  "100% of cities ports become Green ports": "Μετατροπή των αστικών λιμανιών σε πράσινα λιμάνια κατά 100%",

  "Financial Assistance Program for Home Insulation Applications": "Πρόγραμμα οικονομικής ενίσχυσης για την συστήματα οικιακής μόνωσης",
  "20% Improve Home Insulation": "20% βελτίωση μόνωσης σπιτιών",
  "50% Improve Home Insulation": "50% βελτίωση μόνωσης σπιτιών",
  "100% Improve Home Insulation": "100% βελτίωση μόνωσης σπιτιών",

  "Financial Support Program for Replacing Domestic Heating Appliances": "Πρόγραμμα οικονομικής ενίσχυσης για την αντικατάσταση των οικιακών συστημάτων θέρμανσης",
  "20% Replacing fossil fuel boilers with heat pumps": "Αντικατάσταση των λέβητων ορυκτών καυσίμων με αντλίες θερμότητας κατά 20%",
  "40% Replacing fossil fuel boilers with heat pumps": "Αντικατάσταση των λέβητων ορυκτών καυσίμων με αντλίες θερμότητας κατά 40%",
  "100% Replacing fossil fuel boilers with heat pumps": "Αντικατάσταση των λέβητων ορυκτών καυσίμων με αντλίες θερμότητας κατά 100%",

  // Actions - Local Government
  "Improvement in rail service quality (frequency,speed)": "Βέλτιωση των σιδηροδρομικών υπηρεσιών (συχνότητα, ταχύτητα)",
  "20% Increase in rail usage": "Αύξηση 20% χρήσης σιδηροδρόμων",
  "40% Increase in rail usage": "Αύξηση 40% χρήσης σιδηροδρόμων",
  "90% Increase in rail usage": "Αύξηση 90% χρήσης σιδηροδρόμων",

  "Improving the energy efficiency of existing buildings (goverment support programme)": "Μείωση της κατανάλωσης ενέργειας σε κτήρια (μέσω εθνικού προγράμματος στήριξης)",
  "20% Improving the energy efficiency of existing buildings": "20% μείωση της κατανάλωσης ενέργειας σε κτήρια",
  "40% Improving the energy efficiency of existing buildings": "40% μείωση της κατανάλωσης ενέργειας σε κτήρια",
  "90% Improving the energy efficiency of existing buildings": "90% μείωση της κατανάλωσης ενέργειας σε κτήρια",

  "Increase Compostable stations": "Αύξηση κάδων κομποστοποίησης",
  "20% Increase Compostable stations": "20% Αύξηση κάδων κομποστοποίησης",
  "40% Increase Compostable stations": "40% Αύξηση κάδων κομποστοποίησης",
  "90% Increase Compostable stations": "90% Αύξηση κάδων κομποστοποίησης",

  "Bike-sharing programs at convenient locations": "Προγράμματα ενοικίασης ποδηλάτων σε προσβάσιμα σημεία",
  "20% Increase bike usage": "Αύξηση 20% χρήσης ποδηλάτου",
  "40% Increase bike usage": "Αύξηση 40% χρήσης ποδηλάτου",
  "70% Increase bike usage": "Αύξηση 70% χρήσης ποδηλάτου",

  "Start creating bicycle lanes": "Δημιουργία ποδηλατοδρόμων",
  "20% expand bicycle lanes": "Αύξηση 20% ποδηλατοδρόμων",
  "40% expand bicycle lanes": "Αύξηση 40% ποδηλατοδρόμων",
  "90% expand bicycle lanes": "Αύξηση 90% ποδηλατοδρόμων",

}

export default grJSON;