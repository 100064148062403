import { useRef, useState } from 'react';

import {
  IconButton,
  Box,
  List,
  ListItem,
  Typography,
  ListItemText,
  Popover,
  Tooltip,
  styled,
} from '@mui/material';

import internationalization from 'src/i18n/i18n';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";



 
const SectionHeading = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
        padding: ${theme.spacing(2, 2, 0)};
`
);

const IconButtonPrimary = styled(IconButton)(
  ({ theme }) => `
    display: inline-flex;
    width: 58px;
    border-radius: ${theme.general.borderRadiusLg};
    height: 58px;
    justify-content: center;
    font-size: ${theme.typography.pxToRem(13)};
    padding: 0;
    position: relative;

    svg {
      width: 32px;
    }

    .MuiSvgIcon-root {
      transition: ${theme.transitions.create(['color'])};
      font-size: ${theme.typography.pxToRem(28)};
      color: ${theme.colors.alpha.trueWhite[50]};
    }

    &.active,
    &:hover {
      background-color: ${theme.colors.alpha.black[10]};

      .MuiSvgIcon-root {
        color: ${theme.colors.alpha.trueWhite[100]};
      }
    }
`
);

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const getLanguage = i18n.language;

  const switchLanguage = ({ lng }) => {
    internationalization.changeLanguage(lng);
  };
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /* Hide footer when viewing through the Compair Project portal */
  const currentPath = useLocation();
  const isIntegrated = new URLSearchParams(currentPath.search).get('integration')
  if (isIntegrated) return null;




  return (
    <>
{/* TODO; Change be to NL */}
      <Tooltip arrow title={t('Language Switcher')}>
        <IconButtonPrimary color="primary" ref={ref} onClick={handleOpen}>
          {getLanguage === 'de' && <Typography color="primary"> DE </Typography>}
          {getLanguage === 'en' && <Typography color="primary"> EN </Typography>}
          {getLanguage === 'en-US' && <Typography color="primary"> EN </Typography>}
          {getLanguage === 'en-GB' && <Typography color="primary"> EN </Typography>}
          {getLanguage === 'gr' && <Typography color="primary"> GR </Typography>}
          {getLanguage === 'bg' && <Typography color="primary"> BG </Typography>}
          {getLanguage === 'be' && <Typography color="primary"> NL </Typography>}


        </IconButtonPrimary>
      </Tooltip>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <Box
          sx={{
            maxWidth: 240
          }}
        >
          <SectionHeading variant="body2" color="text.primary">
            {t('Language Switcher')}
          </SectionHeading>
          <List
            sx={{
              p: 2,
              svg: {
                width: 26,
                mr: 1
              }
            }}
            component="nav"
          >
            <ListItem
              className={
                getLanguage === 'en' || getLanguage === 'en-US' ? 'active' : ''
              }
              button
              onClick={() => {
                switchLanguage({ lng: 'en' });
                handleClose();
              }}
            >
              <ListItemText
                sx={{
                  pl: 1
                }}
                primary="English"
              />
            </ListItem>
            <ListItem
              className={getLanguage === 'de' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'de' });
                handleClose();
              }}
            >
              <ListItemText
                sx={{
                  pl: 1
                }}
                primary="German"
              />
            </ListItem>
            
            <ListItem
              className={getLanguage === 'bg' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'bg' });
                handleClose();
              }}
            >
              <ListItemText
                sx={{
                  pl: 1
                }}
                primary="Bulgarian"
              />
            </ListItem>

            
{/* TODO; Change be to NL */}
            <ListItem
              className={getLanguage === 'be' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'be' });
                handleClose();
              }}
            >
              <ListItemText
                sx={{
                  pl: 1
                }}
                primary="Dutch"
              />
            </ListItem>

            <ListItem
              className={getLanguage === 'gr' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'gr' });
                handleClose();
              }}
            >
              <ListItemText
                sx={{
                  pl: 1
                }}
                primary="Greek"
              />
            </ListItem>


          </List>
          
        </Box>
      </Popover>
    </>
  );
}

export default LanguageSwitcher;
