import { Box, Card, alpha, styled } from '@mui/material';
import Logo from './Logo';
import NavigationMenu from './NavigationMenu';
import LanguageSwitcher from './LanguageSwitcher';
import Userbox from './Userbox';

const BottomBarWrapper = styled(Card)(
  ({ theme }) => `
    height: ${theme.header.height};
    color: ${theme.header.textColor};
    padding: 0;
    right: 0;
    z-index: 6;
    background: #FFFFFF;
    backdrop-filter: blur(5px);
    box-shadow: none;
    position: fixed;
    justify-content: space-between;
    width: calc(100% - ${theme.spacing(4)});
    display: flex;
    align-items: stretch;
    border-radius: 0;
    border-bottom-left-radius: 40px;
    overflow-x: auto; /* Enable horizontal scrolling */
`
);

const BoxLogoWrapper = styled(Box)(
  ({ theme }) => `
  justify-content: center;
  align-items: center;
  display: flex;
  width: ${theme.spacing(14)};
`
);

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  flex-grow: 1;
  border-bottom-left-radius: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: ${theme.spacing(0, 3)};
  background: ${alpha(theme.colors.alpha.black[100], 0)};
`
);

const NavigationMenuWrapper = styled(Box)(
  () => `
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  max-width: 100%;
`
);

function BottomBar() {

  return (
    <BottomBarWrapper>
      <BoxLogoWrapper>
        <Logo />
      </BoxLogoWrapper>
      <MenuWrapper>
      
        <NavigationMenuWrapper
          sx={{
            // display: { xs: 'none', md: 'inline-block' }
            display: 'inline-block' 
          }}
        >
          <NavigationMenu />
        </NavigationMenuWrapper>

          <LanguageSwitcher />
          <Userbox />
          
      </MenuWrapper>
    </BottomBarWrapper>
  );
}

export default BottomBar;
