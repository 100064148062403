import React from 'react';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import {Button} from "@mui/material"
import {Box, Grid, Select, MenuItem, FormControl, InputLabel, TextField}  from "@mui/material";
import { useTranslation } from 'react-i18next';
import jwtDecode  from 'jwt-decode';

const WasteDisposal = ({
  toPrevTab,
  handleWaste,
  formData,
  setFormData,
  toNextTab,
  showRes,
  country,
  carC,
  flightC,
  trainC,
  buildingC,
  wasteC,
  carE,
  flightE,
  trainE,
  buildingE,
  wasteE
}) => {
  
  const wasteForm = (e) => {
    e.preventDefault();
    handleWaste();
  };

  const doStuff = () => {
    saveData();
    updateUniqueUserResults()
  }


  const saveData = () =>  {
    const client = axios.create({
      baseURL: "https://co2.wecompair.eu/co2api"
    });
    
    const accessToken = localStorage.getItem('accessToken');   
    let sub = null;
    if (accessToken != null) {
      const decoded = jwtDecode(accessToken);
       sub = decoded.sub;
    }

    client.post("/userCalculation", { 

      userID: sub,
      country : formData['country'],
      startDate: formData['startDate'],
      endDate: formData['endDate'],
      city: formData['city'],
      age: formData['age'],
      gender: formData['gender'],
      // maritalStatus: formData['marital'],
      socialAid: formData['socialAid'],
      educationLevel: formData['educationLevel'],
      municipalCommunity: formData['municipalCommunity'],
      busFrequency: formData['busFrequency'],
      tramFrequency: formData['tramFrequency'],
      metroFrequency: formData['metroFrequency'],
      bicycleFrequency: formData['bicycleFrequency'],
      carDistance: formData['car_distance'],
      carLitersPerKm: formData['liters'],
      carFuelType: formData['fuel'],
      usePublicTransport: formData['public'],
      useElectricCar: formData['electric'],
      domesticFlights: formData['domestic'],
      shortFlights: formData['short'],
      mediumFlights: formData['medium'],
      longFlights: formData['long'],
      trainTrips: formData['trips'],
      trainAvgDistance: formData['train_distance'],
      buildingFuel: formData['building_fuel'],
      buildingFuelType: formData['building_fuel_type'],
      buildingElectricity: formData['building_elec'],
      buildingUseSolarThermal: formData['solar'],
      buildingUseHeatPump: formData['pump'],
      buildingRefrigerator: formData['buildingRefrigerator'],
      buildingWashingMachine: formData['buildingWashingMachine'],
      buildingDishwasher: formData['buildingDishwasher'],
      buildingOven: formData['buildingOven'],
      wasteRecycling: formData['recycling'],
      wasteComposting: formData['composting'],
      transportationResults: formData['transportationResults'],
      flightResults: formData['flightResults'],
      trainResults: formData['trainResults'],
      buildingResults: formData['buildingResults'],
      wasteResults: formData['wasteResults'],
      transportationCountryAverage: carC,
      flightCountryAverage: flightC,
      trainCountryAverage: trainC,
      buildingCountryAverage: buildingC,
      wasteCountryAverage: wasteC,
      transportationEUAverage: carE,
      flightEUAverage: flightE,
      trainEUAverage: trainE,
      buildingEUAverage: buildingE,
      wasteEUAverage: wasteE
    })
  }

  const updateUniqueUserResults = () =>  {
    const client = axios.create({
       baseURL: "https://co2.wecompair.eu/co2api"
    });

    const accessToken = localStorage.getItem('accessToken');   
    let sub = null;
    if (accessToken != null) {
      const decoded = jwtDecode(accessToken);
       sub = decoded.sub;
    }

    const totalCO2 = (formData['transportationResults'] + formData['flightResults'] + formData['trainResults'] + formData['buildingResults'] + formData['wasteResults']).toFixed(2);

    if (sub != null) {
      if (country === 'BELGIUM') {
        client.post("/belgiumUnique", { 
  
          userID: sub,
          city: formData['city'],
          age: formData['age'],
          gender: formData['gender'],
          socialAid: formData['socialAid'],
          educationLevel: formData['educationLevel'],
          municipalCommunity: formData['municipalCommunity'],
          busFrequency: formData['busFrequency'],
          tramFrequency: formData['tramFrequency'],
          metroFrequency: formData['metroFrequency'],
          bicycleFrequency: formData['bicycleFrequency'],
          totalCO2: totalCO2,
          transportationResults: formData['transportationResults'],
          flightResults: formData['flightResults'],
          trainResults: formData['trainResults'],
          buildingResults: formData['buildingResults'],
          wasteResults: formData['wasteResults']
        })
      }
  
      if (country === 'GREECE') {
        client.post("/greeceUnique", { 
  
          userID: sub,
          city: formData['city'],
          age: formData['age'],
          gender: formData['gender'],
          socialAid: formData['socialAid'],
          educationLevel: formData['educationLevel'],
          municipalCommunity: formData['municipalCommunity'],
          busFrequency: formData['busFrequency'],
          tramFrequency: formData['tramFrequency'],
          metroFrequency: formData['metroFrequency'],
          bicycleFrequency: formData['bicycleFrequency'],
          totalCO2: totalCO2,
          transportationResults: formData['transportationResults'],
          flightResults: formData['flightResults'],
          trainResults: formData['trainResults'],
          buildingResults: formData['buildingResults'],
          wasteResults: formData['wasteResults']
        })
      }
  
      if (country === 'BULGARIA') {
        client.post("/bulgariaUnique", { 
  
          userID: sub,
          city: formData['city'],
          age: formData['age'],
          gender: formData['gender'],
          socialAid: formData['socialAid'],
          educationLevel: formData['educationLevel'],
          municipalCommunity: formData['municipalCommunity'],
          busFrequency: formData['busFrequency'],
          tramFrequency: formData['tramFrequency'],
          metroFrequency: formData['metroFrequency'],
          bicycleFrequency: formData['bicycleFrequency'],
          totalCO2: totalCO2,
          transportationResults: formData['transportationResults'],
          flightResults: formData['flightResults'],
          trainResults: formData['trainResults'],
          buildingResults: formData['buildingResults'],
          wasteResults: formData['wasteResults']
        })
      }
  
      if (country === 'GERMANY') {
        client.post("/germanyUnique", { 
  
          userID: sub,
          city: formData['city'],
          age: formData['age'],
          gender: formData['gender'],
          socialAid: formData['socialAid'],
          educationLevel: formData['educationLevel'],
          municipalCommunity: formData['municipalCommunity'],
          busFrequency: formData['busFrequency'],
          tramFrequency: formData['tramFrequency'],
          metroFrequency: formData['metroFrequency'],
          bicycleFrequency: formData['bicycleFrequency'],
          totalCO2: totalCO2,
          transportationResults: formData['transportationResults'],
          flightResults: formData['flightResults'],
          trainResults: formData['trainResults'],
          buildingResults: formData['buildingResults'],
          wasteResults: formData['wasteResults']
        })
      }
    }
  }
  
  const { t } = useTranslation();

  return (


    <Box sx={{border:1, borderRadius:1, borderColor:"lightgray"}} backgroundColor="white" >
    {/* <LeavePageWarningDialog open={dialogOpen} handleClose={handleCloseDialog} /> */}
    <div className="card">
          <div className="card-body">
            <h5 className="card-title text-center"> {t("CO2 from Waste Management")} </h5>
            <form
              className="row g-3 pt-5"
              onSubmit={(e) => {
                wasteForm(e);
              }}
            >

          <Grid container justifyContent="center" alignContent="center">
          <Grid item xs={6} sm={6} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            {t("From")}
          </Grid>
  
          <Grid item xs={6} sm={6} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            {t("To")}
          </Grid>
  
          <Grid item xs={6} sm={6} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            <TextField
              key={formData.startDate}
              defaultValue={formData.startDate}
              variant="outlined"
              InputProps={{
                readOnly: true,
                inputProps: { style: { textAlign: 'center' } }
              }}
              fullWidth
              size="small"
            />
          </Grid>

          <Grid item xs={6} sm={6} sx={{ px: 2, py: 1, textAlign: 'center' }}>
            <TextField
              key={formData.endDate}
              defaultValue={formData.endDate}
              variant="outlined"
              InputProps={{
                readOnly: true,
                inputProps: { style: { textAlign: 'center' } }
              }}
              fullWidth
              size="small"
            />
          </Grid>
          </Grid>

          
              <div className="row">
                <div className="col-12 mb-4 d-flex align-items-center justify-content-center text-center">
                  <h6
                    className="border border-success border-2 border-opacity-25 rounded-pill p-3"
                    style={{
                      width: 'fit-content'
                    }}
                  >
                    {t("The average citizen in")} <strong>{ t(country) || '<COUNTRY>'}</strong>{' '}
                   {t("recycles")}{' '}
                    <strong>
                      {country === 'GREECE'
                        ? 96
                        : country === 'GERMANY'
                        ? 160
                        : country === 'BULGARIA'
                        ? 79.5
                        : country === 'BELGIUM'
                        ? 137
                        : '<NUMBER>'}
                    </strong>{' '}
                    {t("Kgs of waste")} {t("and composts")} {' '}
                    <strong>
                      {country === 'GREECE'
                        ? 15
                        : country === 'GERMANY'
                        ? 125
                        : country === 'BULGARIA'
                        ? 16
                        : country === 'BELGIUM'
                        ? 82
                        : '<NUMBER>'}
                    </strong>{' '}
                    {t("Kgs of waste")}      
                    <br />
                    {t("That is the equivalent of")} {' '}
                    <strong>
                      {country === 'GREECE'
                        ? 39
                        : country === 'GERMANY'
                        ? 64
                        : country === 'BULGARIA'
                        ? 32
                        : country === 'BELGIUM'
                        ? 55
                        : '<NUMBER>'}
                    </strong>{' '}      
                    {t("and")} {' '}
                    <strong>
                      {country === 'GREECE'
                        ? 2
                        : country === 'GERMANY'
                        ? 13
                        : country === 'BULGARIA'
                        ? 2
                        : country === 'BELGIUM'
                        ? 9
                        : '<NUMBER>'}
                    </strong>{' '}  
                    {t("30lt trash bags")} 
                  </h6>
                </div>
                <div className="col-md-6">
                  <Grid item xs={5} sx={{pl: 4, py: 0.5, lineHeight: 1.5,}}>
                    <FormControl fullWidth required label="Required" >
                      <Tooltip disableFocusListener disableTouchListener title= {t("Recycle Tooltip")} >
                        <InputLabel  id="selectlabel"> {t("Recycling")} </InputLabel>
                      </Tooltip>
                        <Select 
                          required
                          label="Required"
                          labelId="selectLabel" 
                          value={formData.recycling} 
                          onChange={(e) => {
                              setFormData({ ...formData, recycling: e.target.value });
                              handleWaste();
                          }}
                          style={{ textAlign: 'center' }} 
                          >
                          <MenuItem value={0} disabled style={{ display: 'none' }}>
                            {t("Recycling")}
                          </MenuItem>
                          <MenuItem value={200}> {t("No recycling")} </MenuItem>
                          <MenuItem value={486.4}> {t("Less than average recycling")} </MenuItem>
                          <MenuItem value={608}> {t("Same as average recycling")} </MenuItem>
                          <MenuItem value={729.6}> {t("More than average recycling")} </MenuItem>
                        </Select>
                    </FormControl>
                  </Grid>
    
                </div>
                <div className="col-md-6">
                  <Grid item xs={5} sx={{pl: 4, py: 0.5, lineHeight: 1.5,}}>
                    <FormControl fullWidth required label="Required" >
                      <Tooltip disableFocusListener disableTouchListener title= {t("Compost Tooltip")} >
                        <InputLabel  id="selectlabel"> {t("Composting")} </InputLabel>
                      </Tooltip>  
                        <Select 
                          required
                          label="Required"
                          labelId="selectLabel" 
                          value={formData.composting} 
                          onChange={(e) => {
                            setFormData({ ...formData, composting: e.target.value });
                            handleWaste();
                          }}
                          style={{ textAlign: 'center' }} 
                          >
                          <MenuItem value={0} disabled style={{ display: 'none' }}>
                            {t("Composting")}
                          </MenuItem>
                          <MenuItem value={200}> {t("No composting")} </MenuItem>
                          <MenuItem value={486.4}> {t("Less than average composting")} </MenuItem>
                          <MenuItem value={608}> {t("Same as average composting")} </MenuItem>
                          <MenuItem value={729.6}> {t("More than average composting")} </MenuItem>
                        </Select>
                    </FormControl>
                  </Grid>
                </div>
              </div>
    
              <div className="col-12">
                <div className="d-flex justify-content-between">
    
                  <Grid item xs={5}>
                    <Button variant = "text" onClick={(e) => toPrevTab(e)}>
                      {t("Previous")}
                    </Button>
                  </Grid>
    
                  <Grid item xs={6}>
                    {/* <Button type="submit" onClick={() => saveData()}> */}
                    <Button type="submit" onClick={() => doStuff()}>
                      {t("Calculate")}
                    </Button>
                  </Grid>
    
                  <Grid item>
                    <Button variant = "text" disabled={showRes} onClick={(e) => toNextTab(e)}>
                      {t("Results")}
                    </Button>
                  </Grid>
    
                </div>
              </div>
            </form>
          </div>
        </div>
    </Box>
  );
};
    
    export default WasteDisposal;
    