import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import Login from 'src/content/pages/Auth/Login/Cover';

const Authenticated = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    // return <Login />;
    // Allow access to specific pages for users who have not logged in
    if (location.pathname === '/' || location.pathname === '/home' || location.pathname === '/dashboard' || location.pathname === '/calculator') {
      return <>{children}</>;
    } 
    return <Login />;
  }

  if (auth.user && auth.user.role) {
    if (location.pathname.includes('/adminpage')) {
      if (!auth.user.role.includes("admin")) {
        // Redirect logged-in users without admin role to unauthorized page
        return <Navigate to="/unauthorized" />;
      }
    }
  }

  // if(auth.isAuthenticated){
  //     console.log(auth.isAuthenticated)
  //   }
  if (location.pathname.includes('/adminpage') && (!auth.isAuthenticated || !auth.user || !auth.user.role || !auth.user.role.includes('admin'))) {

    // Redirect to unauthorized page if accessing AdminPage without admin role or without authentication
    return <Navigate to="/unauthorized" />;
  }

  if (location.pathname.includes('/adminpage') && auth.user === null) {
    return <Login />;
  }




  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

Authenticated.propTypes = {
  children: PropTypes.node
};

export default Authenticated;
