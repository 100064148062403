import { Box,  useTheme, styled } from '@mui/material';
import BottomBar from './BottomBar';
import Scrollbar from 'src/components/Scrollbar';
import { Outlet } from 'react-router-dom';

const MainWrapper = styled(Box)(
  () => `
      flex: 1;
      display: flex;
      height: 100%;

      .footer-wrapper {
        overflow: hidden;
        height: 0;
        box-shadow: none;
        border: 0;
      }
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;
`
);

const BottomNavigationLayout = () => {
  const theme = useTheme();

  return (
    <>
      <MainWrapper>

      <BottomBar />

        <MainContent
          sx={{
            '.MuiDrawer-hd': {
              '& .MuiDrawer-paper': {
                top: 0,
                width: `calc(320px + ${theme.spacing(4)})`,
                position: 'fixed'
              }
            },
            '.MuiDrawer-fm': {
              '& .MuiDrawer-paper': {
                top: 0,
                position: 'fixed'
              }
            },
            '.Mui-FixedWrapper': {
              '.MuiDrawer-root.MuiDrawer-docked': {
                '.MuiPaper-root': {
                  left: 0,
                  top: 0
                }
              }
            }
          }}
        >
          <Scrollbar>
            
            <Outlet/>



          </Scrollbar>
          

        </MainContent>

      </MainWrapper>

    </>
  );
};

export default BottomNavigationLayout;
