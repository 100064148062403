// import { createContext, useEffect, useReducer, useState } from 'react';
import { createContext, useEffect, useReducer } from 'react';
import axios from 'src/utils/axios';
// import { verify, JWT_SECRET } from 'src/utils/jwt';
import PropTypes from 'prop-types';
import jwtDecode  from 'jwt-decode';
import internationalization from 'src/i18n/i18n';

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  role: null
};

const setSession = (accessToken) => {
  
  if (accessToken) {
    // localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    // localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  role: null
});

const switchLanguage = ({ lng }) => {
  internationalization.changeLanguage(lng);
};

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(() => {
    const initialize = async () => {
      try {

        let user;
        let accessToken = localStorage.getItem('accessToken');

        if (accessToken) {
          // Token exists in local storage, dispatch action to update state
    
          const decoded = jwtDecode(accessToken);
          const email = decoded.email;

          localStorage.setItem('email', email);
          user  = decoded;

          if (decoded.resource_access.compair.roles.includes('admin')) {
            user.role = 'admin';
          }
      
          if (decoded.resource_access.compair.roles.includes('user')) {
            user.role = 'user';
          }
      
          if (decoded.email.includes('chkarelis@gmail.com')) {
            user.role = 'admin';
          }
          
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          // // Token doesn't exist, redirect to login page
          // // return <Navigate to="/login" />;
          // window.location.href = '/home';
          // return;
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }

        window.addEventListener("message", (event) => {

          if (event.origin !== "https://monitoring.dev.wecompair.eu/" || event.origin !== "https://monitoring.wecompair.eu/") {

            if (event.data && event.data.accessToken) {
      
              accessToken = event.data.accessToken;
              localStorage.setItem('accessToken', accessToken);
        
              const decoded = jwtDecode(accessToken);
              const email = decoded.email;

              localStorage.setItem('email', email);
              user  = decoded;

              if (decoded.resource_access.compair.roles.includes('admin')) {
                user.role = 'admin';
              }
          
              if (decoded.resource_access.compair.roles.includes('user')) {
                user.role = 'user';
              }
          
              if (decoded.email.includes('chkarelis@gmail.com')) {
                user.role = 'admin';
              }
            } 
          }



          if (event.data && event.data.language) {
            switchLanguage({ lng: event.data.language });
          } 
        });

          dispatch({
              type: 'INITIALIZE',
              payload: {
                // isAuthenticated: true,
                isAuthenticated: !!accessToken,
                user
              }
          });
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };
  
    initialize();
  }, []);

  const login = async (email, password) => {
    // const response = await axios.post('/api/account/login', {
    //   email,
    //   password
    // });
    const response = await fetch('https://services.wecompair.eu/users/users/authenticate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        password: password
      })
    })
    const data = await response.json();
    // console.log("data received")
    // console.log("data:", data)
    // console.log(data.accessToken)
    const { accessToken  } = data.accessToken;
    // console.log("data.accessToken:", data.accessToken)
    localStorage.setItem('accessToken', data.accessToken);
    // console.log(accessToken)
    const decoded = jwtDecode(data.accessToken)
    console.log("jwtauth")
    console.log(decoded)

    const user  = decoded;
    if (decoded.resource_access.compair.roles.includes("admin")) {
      user.role = "admin";
    }

    if (decoded.resource_access.compair.roles.includes("user")) {
      user.role = 'user';
    }

    if (decoded.email.includes('chkarelis@gmail.com')) {
      console.log('hi chris')
      user.role = "admin";
    }

    console.log("user")
    console.log(user)

    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      }
    });
  };

  const logout = async () => {
    setSession(null);
    localStorage.removeItem('accessToken')
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        // register
        // jwtoken, setJwtoken
      }   
      }
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
