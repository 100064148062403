const nlJSON = {
  Blueprints: 'Plans',

  // Scenario Simulation
  "Scenario Simulation": "Scenario Simulation",
  "Emission text":"De doelstelling van de Europese Commissie om de uitstoot van broeikasgassen tegen 2030 netto met 55% te verminderen",
  "% CO2 reduction" : "% CO2 reductie",
  "You've reached the end of the list!" : "Je hebt het einde van de lijst bereikt!",
  "Save Button Label" : "Opslaan",
  "Empty Tabs Text" : "Selecteer een van de tabbladen om verder te gaan",
  "Citizen Tab Text" : "Acties die je bereid bent te ondernemen als burger",
  "Government Tab Text" : "Acties die je bereid bent te accepteren van de overheid",
  "Local Government Tab Text" : "Acties van de lokale overheid die u aanvaardbaar vindt",
  "Local Government" : "Lokale overheid",
  "Current" : "Huidige",
  "Target" : "Doel",
  "Example Scenarios Tooltip" : "Hier kun je kiezen tussen voorbeeldscenario's. Van de kleinste inspanning (min. actiescenario) tot de grootste inspanning (max. actiescenario) om je CO2-voetafdruk te verminderen.",
  "Emission Tooltip" : "Kies acties die je bereid bent te ondernemen en acties die je bereid bent te accepteren van de overheid om je CO2-uitstoot te verminderen.",
  "Optimistic Scenario" : "Optimistisch scenario",
  "In an optimistic scenario where citizens change their everyday habits to be more sustainable and environmentally friendly." : "In een optimistisch scenario veranderen burgers hun dagelijkse gewoonten om duurzamer en milieuvriendelijker te zijn.",
  "Pessimistic Scenario" : "Pessimistisch scenario",
  "In a pessimistic scenario where citizens do not change their everyday habits, the negative impacts on the environment would continue to exist." : "In een pessimistisch scenario waarin burgers hun dagelijkse gewoonten niet veranderen, blijven de negatieve gevolgen voor het milieu bestaan.",
  "Realistic Scenario" : "Pessimistisch scenario",
  "In a realistic scenario, citizens will try to change their everyday habbits towards achieving sustainability and improving the environment, while also being aware of the limitations and challenges that may exist." : "In een realistisch scenario zullen burgers proberen hun dagelijkse gewoonten te veranderen om duurzaamheid te bereiken en het milieu te verbeteren, terwijl ze zich ook bewust zijn van de beperkingen en uitdagingen die kunnen bestaan.",


  // Landing Page
  "Landing Page Title": "CO2-voetafdruk simulatie dashboard",
  "Landing Page Subtitle": "Samen kunnen we schone lucht voor iedereen bereiken. Gebruik het Carbon Footprint Simulation Dashboard en onderneem vandaag nog actie.",
  "Get Started Button": "Aan de slag",
  "Explanation Title": "Wat is het Carbon Footprint Simulatie Dashboard",
  "Explanation Paragraph 1": "Het Carbon Footprint Simulation Dashboard bestaat uit twee tools. De eerste is de CO2-voetafdrukcalculator. Deze tool is bedoeld om burgers te helpen begrijpen hoe zij hun CO2-voetafdruk beïnvloeden door hun dagelijkse activiteiten en om aanbevelingen te geven over hoe zij hun CO2-voetafdruk kunnen verminderen.",    
  "Explanation Paragraph 2": "De tweede tool is het Scenario Simulatie Dashboard. Met deze tool kunnen burgers deelnemen aan het maken van beleid. Het presenteert een reeks acties die zij bereid zijn te ondernemen, evenals acties die zij bereid zijn te accepteren van de overheid met het doel de CO2-uitstoot te verminderen. Burgers kunnen kiezen welke acties ze denken te willen ondernemen en dat creëert een scenario dat ze kunnen indienen en waar beleidsmakers inzicht uit kunnen halen.",
  "Keep track of your carbon footprint by signing up.": "Keep track of your carbon footprint by signing up.",
  "Sign Up Now": "Sign Up Now",
  "Sign In": "Sign In",


  // CO2 Calculator - Tab Names
  "Welcome": "Welkom",
  "Demographic": "General",
  "Cars": "Auto's",
  "Flights": "Vluchten",
  "Trains": "Treinen",
  "Buildings": "Gebouwen",
  "Waste Management": "Afvalbeheer",
  "Results": "Resultaten",

  // CO2 Calculator - Welcome
  "CO2 Calculator": "CO2-berekenen",
  "Country Question": "Vertel ons eerst waar je woont.",
  "Country Option": "Land",
  "Greece": "Griekenland",
  "greece": "Griekenland",
  "GREECE": "Griekenland",
  "Germany": "Duitsland",
  "germany": "Duitsland",
  "GERMANY": "Duitsland",
  "Belgium": "België",
  "belgium": "België",
  "BELGIUM": "België",
  "Bulgaria": "Bulgarije",
  "bulgaria": "Bulgarije",
  "BULGARIA": "Bulgarije",
  "From": "Van",
  "To": "Tot",
  "Date Explanation": "Berekeningen van de CO2-voetafdruk zijn meestal gebaseerd op de jaarlijkse uitstoot van de afgelopen 12 maanden. Als je je CO2-voetafdruk voor een andere periode wilt berekenen, gebruik dan de kalendervakken hieronder:",
  "Next Steps": "Selecteer vervolgens het juiste tabblad hierboven om het deel van uw levensstijl te berekenen waarin u het meest geïnteresseerd bent, bijvoorbeeld uw vluchten. Of ga naar elk van de tabbladen hierboven om uw volledige CO2-voetafdruk te berekenen. Na uw berekening kunt u uw uitstoot compenseren / neutraliseren via een van onze klimaatvriendelijke projecten.",

  // CO2 Calculator - Demographic
  "Demographic Characteristics": "General Information",
  "City Question": "In welke stad woon je?",
  "City Option": "Stad",
  "Athens": "Athene",
  "athens": "Athene",
  "Brussels": "Brussel",
  "brussels": "Brussel",
  "Berlin": "Berlijn",
  "berlin": "Berlijn",
  "Plovdiv": "Plovdiv",
  "plovdiv": "Plovdiv",
  "Sofia": "Sofia",
  "sofia": "Sofia",
  "Other city or village": "Other city or village",
  "Age Question": "Wat is je leeftijd?",
  "Age Option": "Leeftijd",
  // add the age options
  "Below 18": "onder 18 ",
  "Above 65": "boven 65",
  "Gender Question": "Wat is uw geslacht?",
  "Gender Option": "Geslacht",
  // add the age options
  "Male": "Vrouw",
  "Female": "Mannelijk",
  "Non-binary": "non-binair",
  "Do not wish to specify": "Niet specificeren",
  "Marital Status Question": "Wat is uw burgerlijke staat?",
  "Marital Status Option": "Marital Status",
  // add the marital status options
  "Social Aid Question": "Ontvangt u één of andere vorm van maatschappelijke hulp?",
  "Social Aid Option": "Maatschappelijke hulp",
  "Education Level Question": "Wat is uw opleidingsniveau",
  "Education Level Option": "Opleidingsniveau",
  "Primary Education": "Lager onderwijs",
  "Secondary Education": "Middelbaar onderwijs",
  "University Education": "Hoger/universitair onderwijs",
  "Postgraduate Education": "Postgraduaat opleiding",
  "Municipal Communities Question": "In welke stad/gemeente woont u?",
  "Municipal Communities Option": "Stad/gemeente",
  "Previous": "Vorige",
  "Next": "Volgende",
  "How many times per week on average do you use the metro?": "Hoe vaak gebruik je de metro (gemiddeld per week)?",
  "How many times per week on average do you use the bus?": "Hoe vaak gebruik je de bus (gemiddeld per week)?",
  "How many times per week on average do you use the tram?": "Hoe vaak gebruik je de tram (gemiddeld per week)?",
  "How many times per week on average do you use a bicycle?": "Hoe vaak gebruik je de fiets (gemiddeld per week)?", 
  "By answering the questions below you help us understand how factors like age, gender, and education affect the daily activities that contribute to carbon footprint. This data is not meant to and won't be viewed individually.": "By answering the questions below you help us understand how factors like age, gender, and education affect the daily activities that contribute to carbon footprint. This data is not meant to and won't be viewed individually.", 


  // CO2 Calculator - Cars
  "CO2 from Cars": "CO2 van auto's",
  "Vehicles Info Text": "Het wegvervoer is verantwoordelijk voor 1/5 van de totale CO2-uitstoot in de EU. Wist je dat personenauto's verantwoordelijk zijn voor 75% daarvan?",
  "Distance Question": "Afgelegde afstand (km per toegewezen periode)",
  "Liters Question": "Liter/100 KM",
  "Type of Fuel Question": "Type brandstof", // used for Buildings as well
  "Type of Fuel Option": "Type brandstof", // used for Buildings as well
  // "Type of Fuel Option": "Fuel Type", // used for Buildings as well
  "Diesel": "Diesel",
  "Petrol": "Benzine",
  "LPG": "LPG",
  "CNG": "CNG",
  "Petrol + E10": "Benzine + E10",
  "Public Transport Question": "Openbaar vervoer",
  "Electric Car Question": "Elektrische auto",
  "Liters/100km or kWh/100km": "Liter/100 KM of KW/100km",
  "Electricity": "Electriciteit",
  "Add Vehicle": "Voeg een voertuig toe",
  "Remove Last Vehicle": "Verwijder laatste voertuig",
  "CO2 from Vehicles": "CO2 door fietsen",
  "Vehicle": "Vehicle",
  "Vehicles": "Voertuigen",
  "Required": "Noodzakelijk",


  // CO2 Calculator - Flights
  "CO2 from Flights": "CO2 van vluchten",
  "Flights Info Text": "Hoeveel binnenlandse, korte, middellange of lange vluchten heb je genomen in de gekozen periode?",
  "Flight Description": "Beschrijving (vluchtduur)",
  "Number of Flights": "Aantal vluchten",
  "Avg Flight Distance": "Gemiddelde afstand van vlucht (km)",
  "Domestic": "Binnenlands",
  "Short Haul Flight": "Korte afstand (<3 uur)",
  "Medium Haul Flight": "Middellange afstand (3-6 uur)",
  "Long Haul Flight": "Lange afstand (6-12 uur)",
  "Flights Info Text 2": "Aansluitende vluchten en retourvluchten gelden als een vlucht op zichzelf.",


  // CO2 Calculator - Trains
  "CO2 from Trains": "CO2 van Treinen",
  "Trains Info Text": "Hoe vaak reis je met de trein?",
  "Number of Trips": "Aantal reizen",
  "Avg Trip Distance": "Gemiddelde afstand van reis (km)",
  
  
  // CO2 Calculator - Buildings
  "CO2 from Buildings": "CO2 van gebouwen",
  "Fuel per Year": "Wat is je brandstofverbruik voor de toegewezen periode?",
  "Fuel per Year Tooltip": "Wat is je brandstofverbruik voor de toegewezen periode?",
  "Type of Fuel": "Type brandstof",
  "Type of Fuel Tooltip": "Welk type verwarmingsbron gebruikt u in uw huis?",
  "Heating Oil": "Verwarming Olie",
  "Coal": "Kolen",
  "Biomass": "Biomassa",
  "Natural Gas": "Aardgas",
  "Not using any": "Not using any",
  "Electricity Buildings": "Electriciteit (Kw per toegewezen periode)",
  "Solar Thermal": "Use of solar for water heating",
  "Heat Pump": "Warmtepomp",
  "Is your refrigerator energy efficient?": "Is uw koelkast energiezuinig?",
  "Is your washing machine energy efficient?": "Is uw wasmachine energiezuinig?",
  "Is your dishwasher energy efficient?": "Is je vaatwasser energiezuinig?",
  "Is your oven energy efficient?": "Is je oven energiezuinig?",
  "No": "Geen",
  "Semi-efficient": "Halfzuinig",
  "Yes": "Ja",
  "I don't know": "Weet ik niet",
  "I don't own one": "I don't own one",
  "Regarding Energy Efficiency Labels": "Regarding Energy Efficiency Labels",
  "Match table explanation": "Op 1 maart 2021 wijzigden de Energie-efficiëntielabels voor (huishoud)toestellen. Hieronder vindt u een tabel waarmee u de energieklasse van uw toestellen, gekocht vóór 1 maart 2021, kunt bijstellen.",
  "Refrigerator": "Koelkast",
  "Before 2021": "Voor 2021",
  "After 2021": "Na 2021",
  "Washing Machine": "Wasmachine",
  "Dishwasher": "Vaatwasmachine",
  "Oven": "Oven",
  "The energy classes for ovens remain the same": "The energy classes for ovens remain the same",

  // CO2 Calculator - Waste Management
  "CO2 from Waste Management": "CO2 uit afvalbeheer",
  "The average citizen in": "De gemiddelde burger in",
  "recycles": "recyclet",
  "Kgs of waste": "Kgs afval",
  "and composts": "en composteert",
  "Recycle Tooltip": "Hoeveel papier, glas, plastic, blikjes recycle je?",
  "Compost Tooltip": "Hoeveel keukenafval composteert u?",
  "Choose": "Kies",
  "Recycling": "Recycling",
  "No recycling": "Geen recycling",
  "Less than average recycling": "Minder dan gemiddelde recycling",
  "Same as average recycling": "Gelijk aan gemiddelde recycling",
  "More than average recycling": "Meer dan gemiddelde recycling",
  "Composting": "Compost",
  "No composting": "Geen compostering",
  "Less than average composting": "Minder dan gemiddeld composteren",
  "Same as average composting": "Gelijk aan gemiddelde compostering",
  "More than average composting": "Meer dan gemiddeld composteren",
  "Calculate": "Bereken",
  "That is the equivalent of": "Dat is het equivalent van",
  "30lt trash bags": "Vuilniszakken van 30 liter ",
  "and": "en",

  // CO2 Calculator - Completion Alerts
  "Calculated successfully": "Met succes berekend!",
  "Check Results": "Je kunt het tabblad Resultaten bekijken om de toegepaste wijzigingen te zien.",
  "Uncompleted fields": "Niet ingevulde velden!", 
  "Fill out fields": "Vul alle verplichte velden in om de resultaten te kunnen berekenen.",

  
  // CO2 Calculator - Results
  "CO2 Calculator Results": "CO2 Rekenmachine Resultaten",
  "Calculator Results": "Rekenmachine resultaten",
  "Save": "Opslaan",
  "Total": "Totaal",
  "User": "Gebruiker",
  "Waste": "Afval",
  "Transportation": "Vervoer",
  "For more information": "Voor meer informatie",
  "Your results compared to your country's average": "Uw resultaten vergeleken met het gemiddelde van uw land",
  "You": "U",
  "Now that you know your carbon footprint, give us your opinion on how to reduce emissions.": "Now that you know your carbon footprint, give us your opinion on how to reduce emissions.",

  // Profile
  "Calculations List Title": "Berekeningen",
  "Your Calculator Results": "De resultaten van uw rekenmachine",
  "tons CO2 per domain": "ton CO2 per domein",
  "tons CO2": "ton CO2",
  "Kilometers/Year": "Kilometers/jaar",
  "Liters/100 Km": "Liter/100 km",
  "Full Name": "Full Name",
  "e-mail": "e-mail",
  "Short Haul": "Korte afstand",
  "Medium Haul": "Middellange afstand",
  "Long Haul": "Lange afstand",
  "Europe": "Europa",
  "Export": "Export",
  "Scenario": "Scenario",
  "Energy Efficient Refrigerator": "Energiezuinige koelkast",
  "Energy Efficient Washing Machine": "Energiezuinige wasmachine",
  "Energy Efficient Dishwasher": "Energiezuinige vaatwasser",
  "Energy Efficient Oven": "Energiezuinige oven",
  "Car": "Car",
  "More Info": "Meer informatie",

  
  // Admin Page - 
  "Percentage": "Percentage",
  "Recommendations": "Aanbevelingen",
  "Scenarios": "Scenario's",
  "Actions": "Acties",
  "Statistics": "Statistieken",

  // Admin Page - Scenarios
  "Scenario Explanation": "In this page you can create your own scenarios, by choosing actions from the adjacent column. You can also update the existing scenarios.",
  "Select slider action": "Selecteer schuifbalk actie",    
  "Please select the appropriate levels of the actions for your scenario.": "Selecteer de juiste niveaus van de acties voor jouw scenario.",
  "Add Scenario": "Scenario toevoegen",
  "Cancel": "Annuleren",
  "Update": "Update",
  "Delete": "Bijwerken",
  "Please enter a title and a description for your scenario.": "Voeg een titel en omschrijving toe aan uw scenario.",
  "Citizen": "Burger",
  "Government": "Overheid",
  "Scenario Title": "Scenario titel",
  "Scenerio Description": "Scenario Beschrijving",
  

  // Admin Page - Actions
  "Actions Title Text" : "ActioActiesns",
  "Actions Title Text secondary" : "Selecteer actie",
  "Action Label" : "Actielabel",
  "Action Contribution Admin Page Text": "0 <= input <= 1",
  "Action Title 1 Admin Page Text" : "",
  "Action Title 2 Admin Page Text" : "",
  "Action Title 3 Admin Page Text" : "",
  "Action Percentage 1 Admin Page Text" : "",
  "Action Percentage 2 Admin Page Text" : "",
  "Action Percentage 3 Admin Page Text" : "",
  "Empty Fields Error Text" : "Velden mogen niet leeg zijn",
  "Clear" : "wissen",
  "Category" : "Categorie",
  "Contribution" : "Bijdrage",
  "Title" : "Titel",
  "Add action" : "Actie toevoegen",
  "No Action" : "Geen actie",
  "First Action" : "Eerste actie",
  "Second Action" : "Tweede actie",
  "Third Action" : "Derde actie",
  "Please choose the percentage of the implementation of your action" : "Kies het percentage van de implementatie van je actie",
  "You have to enter a number between 0 and 1 that represents a percentage" : "Je moet een getal tussen 0 en 1 invoeren dat een percentage vertegenwoordigt",
  "Please choose the percentage of the implementation of the first stage of your action": "Kies het percentage van de uitvoering van de eerste",
  "Please choose the percentage of the implementation of the second stage of your action": "Hoeveel percent van de tweede fase van uw actie werd geïmplementeerd?",
  "Please choose the percentage of the implementation of the third stage of your action": "Hoeveel percent van de derde fase van uw actie werd geïmplementeerd?",
  "Please add the title of the new action.": "Voeg de titel van de nieuwe actie toe",

  // Admin Page - Recommendations
  "Add Link" : "Link toevoegen",
  "Remove Last Link" : "Laatste link verwijderen",
  "Link" : "Koppeling",
  "Please enter the text for your new recommendation." : "Voer de tekst in voor uw nieuwe aanbeveling.",
  "Recommendation Text" : "Aanbeveling Tekst",
  "Add Case" : "Geval toevoegen",
  "Add Recommendation" : "Aanbeveling toevoegen",
  "Operator" : "Operator",
  "Value" : "Waarde",
  "Country" : "Land",
  

  // Admin Page - Statistics
  "Stage" : "Fase",
  "Stage 0" : "Fase 0",
  "Stage 1" : "Fase 1",
  "Stage 2" : "Fase 2",
  "Stage 3" : "Fase 3",
  "Select Country" : "Land",
  "Select City" : "Stad",
  "Select Municipal Region" : "Gemeente",
  "Select Age" : "Leeftijd",
  "Select Gender" : "Geslacht",
  "Select Social Aid" : "Sociale hulp",
  "Select Education Level" : "Selecteer opleidingsniveau",
  "Scenario Simulation Statistics" : "Scenario Simulatie Statistieken",
  "Carbon Footprint Calculator Statistics" : "Statistieken van de CO2-voetafdruk calculator",
  "Average" : "Gemiddelde",


  // Recommendations
  "Consider replacing old fluorescent lighting with LEDs" : "Overweeg oude TL-verlichting te vervangen door LED's",
  "Consider switching to Energy Efficient appliances" : "Overweeg om over te schakelen op energiezuinige apparaten",
  "Consider using smart power strips" : "Overweeg het gebruik van slimme stekkerdozen",
  "Consider turning down the heating by 1 degree Celsius" : "Overweeg de verwarming 1 graad lager te zetten",
  "Consider unpluging electronical equipment when fully charged" : "Elektronische apparatuur uit het stopcontact halen als deze volledig is opgeladen",
  "Consider insulating your home" : "Overweeg je huis te isoleren",
  "Consider increasing cycling by 30% (micromobility)" : "Overweeg 30% meer te gaan fietsen (micromobiliteit)",
  "Consider using the train for medium length distances (insted of the car)" : "Overweeg de trein te nemen voor middellange afstanden (in plaats van de auto)",
  "Consider carpooling to work" : "Overweeg carpoolen naar je werk",
  "Consider using public transport in your daily routine" : "Overweeg het gebruik van openbaar vervoer in je dagelijkse routine",
  "Consider replacing all business and first class trips by economy class trips" : "Overweeg om alle zakenreizen en reizen in eerste klas te vervangen door reizen in economy class.",
  "Consider replacing indirect flights with direct flights" : "Overweeg om indirecte vluchten te vervangen door directe vluchten",
  "Consider reducing air travel by using trains or ferries" : "Overweeg vliegreizen te verminderen door gebruik te maken van treinen of veerboten",
  "Consider using a reusable bad to the market" : "Overweeg het gebruik van een herbruikbare bad naar de markt",
  "Consider shopping at a bulk foods store" : "Overweeg om boodschappen te doen bij een winkel voor bulkproducten",
  "Good job!" : "Goed bezig!",
  "Consider reducing the use of single plastics!" : "Overweeg om het gebruik van kunststoffen te verminderen",
  "Consider using public transport" : "Overweeg het gebruik van openbaar vervoer",
  "Consider car sharing" : "Overweeg om de auto te delen",
  "Consider replacing your washing machine with an energy efficient one" : "Overweeg je wasmachine te vervangen door een energiezuinige",
  "Consider replacing your oven with an energy efficient one" : "Overweeg je oven te vervangen door een energiezuinige oven",
  "Consider replacing your refrigerator with an energy efficient one" : "Overweeg je koelkast te vervangen door een energiezuinige koelkast",
  "Consider replacing your dishwasher with an energy efficient one" : "Overweeg je vaatwasser te vervangen door een energiezuinige vaatwasser",
  "Consider reducing the use of single plastics":"Consider reducing the use of single plastics",
  "Consider using a reusable bag to the market":"Overweeg het gebruik van een herbruikbare tas",
  "Consider purchasing preused items and/or materials":"Overweeg de aanschaf van tweedehands artikelen en/of materialen",
  "Consider circular products instead of single use":"Overweeg recycleerbare producten in plaats van wegwerpproducten",
  "Consider products made of regenerated material sources": "Denk aan producten gemaakt van herbruikbare bronnen",
  "Consider less use of air-condition": "Overweeg een verminderd gebruik van airconditioning",
  "Consider using your own thermo for coffee/tea/water takeaway": "Overweeg het gebruik van een persoonlijke thermos voor koffie/thee/water",
  "Consider using the composting bins of the city or your own": "Overweeg het gebruik van eigen of gemeentelijke compostbakken",
  "Consider performing maintenance on your heating system": "Overweeg onderhoud van uw verwarmingssysteem",
  "Consider upgrading your heating system": "Overweeg een upgrade van uw verwarmingssysteem",
  "Consider using a more efficient vehicle": "Overweeg een efficiënter voertuig te gebruiken",
  "Consider donating surplus production out of your garden or field": "Overweeg om overtollige productie van fruit/groenten weg te geven",
  "Consider sharing formulas instead of purchasing (shared vehicles, machines...)": "Verkies deelformules (voor voertuigen, machines ...) boven aankopen",

// Actions - Citizen
"Improve Transportation Habits" : "Vervoersgewoonten verbeteren",
"20% increased Carpoolling" : "20% meer carpoolen",
"50% increased Carpoolling" : "50% meer carpoolen",
"70% increased Carpoolling" : "70% meer carpoolen",

"Change Flying Habits" : "Vlieggewoonten veranderen",
"20% Replace business class with economy" : "20% Business class vervangen door economy class",
"50% Replace business class with economy" : "50% Business class vervangen door economy class",
"90% Replace business class with economy" : "90% Business class vervangen door economy class",

"Improve Flying Habits" : "Vlieggewoonten verbeteren",
"20% Replace indirect flights with direct flights" : "20% Indirecte vluchten vervangen door directe vluchten",
"50% Replace indirect flights with direct flights" : "50% Indirecte vluchten vervangen door directe vluchten",
"90% Replace indirect flights with direct flights" : "90% Indirecte vluchten vervangen door directe vluchten",

"Change Travelling Habits" : "Reisgewoonten veranderen",
"20% Train use for medium length distances" : "20% Treingebruik voor middellange afstanden",
"50% Train use for medium length distances" : "50% Treingebruik voor middellange afstanden",
"90% Train use for medium length distances" : "90% Treingebruik voor middellange afstanden",

"Lighting Habits" : "Verlichtings gewoonten",
"20% Replace old fluorescent lighting with LEDs" : "20% Vervang oude TL-verlichting door LED's",
"50% Replace old fluorescent lighting with LEDs" : "50% Vervang oude TL-verlichting door LED's",
"100% Replace old fluorescent lighting with LEDs" : "100% Vervang oude TL-verlichting door LED's",

"Appliances Habits" : "Gewoontes apparaten",
"20% Energy Efficient appliances" : "20% Energie-efficiënte apparaten",
"50% Energy Efficient appliances" : "50% Energie-efficiënte apparaten",
"100% Energy Efficient appliances" : "100% Energie-efficiënte apparaten",

"Insulation Habits" : "Isolatie gewoonten",
"20% Insulation Thickness" : "20% Isolatie dikte",
"50% Insulation Thickness" : "50% Isolatie dikte",
"100% Insulation Thickness" : "100% Isolatie dikte",

"Improve Recycling Habits" : "Verbeter recycling gewoonten", // needs fix
"20% Increased recycling of plastics + paper + metal cans + glass" : "20% Meer recycling van plastic + papier + metalen blikjes + glas",
"40% Increased recycling of plastics + paper + metal cans + glass" : "40% Meer recycling van plastic + papier + metalen blikjes + glas",
"70% Increased recycling of plastics + paper + metal cans + glass" : "70% Meer recycling van plastic + papier + metalen blikjes + glas",

"Introduce composting in everyday life": "Introduceer composteren in het dagelijks leven",
"20% compost of organic waste by placing a bin": "20% compost van organisch afval door het plaatsen van een bak",
"40% compost of organic waste by placing a bin": "40% compost van organisch afval door het plaatsen van een bak",
"70% compost of organic waste by placing a bin": "70% compost van organisch afval door het plaatsen van een bak",

"Unplug devices that are not used or when on vacation": "Koppel apparaten los die niet worden gebruikt of wanneer u op vakantie bent",
"20% Unplug devices that are not operating": "20% Koppel apparaten los die niet werken",
"40% Unplug devices that are not operating": "40% Koppel apparaten los die niet werken",
"70% Unplug devices that are not operating": "70% Koppel apparaten los die niet werkeng",

"Improve everyday mobility": "Verbeter de dagelijkse mobiliteit",
"20% Increase everyday's cycling": "20% Ga meer fietsen",
"40% Increase everyday's cycling": "40% Ga meer fietsen",
"70% Increase everyday's cycling": "70% Ga meer fietsen",

// Actions - Government
"Capture and storage of CO2 from fossil fuel combustion" : "Opvang en opslag van CO2 uit de verbranding van fossiele brandstoffen",
"40% Capture and storage of CO2" : "40% Opvang en opslag van CO2",
"60% Capture and storage of CO2" : "60% Opvang en opslag van CO2",
"100% Capture and storage of CO2" : "100% Opvang en opslag van CO2",

"Photovoltaic parks at petrol stations" : "Fotovoltaïsche parken bij benzinestations",
"30% Installed photovoltaic parks at petrol stations" : "30% Geïnstalleerde fotovoltaïsche parken bij benzinestations",
"60% Installed photovoltaic parks at petrol stations" : "60% Geïnstalleerde fotovoltaïsche parken bij benzinestations",
"100% Installed photovoltaic parks at petrol stations" : "100% Geïnstalleerde fotovoltaïsche parken bij benzinestationss",

"Wind Energy" : "Windenergie",
"30% penetration from wind energy" : "30% penetratie van windenergie",
"60% penetration from wind energy" : "60% penetratie van windenergie",
"90% penetration from wind energy" : "90% penetratie van windenergie",

"Replacing cars with electric ones": "Vervang auto's door elektrische versies",
"40% Replacing cars with electric ones": "40% Vervang auto's door elektrische versies",
"60% Replacing cars with electric ones": "60% Vervang auto's door elektrische versies",
"100% Replacing cars with electric ones": "100% Vervang auto's door elektrische versies",

"Establish a speed limit of 30 km/h in cities": "Stel een snelheidslimiet in van 30 km/u in steden",
"20% Establish a speed limit of 30 km/h in cities": "20% Stel een snelheidslimiet in van 30 km/u in steden",
"60% Establish a speed limit of 30 km/h in cities": "60% Stel een snelheidslimiet in van 30 km/u in steden",
"100% Establish a speed limit of 30 km/h in cities": "100% Stel een snelheidslimiet in van 30 km/u in steden",

"Ιncreasing bike lanes in the city": "Meer fietspaden in de stad",
"20% Ιncreasing bike lanes in the city": "20% Meer fietspaden in de stad",
"40% Ιncreasing bike lanes in the city": "40% Meer fietspaden in de stad",
"100% Ιncreasing bike lanes in the city": "100% Meer fietspaden in de stad",

"Create green ports": "Creëer groene havens",
"20% of cities ports become Green ports": "20% van de stadshavens worden groene havens",
"40% of cities ports become Green ports": "40% van de stadshavens worden groene havens",
"100% of cities ports become Green ports": "100% van de stadshavens worden groene havens",

"Financial Assistance Program for Home Insulation Applications": "Financieel hulpprogramma voor woningisolatietoepassingen",
"20% Improve Home Insulation": "20% Verbetering van de huisisolatie",
"50% Improve Home Insulation": "50% Verbetering van de huisisolatie",
"100% Improve Home Insulation": "100% Verbetering van de huisisolatie",

"Financial Support Program for Replacing Domestic Heating Appliances": "Financieel steunprogramma voor de vervanging van huishoudelijke verwarmingstoestellen",
"20% Replacing fossil fuel boilers with heat pumps": "20% Vervanging van verwarmingsketels op fossiele brandstoffen door warmtepompen",
"40% Replacing fossil fuel boilers with heat pumps": "40% Vervanging van verwarmingsketels op fossiele brandstoffen door warmtepompen",
"100% Replacing fossil fuel boilers with heat pumps": "100% Vervanging van verwarmingsketels op fossiele brandstoffen door warmtepompen",

// Actions - Local Government
"Improvement in rail service quality (frequency,speed)": "Verbetering van de kwaliteit van de spoordienst (frequentie, snelheid)",
"20% Increase in rail usage": "20% Toename van het spoorgebruik",
"40% Increase in rail usage": "40% Toename van het spoorgebruik",
"90% Increase in rail usage": "90% Toename van het spoorgebruik",

"Improving the energy efficiency of existing buildings (goverment support programme)": "Verbetering van de energie-efficiëntie van bestaande gebouwen (steunprogramma overheid)",
"20% Improving the energy efficiency of existing buildings": "20% Verbetering van de energie-efficiëntie van bestaande gebouwen",
"40% Improving the energy efficiency of existing buildings": "40% Verbetering van de energie-efficiëntie van bestaande gebouwen",
"90% Improving the energy efficiency of existing buildings": "90% Verbetering van de energie-efficiëntie van bestaande gebouwen",

"Increase Compostable stations": "Vergroot composteerbare stations",
"20% Increase Compostable stations": "20% Vergroot composteerbare stations",
"40% Increase Compostable stations": "40% Vergroot composteerbare stations",
"90% Increase Compostable stations": "90% Vergroot composteerbare stations  ",

"Bike-sharing programs at convenient locations": "Programma's voor het delen van fietsen op handige locaties",
"20% Increase bike usage": "20% Verhoog het fietsgebruik",
"40% Increase bike usage": "40% Verhoog het fietsgebruik",
"70% Increase bike usage": "70% IVerhoog het fietsgebruik",

"Start creating bicycle lanes": "Start met het aanleggen van fietspaden",
"20% expand bicycle lanes": "20% breidt fietspaden uit",
"40% expand bicycle lanes": "40% breidt fietspaden uit",
"90% expand bicycle lanes": "90% breidt fietspaden uit",

};
  
  export default nlJSON;