import React from "react";
import { Box, Typography } from '@mui/material';
import { useLocation } from "react-router-dom";




const Footer = (

    ) => {

    /* Hide footer when viewing through the Compair Project portal */
    const currentPath = useLocation();
    const isIntegrated = new URLSearchParams(currentPath.search).get('integration')
    if (isIntegrated) return null;

    let url = "https://www.wecompair.eu/"

    // console.log(isIntegrated)

    return (
        <>
            <Box sx={{
                justifyContent: 'center',
                backgroundColor: '#BED3F7',
                '&:hover': {
                    backgroundColor: 'primary.main',
                    opacity: [0.9, 0.8, 0.7],
                }
            }}
            onClick = { () => window.open(url, '_blank', 'noopener,noreferrer')}
              >
                <Typography sx={{ textAlign: "center" }} >
                    @2022 by COMPAIR Project
                </Typography>
            </Box>


        </>
    );
};

export default Footer;

